
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.pagination = window.Smartly.locales.modules.en.back_royal.careers.pagination || {};
window.Smartly.locales.modules.en.back_royal.careers.pagination = {...window.Smartly.locales.modules.en.back_royal.careers.pagination, ...{
                 
    "first": "First",
    "prev": "Prev",
    "next": "Next",
    "last": "Last",
    "of": "of",

                
    "expand": "Expand",
    "collapse": "Collapse"
}
}
    