import angularModule from 'ClientConfig/angularModule/scripts/client_config_module';
import BuildConfig from 'BuildConfig';
import reloadWindow from 'reloadWindow';

angularModule.factory('ClientConfig', [
    '$injector',

    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $window = $injector.get('$window');

        const ClientConfig = SuperModel.subclass(function () {
            const self = this;
            ['upgradeInstructions', 'upgradeButtonText'].forEach(prop => {
                Object.defineProperty(self.prototype, prop, {
                    get() {
                        const translateKey = `client_config.client_config.${this[`${prop}Key`]}`;
                        // lazy injection to prevent circ. dependency
                        return $injector.get('$translate').instant(translateKey);
                    },
                });
            });

            return {
                refreshToUpgrade: false,

                initialize(versionNumber) {
                    this.versionNumber = versionNumber;
                },
            };
        });

        const getAppStoreName = () => {
            // See also hybrid/overrides/Miya\ Miya/www/client_overrides.js
            if (window.CORDOVA?.miyaMiya) {
                return 'id1503519481';
            }
            return 'id1014850662';
        };

        const WebClientConfig = ClientConfig.subclass(() => ({
            identifier: 'web',
            upgradeInstructionsKey: 'web_upgrade_instructions',
            upgradeButtonTextKey: 'web_upgrade_button_text',

            appStoreName: getAppStoreName(),

            upgrade(EventLogger) {
                reloadWindow({ label: 'web_client_config', EventLogger });
            },
        }));

        const NativeAppConfig = ClientConfig.subclass(() => ({
            upgradeInstructionsKey: 'native_upgrade_instructions',
            upgradeButtonTextKey: 'native_upgrade_button_text',

            upgrade(_) {
                if (this.appStoreName) {
                    $window.cordova.plugins.market.open(this.appStoreName);
                } else {
                    navigator.notification.alert('Cannot upgrade without an app store name.', () => {});
                }
            },
        }));

        const IosClientConfig = NativeAppConfig.subclass(() => ({
            identifier: 'ios',
        }));

        const AndroidClientConfig = NativeAppConfig.subclass(() => ({
            identifier: 'android',
        }));

        const Klass =
            {
                web: WebClientConfig,
                ios: IosClientConfig,
                android: AndroidClientConfig,
            }[window.clientIdentifier] || WebClientConfig;

        const instance = new Klass(Number(BuildConfig.build_number));

        return {
            current: instance,
        };
    },
]);
