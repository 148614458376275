import { fetchBrandConfig } from 'AppBranding';
import {
    formattedUserFacingDay,
    formattedUserFacingMonthDayLong,
    formattedUserFacingMonthDayYearLong,
    formattedUserFacingMonthDayYearMedium,
    formattedUserFacingMonthYearLong,
    formattedUserFacingYearLong,
} from 'DateHelpers';
import { saveAs } from 'file-saver';
import proximaNovaSoftRegularAssetPath from 'fonts/ProximaNovaSoft-Regular.ttf';
import proximaNovaSoftSemiboldAssetPath from 'fonts/ProximaNovaSoft-Semibold.ttf';
import { type FrontRoyalWindow } from 'FrontRoyalWindow';
import { type LearnerProjectIguanaObject } from 'LearnerProjects';
import { type StreamIguanaObject } from 'Lessons';
import moment from 'moment';
import oreo from 'Oreo';
import { type PeriodIguanaObject } from 'Period';
import { type PlaylistIguanaObject } from 'Playlists';
import { type AnyObject, type Nullable } from '@Types';
import getFilePathsFromManifest from 'WebpackManifestHelper';
import { type CohortIguanaObject } from './Cohort.types';

type ocLazyLoad = {
    load: (module: string) => ng.IPromise<unknown>;
};

type FetchedAssets = {
    pages: {
        coverPage: ArrayBuffer;
        pageA: ArrayBuffer;
        pageB: ArrayBuffer;
    };
    fonts: {
        proximaNovaSoftRegular: ArrayBuffer;
        proximaNovaSoftSemibold: ArrayBuffer;
    };
};

type ImportantDate = {
    sortDate: Date;
    text: string;
    bullet: string;
};

type ScheduleSection = {
    startDate: Nullable<Date>;
    title: string;
    type: string;
    periods: PeriodConfig[];
};

type PeriodConfig = {
    title: string;
    curriculumComponents: string[];
};

const PAGE_DIMENSIONS = { width: 612, height: 792 };
const COPYRIGHT_MESSAGE_COLOR = '#231F20';
const COPYRIGHT_MESSAGE_FONT_SIZE = 10;
const COPYRIGHT_MESSAGE_FONT = 'proximaNovaSoftRegular';
const SCHEDULE_SECTION_LEFT_COLUMN_Y = 96;
const SCHEDULE_SECTION_TITLE_MARGIN_BOTTOM = 15;
const SCHEDULE_SECTION_MARGIN_BOTTOM = 20;
const SCHEDULE_SECTION_TITLE_HEIGHT = 19.2; // calculated via doc.heightOfString()
const CURRICULUM_COMPONENT_HEIGHT = 17.4; // calculated via doc.heightOfString()
const SCHEDULE_BOX_X = 34;
const SCHEDULE_BOX_WIDTH = 546;
const SCHEDULE_BOX_COLOR = oreo.COLOR_V3_BEIGE_MEDIUM;
const PAGE_B_SCHEDULE_BOX_MAX_HEIGHT = 646;
const PAGE_B_SCHEDULE_BOX_Y = 79;
const SCHEDULE_BOX_PADDING_TOP_BOTTOM = SCHEDULE_SECTION_LEFT_COLUMN_Y - PAGE_B_SCHEDULE_BOX_Y;
const SCHEDULE_PAGE_TITLE_X = 144;
const SCHEDULE_PAGE_Y_BOUNDARY =
    PAGE_B_SCHEDULE_BOX_Y + PAGE_B_SCHEDULE_BOX_MAX_HEIGHT - SCHEDULE_BOX_PADDING_TOP_BOTTOM;
const DUE_BY_DATE_SUFFIX = 'by 11:59 p.m. PDT / UTC-7 hours';

export async function downloadSchedule(
    cohort: CohortIguanaObject,
    playlists: PlaylistIguanaObject[],
    streams: StreamIguanaObject[],
    $injector: ng.auto.IInjectorService,
) {
    const assets = await fetchAssets(cohort, $injector);

    const globalWindow = window as FrontRoyalWindow;
    const doc = new globalWindow.PDFDocument({
        size: [PAGE_DIMENSIONS.width, PAGE_DIMENSIONS.height],
        margin: 0,
    });
    const pdfStream = doc.pipe(globalWindow.blobStream());

    generateCoverPage(doc, assets, cohort);
    generateRemainingPages(doc, assets, cohort, playlists, streams);

    // set the finish event
    pdfStream.on('finish', () => {
        const blob = pdfStream.toBlob('application/pdf');
        saveAs(
            blob,
            `${[fetchBrandConfig(cohort.branding).brandNameShort, getSanitizedDocumentTitle(cohort)].join(' ')}.pdf`,
        );
    });

    doc.end();
}

// Fetches the assets needed to generate the cohort schedule PDF:
//  1. The schedule page template images.
//  2. The necessary fonts.
//  3. The certificates entry point, which contains blob stream helper code that will
//      be attached to the global window.
async function fetchAssets(cohort: CohortIguanaObject, $injector: ng.auto.IInjectorService) {
    const brandConfig = fetchBrandConfig(cohort.branding);
    const coverPageAssetPath = brandConfig.downloadableCohortScheduleConfig.cover;
    const pageAAssetPath = brandConfig.downloadableCohortScheduleConfig.pageA;
    const pageBAssetPath = brandConfig.downloadableCohortScheduleConfig.pageB;
    const pageAssetPaths = [coverPageAssetPath, pageAAssetPath, pageBAssetPath];
    const pageRequests = pageAssetPaths.map(pageAssetPath => fetch(new Request(window.ENDPOINT_ROOT + pageAssetPath)));
    const fontRequests = [proximaNovaSoftRegularAssetPath, proximaNovaSoftSemiboldAssetPath].map(fontAssetPath =>
        fetch(new Request(window.ENDPOINT_ROOT + fontAssetPath)),
    );
    const certificatesAssetRequests = getFilePathsFromManifest('certificates', 'js').map(path => {
        let resolver;
        const promise = new Promise(resolve => {
            resolver = resolve;
        });
        $injector.get<ocLazyLoad>('$ocLazyLoad').load(path).then(resolver);
        return promise;
    });

    const [coverPageResponse, pageAResponse, pageBResponse] = await Promise.all(pageRequests);
    const [proximaNovaSoftRegularResponse, proximaNovaSoftSemiboldResponse] = await Promise.all(fontRequests);
    await Promise.all(certificatesAssetRequests);

    const coverPage = await (await coverPageResponse.blob()).arrayBuffer();
    const pageA = await (await pageAResponse.blob()).arrayBuffer();
    const pageB = await (await pageBResponse.blob()).arrayBuffer();
    const proximaNovaSoftRegular = await (await proximaNovaSoftRegularResponse.blob()).arrayBuffer();
    const proximaNovaSoftSemibold = await (await proximaNovaSoftSemiboldResponse.blob()).arrayBuffer();

    return {
        pages: {
            coverPage,
            pageA,
            pageB,
        },
        fonts: {
            proximaNovaSoftRegular,
            proximaNovaSoftSemibold,
        },
    };
}

function generateCoverPage(doc: PDFKit.PDFDocument, assets: FetchedAssets, cohort: CohortIguanaObject) {
    doc.image(assets.pages.coverPage, 0, 0, PAGE_DIMENSIONS);

    const brandConfig = fetchBrandConfig(cohort.branding);
    doc.font(assets.fonts.proximaNovaSoftRegular)
        .fontSize(42)
        .fillColor(oreo.COLOR_V3_WHITE)
        .text(
            `${brandConfig.brandNameShort} `,
            brandConfig.downloadableCohortScheduleConfig.coverPageTitleX,
            brandConfig.downloadableCohortScheduleConfig.coverPageTitleY,
            {
                continued: true,
            },
        )
        .font(assets.fonts.proximaNovaSoftSemibold)
        .text(cohort.shortProgramTitle.toUpperCase());

    const classGraduationMonthYear = formattedUserFacingMonthYearLong(cohort.graduationDate, false);
    const [classGraduationMonth, classGraduationYear] = classGraduationMonthYear.split(' ');
    doc.font(assets.fonts.proximaNovaSoftRegular)
        .text(`Class of ${classGraduationMonth} `, { continued: true })
        .font(assets.fonts.proximaNovaSoftSemibold)
        .text(classGraduationYear);

    doc.font(assets.fonts.proximaNovaSoftRegular).text('Cohort Schedule');

    const welcomeMessageSansGradingPolicyLink = `Welcome to ${brandConfig.brandNameShort}! Here's the course syllabus for your program. It's strongly recommended that you follow this schedule to complete your required lessons per week. In order to graduate and earn your degree, you must meet the course requirements as seen `;
    doc.fontSize(11)
        .fillColor(oreo.COLOR_V3_EGGPLANT)
        .text(
            welcomeMessageSansGradingPolicyLink,
            238,
            brandConfig.downloadableCohortScheduleConfig.coverPageWelcomeMessageY,
            {
                continued: true,
                width: 333,
            },
        )
        .text('here', {
            continued: true,
            link: cohort.gradingPolicyFaqUrl,
            underline: true,
        })
        .text('.', {
            underline: false,
        });

    doc.font(assets.fonts[COPYRIGHT_MESSAGE_FONT])
        .fontSize(COPYRIGHT_MESSAGE_FONT_SIZE)
        .fillColor(COPYRIGHT_MESSAGE_COLOR)
        .text(getCopyrightMessage(), 238, 735);
}

function generateRemainingPages(
    doc: PDFKit.PDFDocument,
    assets: FetchedAssets,
    cohort: CohortIguanaObject,
    playlists: PlaylistIguanaObject[],
    streams: StreamIguanaObject[],
) {
    doc.on('pageAdded', () => {
        // Add a title at the top of all subsequently added pages.
        const brandConfig = fetchBrandConfig(cohort.branding);
        doc.font(assets.fonts.proximaNovaSoftSemibold)
            .fontSize(16)
            .fillColor(brandConfig.themeColor)
            .text(getSanitizedDocumentTitle(cohort), SCHEDULE_PAGE_TITLE_X, 19);
    });

    doc.addPage().image(assets.pages.pageA, 0, 0, PAGE_DIMENSIONS);

    // Any page added after pageA uses pageB as the template.
    doc.on('pageAdded', () => {
        // Add a copyright message at the bottom of all subsequently added pages.
        // The copyright message on pageB has a slightly different positioning than
        // the copyright message on pageA.
        doc.font(assets.fonts[COPYRIGHT_MESSAGE_FONT])
            .fontSize(COPYRIGHT_MESSAGE_FONT_SIZE)
            .fillColor(COPYRIGHT_MESSAGE_COLOR)
            .text(getCopyrightMessage(), 365, 736, { width: 215 });
    });

    doc.font(assets.fonts[COPYRIGHT_MESSAGE_FONT])
        .fontSize(COPYRIGHT_MESSAGE_FONT_SIZE)
        .fillColor(COPYRIGHT_MESSAGE_COLOR)
        .text(getCopyrightMessage(), 365, 728, { width: 215 });

    const importantDatesBoxX = SCHEDULE_PAGE_TITLE_X;
    const importantDatesBoxY = 79;
    const importantDatesLeftColumnX = 161;
    const importantDatesLeftColumnY = 126;
    const importantDatesRightColumnBulletRadius = 1;
    const importantDatesRightColumnTextIndent = 21;
    const importantDatesRightColumnWidth = 170;
    const importantDatesTitleY = 90;
    const importantDatesTitleFont = assets.fonts.proximaNovaSoftSemibold;
    const importantDatesTitleFontSize = 16;
    const importantDatesTitle = 'IMPORTANT DATES';
    const importantDatesBodyFont = assets.fonts.proximaNovaSoftRegular;
    const importantDatesBodyFontSize = 10;
    const importantDatesBodyLineGap = 4;
    const importantDatesBoxPaddingBottom = 66;
    const importantDatesBoxMarginBottom = 5;
    const distanceBetweenImportantDatesBoxBottomAndScheduleBoxTop = 34;

    /*
        How dates are formatted for this document:
            - Standalone dates (e.g. start date and graduation date) use 'MMMM D, YYYY' formatting (e.g. November 27, 2023).
            - Date ranges:
                - If the range of dates is contained within a single month, the range is formatted as 'MMMM D-D, YYYY'.
                    - For example, 'October 21-27, 2024'.
                - If the range spans multiple months, the range is formatted as 'M D, YYYY - M D, YYYY'.
                    - For example, 'Dec. 25, 2023 - Jan. 7, 2024'.
            - Due dates use the formatting for standalone dates described above and have "by 11:59 p.m. PDT / UTC-7 hours"
                appended to the end.
                - For example, 'February 16, 2025 by 11:59 p.m. PDT / UTC-7 hours'.
    */
    const importantDates = (
        [
            {
                sortDate: cohort.startDate,
                text: 'Starting Date',
                bullet: formattedUserFacingMonthDayYearLong(cohort.startDate, false),
            },
            ...getWinterBreakImportantDates(cohort),
            getCapstoneKickoffImportantDate(cohort),
            ...getCapstoneCheckInImportantDates(cohort, 'standard'),
            ...getCapstoneCheckInImportantDates(cohort, 'specialized'),
            getCapstoneDueImportantDate(cohort),
            getCapstonePresentationDueImportantDate(cohort),
            {
                sortDate: cohort.graduationDate,
                text: 'Anticipated Graduation Date',
                bullet: formattedUserFacingMonthDayYearLong(cohort.graduationDate, false),
            },
        ].filter(Boolean) as ImportantDate[]
    ).sort((importantDateA, importantDateB) => {
        if (importantDateA.sortDate < importantDateB.sortDate) {
            return -1;
        }
        if (importantDateA.sortDate > importantDateB.sortDate) {
            return 1;
        }
        if (importantDateA.text < importantDateB.text) {
            return -1;
        }
        if (importantDateA.text > importantDateB.text) {
            return 1;
        }
        throw new Error('Duplicate important date detected');
    });

    doc.font(importantDatesBodyFont).fontSize(importantDatesBodyFontSize);

    const heightOfImportantDatesText =
        importantDates.reduce((totalHeight, importantDate) => {
            const numLinesOccupiedByImportantDate = importantDate.bullet.endsWith(DUE_BY_DATE_SUFFIX) ? 2 : 1;
            return (
                totalHeight +
                doc.heightOfString(importantDate.text, { lineGap: importantDatesBodyLineGap }) *
                    numLinesOccupiedByImportantDate
            );
        }, 0) - importantDatesBodyLineGap; // subtract the line gap after the last important date
    const importantDatesBoxHeight =
        importantDatesLeftColumnY - importantDatesBoxY + heightOfImportantDatesText + importantDatesBoxPaddingBottom;
    const importantDatesBoxBottomY = importantDatesBoxY + importantDatesBoxHeight;
    doc.polygon(
        [importantDatesBoxX, importantDatesBoxY],
        [importantDatesBoxX, importantDatesBoxBottomY],
        [489, importantDatesBoxBottomY],
        [580, importantDatesBoxBottomY - 52],
        [580, importantDatesBoxY],
    ).fill(fetchBrandConfig(cohort.branding).themeColor);

    const importantDatesSectionSubtext =
        'Individual concentration exam and case study due dates can be found in their respective sections below.';
    doc.fontSize(9)
        .fillColor(oreo.COLOR_V3_EGGPLANT)
        .text(importantDatesSectionSubtext, 161, importantDatesBoxBottomY + importantDatesBoxMarginBottom, {
            width: 327,
        });

    const pageAScheduleBoxMaxY = 717;
    const scheduleBoxY = importantDatesBoxBottomY + distanceBetweenImportantDatesBoxBottomAndScheduleBoxTop;
    const scheduleBoxHeight = pageAScheduleBoxMaxY - scheduleBoxY;
    doc.rect(SCHEDULE_BOX_X, scheduleBoxY, SCHEDULE_BOX_WIDTH, scheduleBoxHeight).fill(SCHEDULE_BOX_COLOR);

    doc.font(importantDatesTitleFont)
        .fontSize(importantDatesTitleFontSize)
        .fillColor(oreo.COLOR_V3_BEIGE_LIGHTER)
        .text(importantDatesTitle, importantDatesLeftColumnX, importantDatesTitleY);

    doc.font(importantDatesBodyFont).fontSize(importantDatesBodyFontSize);

    importantDates.forEach((importantDate, index) => {
        let lineY = doc.y;

        // The dates are all positioned relative to the first, so we need to make sure
        // the first date is positioned correctly and then the rest should follow nicely.
        if (index === 0) {
            lineY = importantDatesLeftColumnY;
            doc.text(importantDate.text, importantDatesLeftColumnX, lineY, { lineGap: importantDatesBodyLineGap });
        } else {
            doc.text(importantDate.text, importantDatesLeftColumnX, lineY);
        }

        doc.list([importantDate.bullet], 400, lineY, {
            bulletRadius: importantDatesRightColumnBulletRadius,
            lineGap: importantDatesBodyLineGap,
            textIndent: importantDatesRightColumnTextIndent,
            width: importantDatesRightColumnWidth,
        });
    });

    const concentrations = cohort.concentrationPlaylistPackIds
        .slice(1) // remove the Foundations playlist
        .reduce<AnyObject<PlaylistIguanaObject>>(
            (prev, curr) => ({ ...prev, [curr]: playlists.find(playlist => playlist.locale_pack.id === curr)! }),
            {},
        );

    const requiredStreams = cohort
        .getRequiredStreamPackIdsFromPeriods()
        .reduce<AnyObject<StreamIguanaObject>>(
            (prev, curr) => ({ ...prev, [curr]: streams.find(stream => stream.localePackId === curr)! }),
            {},
        );
    doc.y = scheduleBoxY + SCHEDULE_BOX_PADDING_TOP_BOTTOM;
    addSchedule(doc, assets, cohort, concentrations, requiredStreams);
}

function getSanitizedDocumentTitle(cohort: CohortIguanaObject) {
    const brandConfig = fetchBrandConfig(cohort.branding);
    return `${
        cohort.title.startsWith(brandConfig.brandNameShort) ? cohort.title.split(' ').slice(1).join(' ') : cohort.title
    } Cohort Schedule`;
}

function getCopyrightMessage() {
    return `© ${new Date().getFullYear()} Quantic Holdings Inc. All rights reserved.`;
}

function getWinterBreakImportantDates(cohort: CohortIguanaObject) {
    const winterBreaks: Record<string, Date>[] = [];
    let winterBreakStartDate: Nullable<Date>;
    let winterBreakEndDate: Nullable<Date>;
    const periodTitleRegex = /Winter Break/i;

    cohort.periods.forEach(period => {
        if (period.style === 'break' && periodTitleRegex.test(period.periodTitle)) {
            winterBreakStartDate = winterBreakStartDate || period.startDate;
            winterBreakEndDate = period.endDate;
        } else {
            if (winterBreakStartDate && winterBreakEndDate) {
                winterBreaks.push({ startDate: winterBreakStartDate, endDate: winterBreakEndDate });
            }
            winterBreakStartDate = null;
            winterBreakEndDate = null;
        }
    });

    return winterBreaks.map(winterBreak => ({
        sortDate: winterBreak.startDate,
        text: 'Winter Break',
        bullet: formattedDateRange(winterBreak.startDate, winterBreak.endDate),
    }));
}

function getCapstoneKickoffPeriod(cohort: CohortIguanaObject) {
    return (
        cohort.periods.find(period => (period.learner_projects || []).find(lp => lp.project_type === 'capstone')) ||
        null
    );
}

function getCapstoneKickoffImportantDate(cohort: CohortIguanaObject) {
    const capstoneKickoffPeriod = getCapstoneKickoffPeriod(cohort);
    return capstoneKickoffPeriod
        ? {
              sortDate: capstoneKickoffPeriod.startDate,
              text: 'Capstone Kickoff',
              bullet: formattedDateRange(capstoneKickoffPeriod.startDate, capstoneKickoffPeriod.endDate),
          }
        : null;
}

function getCapstoneCheckInImportantDates(cohort: CohortIguanaObject, capstoneCheckInType: 'standard' | 'specialized') {
    const capstoneKickoffPeriod = getCapstoneKickoffPeriod(cohort);
    const capstoneCheckIns: Record<string, Date>[] = [];
    let capstoneCheckInStartDate: Nullable<Date>;
    let capstoneCheckInEndDate: Nullable<Date>;

    cohort.periods.forEach(period => {
        const isCapstonePeriod = !!(period.learner_projects || []).find(lp => lp.project_type === 'capstone');
        const isCapstoneKickoffPeriod = isCapstonePeriod && period.startDate === capstoneKickoffPeriod?.startDate;
        let isPeriodForCapstoneCheckInType = false;

        if (isCapstonePeriod && !isCapstoneKickoffPeriod && !isCapstoneDuePeriod(period, cohort)) {
            const specializedCheckInPeriodRegex = /Specialized/i;
            switch (capstoneCheckInType) {
                case 'standard':
                    isPeriodForCapstoneCheckInType = !specializedCheckInPeriodRegex.test(period.periodTitle);
                    break;
                case 'specialized':
                    isPeriodForCapstoneCheckInType = specializedCheckInPeriodRegex.test(period.periodTitle);
                    break;
                default:
                    throw new Error(`Unexpected capstoneCheckInType: '${capstoneCheckInType}'`);
            }
        }

        if (isPeriodForCapstoneCheckInType) {
            capstoneCheckInStartDate = capstoneCheckInStartDate || period.startDate;
            capstoneCheckInEndDate = period.endDate;
        } else {
            if (capstoneCheckInStartDate && capstoneCheckInEndDate) {
                capstoneCheckIns.push({
                    startDate: capstoneCheckInStartDate,
                    endDate: capstoneCheckInEndDate,
                });
            }
            capstoneCheckInStartDate = null;
            capstoneCheckInEndDate = null;
        }
        return false;
    });

    return capstoneCheckIns.map(capstoneCheckIn => ({
        sortDate: capstoneCheckIn.startDate,
        text: capstoneCheckInType === 'standard' ? 'Capstone Check-In' : 'Specialized Capstone Project Check-In Week',
        bullet: formattedDateRange(capstoneCheckIn.startDate, capstoneCheckIn.endDate),
    }));
}

function formattedDateRange(startDate: Date, endDate: Date) {
    const startMonthYear = formattedUserFacingMonthYearLong(startDate, false);
    const endMonthYear = formattedUserFacingMonthYearLong(endDate);

    if (startMonthYear === endMonthYear) {
        const rangeStart = formattedUserFacingMonthDayLong(startDate, false);
        const rangeEndDay = formattedUserFacingDay(endDate);
        const rangeEndYear = formattedUserFacingYearLong(endDate);
        return `${rangeStart}-${rangeEndDay}, ${rangeEndYear}`; // e.g. October 21-27, 2024
    }

    const rangeStart = formattedUserFacingMonthDayYearMedium(startDate, false);
    const rangeEnd = formattedUserFacingMonthDayYearMedium(endDate);
    return `${rangeStart} - ${rangeEnd}`; // e.g. Aug. 26, 2024 - Sept. 1, 2024
}

function getCapstoneDueImportantDate(cohort: CohortIguanaObject) {
    const capstoneDuePeriod = getCapstoneDuePeriod(cohort);
    if (!capstoneDuePeriod) {
        return null;
    }

    return {
        sortDate: capstoneDuePeriod.endDate,
        text: 'Capstone Project Written Portion Due Date',
        bullet: `${formattedUserFacingMonthDayYearLong(capstoneDuePeriod.endDate)} ${DUE_BY_DATE_SUFFIX}`,
    };
}

function getCapstoneDuePeriod(cohort: CohortIguanaObject) {
    return (
        cohort.periods
            .slice()
            .reverse()
            .find(period => (period.learner_projects || []).find(lp => lp.project_type === 'capstone')) || null
    );
}

function getCapstonePresentationDueImportantDate(cohort: CohortIguanaObject) {
    const dueDate = getCapstonePresentationDueDate(cohort);
    if (!dueDate) {
        return null;
    }

    return {
        sortDate: dueDate,
        text: 'Capstone Project Presentation Due Date',
        bullet: `${formattedUserFacingMonthDayYearLong(getCapstonePresentationDueDate(cohort))} ${DUE_BY_DATE_SUFFIX}`,
    };
}

function getCapstonePresentationDueDate(cohort: CohortIguanaObject) {
    const capstoneDuePeriod = getCapstoneDuePeriod(cohort);
    if (!capstoneDuePeriod) {
        return undefined;
    }

    if (capstonePresentationDueAfterWrittenPortion(cohort)) {
        return moment(capstoneDuePeriod.endDate).add(2, 'weeks').toDate();
    }

    return capstoneDuePeriod.endDate;
}

// Starting in cycle 62, both the written portion of the Capstone project and the Capstone presentation
// were made to be due on the same date. Prior to cycle 62, the presentation was actually due 2 weeks
// after the written portion despite them still being configured to be due on the same date in the cohort's
// periods. To my knowledge, this was never reflected in code before and customer.io emails were simply
// hardcoded to say something to the effect of: "The presentation is due 2 weeks after the written portion."
function capstonePresentationDueAfterWrittenPortion(cohort: CohortIguanaObject) {
    return cohort.startDate < new Date(1723449600 * 1000); // cycle 62 start date
}

function addSchedule(
    doc: PDFKit.PDFDocument,
    assets: FetchedAssets,
    cohort: CohortIguanaObject,
    concentrations: AnyObject<PlaylistIguanaObject>,
    requiredStreams: AnyObject<StreamIguanaObject>,
) {
    const scheduleSections = getScheduleSections(cohort, concentrations, requiredStreams);
    const brandConfig = fetchBrandConfig(cohort.branding);
    const scheduleSectionLeftColumnX = 49;

    doc.x = scheduleSectionLeftColumnX;

    scheduleSections.forEach((scheduleSection, i) => {
        const numCurriculumComponentsInFirstScheduleSectionPeriod =
            scheduleSection.periods[0].curriculumComponents.length;
        const heigthOfNewSectionTitleAndFirstCurriculumComponent =
            SCHEDULE_SECTION_TITLE_HEIGHT +
            SCHEDULE_SECTION_TITLE_MARGIN_BOTTOM +
            numCurriculumComponentsInFirstScheduleSectionPeriod * CURRICULUM_COMPONENT_HEIGHT;

        if (doc.y + heigthOfNewSectionTitleAndFirstCurriculumComponent > SCHEDULE_PAGE_Y_BOUNDARY) {
            doc.addPage().image(assets.pages.pageB, 0, 0, PAGE_DIMENSIONS);

            const heightOfRemainingScheduleSections = getHeightOfScheduleSections(scheduleSections.slice(i));
            addScheduleBoxToPage(doc, heightOfRemainingScheduleSections);

            doc.x = scheduleSectionLeftColumnX;
            doc.y = SCHEDULE_SECTION_LEFT_COLUMN_Y;
        }

        doc.font(assets.fonts.proximaNovaSoftSemibold)
            .fontSize(16)
            .fillColor(brandConfig.themeColor)
            .text(scheduleSection.title, scheduleSectionLeftColumnX, doc.y);
        doc.y += SCHEDULE_SECTION_TITLE_MARGIN_BOTTOM;

        scheduleSection.periods.forEach((periodConfig, j) => {
            let lineY = doc.y;
            const scheduleItemHeight = periodConfig.curriculumComponents.length * CURRICULUM_COMPONENT_HEIGHT;

            if (lineY + scheduleItemHeight > SCHEDULE_PAGE_Y_BOUNDARY) {
                doc.addPage().image(assets.pages.pageB, 0, 0, PAGE_DIMENSIONS);

                const numRemainingCurriculumComponentsForScheduleSection = scheduleSection.periods
                    .slice(j)
                    .reduce((count, pc) => count + pc.curriculumComponents.length, 0);
                const remainingHeightOfCurrentScheduleSection =
                    numRemainingCurriculumComponentsForScheduleSection * CURRICULUM_COMPONENT_HEIGHT +
                    (scheduleSection === scheduleSections.at(-1) ? 0 : SCHEDULE_SECTION_MARGIN_BOTTOM);
                const heightOfRemainingScheduleSections =
                    remainingHeightOfCurrentScheduleSection +
                    getHeightOfScheduleSections(scheduleSections.slice(i + 1));
                addScheduleBoxToPage(doc, heightOfRemainingScheduleSections);

                doc.x = scheduleSectionLeftColumnX;
                doc.y = SCHEDULE_SECTION_LEFT_COLUMN_Y;
                lineY = doc.y;
            }

            const curriculumComponentLineGap = 3;
            doc.font(assets.fonts.proximaNovaSoftSemibold)
                .fontSize(12)
                .fillColor(oreo.COLOR_V3_EGGPLANT)
                .text(periodConfig.title, scheduleSectionLeftColumnX, lineY, {
                    lineGap: curriculumComponentLineGap,
                });

            periodConfig.curriculumComponents.forEach((curriculumComponent, k) => {
                const curriculumComponentY = k === 0 ? lineY : doc.y;
                doc.font(assets.fonts.proximaNovaSoftRegular).text(curriculumComponent, 217, curriculumComponentY, {
                    lineGap: curriculumComponentLineGap,
                    width: 370,
                });
            });
        });

        doc.y += SCHEDULE_SECTION_MARGIN_BOTTOM;
    });
}

function getScheduleSections(
    cohort: CohortIguanaObject,
    concentrations: AnyObject<PlaylistIguanaObject>,
    requiredStreams: AnyObject<StreamIguanaObject>,
) {
    let numSpecializationBlockPeriodsProcessed = 0;
    const numSpecializationBlockPeriods = getNumSpecializationBlockPeriods(cohort);
    const romanizedSpecializationBlockPeriodCounts: Record<number, string> = {
        1: 'I',
        2: 'II',
        3: 'III',
        4: 'IV',
        5: 'V',
    };
    const concentrationsByStreamLocalePackId = Object.values(requiredStreams).reduce<AnyObject<PlaylistIguanaObject>>(
        (prev, curr) => ({
            ...prev,
            [curr.localePackId]: Object.values(concentrations).find(concentration =>
                concentration.containsLocalePackId(curr.localePackId),
            )!,
        }),
        {},
    );
    const scheduleSections: ScheduleSection[] = [];
    const firstPeriod = cohort.periods[0];
    let scheduleSection = getNewScheduleSection({ startDate: firstPeriod.startDate, type: 'concentration' });
    let currentPeriodPlaylist: Nullable<PlaylistIguanaObject> = concentrations[cohort.concentrationPlaylistPackIds[1]]; // skip Foundations
    let sectionPlaylist: Nullable<PlaylistIguanaObject> = currentPeriodPlaylist;

    function completeScheduleSection(endDate: Date) {
        let scheduleSectionTitleEnd = '';
        if (scheduleSection.type === 'concentration') {
            // We always expect the sectionPlaylist to be defined
            // when the scheduleSection.type is 'concentration'.
            scheduleSectionTitleEnd = sectionPlaylist!.title;
        } else if (scheduleSection.type === 'specialization') {
            if (numSpecializationBlockPeriods === 1) {
                scheduleSectionTitleEnd = 'Specialization Period';
            } else {
                numSpecializationBlockPeriodsProcessed += 1;
                scheduleSectionTitleEnd = `Specialization Period ${romanizedSpecializationBlockPeriodCounts[numSpecializationBlockPeriodsProcessed]}`;
            }
        } else if (scheduleSection.type === 'capstone_due') {
            scheduleSectionTitleEnd = 'Capstone Project';
        }

        scheduleSection.title = `${formattedUserFacingMonthDayYearLong(
            scheduleSection.startDate,
            false,
        )} - ${formattedUserFacingMonthDayYearLong(endDate)}: ${scheduleSectionTitleEnd}`;
        return scheduleSection;
    }

    cohort.periods.forEach((period, i) => {
        let periodMarksStartOfSubsequentScheduleSection = false;
        currentPeriodPlaylist =
            currentPeriodPlaylist || getConcentrationForPeriod(period, concentrationsByStreamLocalePackId);

        // With the way the schedule is organized, a new schedule section starts when we encounter
        // a stream that belongs to a different concentration than the stream that comes before it
        // in the schedule. Specialization periods are typically grouped together in the schedule
        // to make up own schedule section. Lastly, since the capstone has its own number of credit
        // hours, Program Ops would like the "capstone due" period to have its own schedule section
        // so that it's at the same level as concentrations and specialization periods.
        let newScheduleSectionType = '';
        const capstoneDuePeriod = isCapstoneDuePeriod(period, cohort);
        if (period.style === 'standard') {
            if (!sectionPlaylist && currentPeriodPlaylist) {
                periodMarksStartOfSubsequentScheduleSection = true;
                newScheduleSectionType = 'concentration';
            } else if (sectionPlaylist !== currentPeriodPlaylist) {
                periodMarksStartOfSubsequentScheduleSection = true;
                newScheduleSectionType = 'concentration';
            }
        } else if (period.style === 'specialization') {
            periodMarksStartOfSubsequentScheduleSection = scheduleSection.type !== 'specialization';
            if (periodMarksStartOfSubsequentScheduleSection) {
                newScheduleSectionType = 'specialization';
            }
        } else if (capstoneDuePeriod) {
            periodMarksStartOfSubsequentScheduleSection = true;
            newScheduleSectionType = 'capstone_due';
        }

        if (periodMarksStartOfSubsequentScheduleSection) {
            // We've detected that we need to start a new schedule section,
            // so we need to complete the previous schedule section and create
            // a new one to process the current period.
            scheduleSections.push(completeScheduleSection(period.startDate));
            scheduleSection = getNewScheduleSection({ startDate: period.startDate, type: newScheduleSectionType });
            sectionPlaylist = currentPeriodPlaylist;
        }

        const periodConfig = {
            title: `${period.periodTitle.match(/Week \d{1,}/)![0]}: ${formattedUserFacingMonthDayYearLong(
                period.startDate,
                false,
            )}`,
            curriculumComponents: [] as string[],
        };

        if (period.style === 'standard') {
            period.requiredStreamLocalePackIds.forEach(streamLocalePackId => {
                periodConfig.curriculumComponents.push(requiredStreams[streamLocalePackId].title);
            });
        } else if (period.style === 'exam') {
            periodConfig.curriculumComponents.push(requiredStreams[period.requiredStreamLocalePackIds[0]].title);
            periodConfig.curriculumComponents.push(getExamOrProjectDueDateCurriculumComponent(period));
        } else if (period.style === 'project') {
            const projectCurriculumComponent = capstoneDuePeriod
                ? 'Capstone Project Due'
                : getProjectCurriculumComponent(period);
            periodConfig.curriculumComponents.push(projectCurriculumComponent);

            if (capstoneDuePeriod && capstonePresentationDueAfterWrittenPortion(cohort)) {
                periodConfig.curriculumComponents.push(
                    `Written Portion: ${formattedExamOrProjectDueDateAndSuffix(period)}`,
                );
                periodConfig.curriculumComponents.push(
                    `Presentation Portion: ${formattedExamOrProjectDueDateAndSuffix(period, 20)}`,
                );
            } else if (learnerProjectIsDueInPeriod(period.learner_projects[0], period, cohort)) {
                periodConfig.curriculumComponents.push(getExamOrProjectDueDateCurriculumComponent(period));
            }
        } else if (period.style === 'break') {
            const curriculumComponent = period.periodTitle.search(/Winter Break/i) > -1 ? 'Winter Break' : 'Break Week';
            periodConfig.curriculumComponents.push(curriculumComponent);
        } else if (period.style === 'specialization') {
            periodConfig.curriculumComponents.push('Specialization Period');
        } else {
            throw new Error(`Unexpected period style: '${period.style}'`);
        }

        scheduleSection.periods.push(periodConfig);
        currentPeriodPlaylist = null;

        // The schedule section normally gets completed while processing a subsequent period,
        // but if this is the last period in the schedule, we need to complete it now.
        if (i === cohort.periods.length - 1) {
            scheduleSections.push(completeScheduleSection(period.endDate));
        }
    });

    return scheduleSections;
}

function getNewScheduleSection(opts: Partial<ScheduleSection>): ScheduleSection {
    return { startDate: null, title: '', type: '', periods: [], ...opts };
}

function getHeightOfScheduleSections(scheduleSections: ScheduleSection[]) {
    return scheduleSections.reduce((totalHeight, scheduleSection) => {
        const numCurriculumComponentsInScheduleSection = numCurriculumComponentsForScheduleSection(scheduleSection);
        return (
            totalHeight +
            SCHEDULE_SECTION_TITLE_HEIGHT +
            SCHEDULE_SECTION_TITLE_MARGIN_BOTTOM +
            numCurriculumComponentsInScheduleSection * CURRICULUM_COMPONENT_HEIGHT +
            (scheduleSection === scheduleSections.at(-1) ? 0 : SCHEDULE_SECTION_MARGIN_BOTTOM)
        );
    }, 0);
}

function addScheduleBoxToPage(doc: PDFKit.PDFDocument, heightOfRemainingScheduleSections: number) {
    const scheduleBoxHeight =
        PAGE_B_SCHEDULE_BOX_Y + SCHEDULE_BOX_PADDING_TOP_BOTTOM + heightOfRemainingScheduleSections >
        SCHEDULE_PAGE_Y_BOUNDARY
            ? PAGE_B_SCHEDULE_BOX_MAX_HEIGHT
            : heightOfRemainingScheduleSections + SCHEDULE_BOX_PADDING_TOP_BOTTOM * 2;
    doc.rect(SCHEDULE_BOX_X, PAGE_B_SCHEDULE_BOX_Y, SCHEDULE_BOX_WIDTH, scheduleBoxHeight).fill(SCHEDULE_BOX_COLOR);
}

function numCurriculumComponentsForScheduleSection(scheduleSection: ScheduleSection) {
    return scheduleSection.periods.reduce((count, period) => count + period.curriculumComponents.length, 0);
}

function getConcentrationForPeriod(period: PeriodIguanaObject, concentrations: AnyObject<PlaylistIguanaObject>) {
    return (
        period.requiredStreamLocalePackIds
            .map(streamLocalePackId => concentrations[streamLocalePackId])
            .find(concentration => concentration) || null
    );
}

function getNumSpecializationBlockPeriods(cohort: CohortIguanaObject) {
    let inSpecializationBlockPeriod = false;
    return cohort.periods.reduce((count, period) => {
        if (period.style === 'specialization') {
            if (inSpecializationBlockPeriod === false) {
                inSpecializationBlockPeriod = true;
                count += 1;
                return count;
            }
        } else {
            inSpecializationBlockPeriod = false;
        }
        return count;
    }, 0);
}

// Returns a sanitized version of the period's title.
// For example:
//  "**Week 32: Markets and Economies Presentation**" => "Markets and Economies Presentation"
//  "Week 52: Accounting Project Due" => "Accounting Project Due"
function getProjectCurriculumComponent(period: PeriodIguanaObject) {
    const periodTitleWithoutWeekPrefix = period.periodTitle.match(/^.+: (.+)/)![1];
    return periodTitleWithoutWeekPrefix.endsWith('**')
        ? periodTitleWithoutWeekPrefix.slice(0, -2)
        : periodTitleWithoutWeekPrefix;
}

function learnerProjectIsDueInPeriod(
    learnerProject: LearnerProjectIguanaObject,
    period: PeriodIguanaObject,
    cohort: CohortIguanaObject,
) {
    return (
        cohort.periods
            .slice()
            .reverse()
            .find(p => (p.learner_project_ids || []).includes(learnerProject.id))?.startDate === period.startDate
    );
}

function isCapstoneDuePeriod(period: PeriodIguanaObject, cohort: CohortIguanaObject) {
    if (period.style !== 'project') {
        return false;
    }
    const capstoneProject = period.learner_projects.find(lp => lp.project_type === 'capstone');
    return capstoneProject ? learnerProjectIsDueInPeriod(capstoneProject, period, cohort) : false;
}

function getExamOrProjectDueDateCurriculumComponent(period: PeriodIguanaObject) {
    if (!['exam', 'project'].includes(period.style)) {
        throw new Error("Expected period style to be either 'exam' or 'project'.");
    }
    return `${period.style === 'exam' ? 'Last day to begin exam:' : 'Due by'} ${formattedExamOrProjectDueDateAndSuffix(
        period,
    )}`;
}

function formattedExamOrProjectDueDateAndSuffix(period: PeriodIguanaObject, daysOffset = 6) {
    return `${formattedUserFacingMonthDayLong(
        moment(period.startDate).add(daysOffset, 'days').toDate(),
        false,
    )}, 11:59 p.m. PT / UTC-7 hours`;
}
