import { setAuthHeaders } from 'ReduxHelpers';
import { type LaunchParams } from './BioSig.types';

const baseApiUrl = `${window.ENDPOINT_ROOT}/api/bio_sig/`;

export async function getBioSigUrl(endpoint: string, params: LaunchParams) {
    const searchParams = new URLSearchParams({
        return_to: window.location.pathname,
        ...params,
    });

    const requestInit = {
        method: 'GET',
        headers: setAuthHeaders(new Headers()),
    };
    const response = await fetch(`${baseApiUrl}${endpoint}?${searchParams}`, requestInit);

    try {
        const responseBodyJsonString = await response.text();
        return JSON.parse(responseBodyJsonString).url;
    } catch (e) {
        throw new Error('No url found in response.');
    }
}
