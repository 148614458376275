
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.choose_a_role = window.Smartly.locales.modules.en.back_royal.careers.choose_a_role || {};
window.Smartly.locales.modules.en.back_royal.careers.choose_a_role = {...window.Smartly.locales.modules.en.back_royal.careers.choose_a_role, ...{
    "role": "Role"
}
}
    