// define this as a provider so it can be used in the config method in FrontRoyal/angularModuleAngular/index.js
export default angular.module('Capabilities', []).provider('Capabilities', [
    '$windowProvider',

    function ($windowProvider) {
        const $window = $windowProvider.$get();
        const Capabilities = {};

        // depend on Modernizr to resolve touch capability
        Object.defineProperty(Capabilities, 'touchEnabled', {
            get() {
                return $window.Modernizr && $window.Modernizr.touchevents;
            },
            configurable: true, // tests
        });

        // depend on Modernizr to resolve localStorage support
        Object.defineProperty(Capabilities, 'localStorage', {
            get() {
                return $window.Modernizr && $window.Modernizr.localstorage; // all lowercase  =/
            },
            configurable: true, // tests
        });

        // depend on Modernizr to resolve sessionStorage support
        Object.defineProperty(Capabilities, 'sessionStorage', {
            get() {
                return $window.Modernizr && $window.Modernizr.sessionstorage; // all lowercase  =/
            },
            configurable: true, // tests
        });

        Object.defineProperty(Capabilities, 'cookies', {
            get() {
                return $window.Modernizr && $window.Modernizr.cookies; // all lowercase  =/
            },
            configurable: true, // tests
        });

        Object.defineProperty(Capabilities, 'cssTransitions', {
            get() {
                return $window.Modernizr.csstransitions;
            },
            configurable: true,
        });

        return {
            $get() {
                return Capabilities;
            },
        };
    },
]);
