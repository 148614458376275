import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';
import { formattedTelephoneNumber } from 'Telephone/TelephoneFormatter';

angularModule.factory('UserIdVerificationViewModel', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const DialogModal = $injector.get('DialogModal');
        const $rootScope = $injector.get('$rootScope');
        const ClientStorage = $injector.get('ClientStorage');

        const IdVerificationViewModel = SuperModel.subclass(function () {
            Object.defineProperty(this.prototype, 'contactMethod', {
                get() {
                    return this._contactMethod;
                },
                set(val) {
                    this._contactMethod = val;

                    if (val === 'email') {
                        this.email = $rootScope.currentUser.email;
                        this.phone = null;
                    } else {
                        this.email = null;
                        this.phone = $rootScope.currentUser.phone;
                    }
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'formattedEmailOrPhone', {
                get() {
                    if (this.contactMethod === 'email') {
                        return this.email;
                    }
                    return formattedTelephoneNumber(this.phone);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'step', {
                get() {
                    const user = $rootScope.currentUser;
                    if (!user) {
                        return 'form';
                    }
                    if (!user.unverifiedForCurrentIdVerificationPeriod) {
                        return 'verified';
                    }
                    if (!this.linkSentAt) {
                        return 'form';
                    }
                    if (
                        user.lastIdologyVerificationFailed &&
                        user.lastIdologyVerification.response_received_at * 1000 > this.linkSentAt
                    ) {
                        return 'failed';
                    }
                    if (this.linkSentAt) {
                        return 'polling';
                    }
                    return undefined;
                },
                configurable: true,
            });

            return {
                initialize() {
                    this.contactMethod = 'mobilePhone';
                },

                launchVerificationModalIfLastIdologyVerificationFailed() {
                    if (!$rootScope.currentUser || !$rootScope.currentUser.unverifiedForCurrentIdVerificationPeriod) {
                        return;
                    }

                    const lastIdologyVerification = $rootScope.currentUser.lastIdologyVerification;
                    if (
                        !lastIdologyVerification ||
                        !lastIdologyVerification.response_received_at ||
                        lastIdologyVerification.verified !== false
                    ) {
                        return;
                    }

                    // We only want to do this once, so we store it in client storage
                    const clientStorageKey = `launchVerificationModalIfLastIdologyVerificationFailed.${lastIdologyVerification.idology_id_number}`;
                    if (ClientStorage.getItem(clientStorageKey)) {
                        return;
                    }

                    ClientStorage.setItem(clientStorageKey, 'true');
                    // show a UI as though we requested a link before the failure, so we
                    // see the failed step
                    this.linkSentAt = 1000 * lastIdologyVerification.response_received_at - 1000;
                    this.launchVerificationModal();
                },

                launchVerificationModal() {
                    DialogModal.alert({
                        content:
                            '<user-id-verification-form user-id-verification-view-model="userIdVerificationViewModel"></user-id-verification-form>',
                        title: null,
                        class: 'id-verification',
                        scope: {
                            userIdVerificationViewModel: this,
                        },
                    });
                },

                requestLink() {
                    const params = {};
                    if (this.contactMethod === 'email') {
                        params.email = this.email;
                    } else {
                        params.phone = this.phone;
                    }
                    $rootScope.currentUser.requestIdologyLink(params);
                    this.linkSentAt = new Date().getTime();
                },

                toggleContactMethod() {
                    if (this.contactMethod === 'mobilePhone') {
                        this.contactMethod = 'email';
                    } else {
                        this.contactMethod = 'mobilePhone';
                    }
                },

                goBackToForm() {
                    this.linkSentAt = null;
                },
            };
        });

        return IdVerificationViewModel;
    },
]);
