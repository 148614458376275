import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/list_pagination.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('listPagination', [
    '$injector',

    function factory($injector) {
        const scrollHelper = $injector.get('scrollHelper');

        return {
            scope: {
                ngModel: '<',
                listLimit: '<',
                offset: '<',
                noMoreAvailable: '<',
                bottomPagination: '<?',
                getItems: '&',
                totalCount: '<',
                scrollToTop: '<?',
                scrollableElem: '<?',
                persistWhenZero: '<?',
                disable: '<?',
            },
            restrict: 'E',
            templateUrl,
            link(scope) {
                // NOTE: numRemainingItems only accounts for Items after the offset,
                // which is intentional for the pagination counter. (NOTE: this can
                // be negative while loading, but we handle that below when we set endingNumber)
                Object.defineProperty(scope, 'numRemainingItems', {
                    get() {
                        return scope.ngModel.length - scope.offset;
                    },
                    configurable: true, // for specs
                });

                Object.defineProperty(scope, 'numVisibleItems', {
                    get() {
                        return _.minBy([scope.listLimit, scope.numRemainingItems]);
                    },
                    configurable: true, // for specs
                });

                Object.defineProperty(scope, 'onLastPage', {
                    get() {
                        return !!(scope.noMoreAvailable && scope.numRemainingItems <= scope.listLimit);
                    },
                    configurable: true, // for specs
                });

                Object.defineProperty(scope, 'paginationCounter', {
                    get() {
                        const startingNumber = scope.ngModel.length > 0 ? scope.offset + 1 : 0;

                        // while loading, it's possible for the endingNumber to be less that the
                        // startingNumber.  That does not look good
                        const endingNumber = _.max([scope.offset + scope.numVisibleItems, startingNumber]);
                        return scope.listLimit === 1 ? startingNumber : [startingNumber, endingNumber].join(' - ');
                    },
                });

                Object.defineProperty(scope, 'shouldDisableNext', {
                    get() {
                        return (
                            scope.disable ||
                            scope.ngModel.length < scope.listLimit ||
                            scope.onLastPage ||
                            !!scope.disableNext
                        );
                    },
                });

                scope.getItemsAndMaybeScrollToTop = args => {
                    if (!!scope.bottomPagination || scope.scrollToTop) {
                        scrollHelper.scrollToTop(true, scope.scrollableElem);
                    }

                    scope.disableNext = true;
                    const promise = scope.getItems(args);
                    if (promise) {
                        promise.then(() => {
                            scope.disableNext = false;
                        });
                    } else {
                        scope.disableNext = false;
                    }
                };
            },
        };
    },
]);
