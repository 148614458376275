import angularModule from 'Careers/angularModule/scripts/careers_module';
import defaultImageUrl from 'vectors/graduationcap.svg';

angularModule.factory('EducationExperience', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const TranslationHelper = $injector.get('TranslationHelper');
        const educationExperienceDetailTranslationHelper = new TranslationHelper(
            'careers.edit_career_profile.education_experience_detail',
        );

        return Iguana.subclass(function () {
            this.alias('EducationExperience');
            this.setIdProperty('id');
            this.embedsMany('transcripts', 'S3TranscriptAsset');

            this.extend({
                // Alteration of this list needs to be reflected in `CareerProfile::TranscriptRequirementHelper::DEGREE_LEVELS`
                degreeOptions: [
                    'associate_of_applied_arts',
                    'associate_of_applied_science',
                    'associate_of_arts',
                    'associate_of_engineering',
                    'associate_of_political_science',
                    'associate_of_science',
                    'bachelor_of_accountancy',
                    'bachelor_of_accounting',
                    'bachelor_of_applied_science',
                    'bachelor_of_applied_science_2',
                    'bachelor_of_architecture',
                    'bachelor_of_arts',
                    'bachelor_of_arts_2',
                    'bachelor_of_arts_honours',
                    'bachelor_of_business_administration',
                    'bachelor_of_business_management',
                    'bachelor_of_business_studies',
                    'bachelor_of_civil_engineering',
                    'bachelor_of_commerce',
                    'bachelor_of_commerce_honours',
                    'bachelor_of_commerce_honours_2',
                    'bachelor_of_computer_applications',
                    'bachelor_of_computer_science',
                    'bachelor_of_computer_science_2',
                    'bachelor_of_computer_science_3',
                    'bachelor_of_dentistry',
                    'bachelor_of_education',
                    'bachelor_of_electrical_engineering',
                    'bachelor_of_engineering',
                    'bachelor_of_engineering_2',
                    'bachelor_of_engineering_honours',
                    'bachelor_of_engineering_in_electrical_engineering',
                    'bachelor_of_fine_arts',
                    'bachelor_of_information_technology',
                    'bachelor_of_laws',
                    'bachelor_of_laws_2',
                    'bachelor_of_laws_honours',
                    'bachelor_of_library_science',
                    'bachelor_of_library_science_2',
                    'bachelor_of_literature',
                    'bachelor_of_management_studies',
                    'bachelor_of_mathematics',
                    'bachelor_of_mechanical_engineering',
                    'bachelor_of_medical_laboratory_science',
                    'bachelor_of_medical_science',
                    'bachelor_of_medicine',
                    'bachelor_of_medicine_and_surgery',
                    'bachelor_of_medicine_and_surgery_2',
                    'bachelor_of_medicine_and_surgery_3',
                    'bachelor_of_medicine_and_surgery_4',
                    'bachelor_of_medicine_and_surgery_5',
                    'bachelor_of_medicine_and_surgery_6',
                    'bachelor_of_music',
                    'bachelor_of_music_2',
                    'bachelor_of_nursing',
                    'bachelor_of_pharmacy',
                    'bachelor_of_pharmacy_2',
                    'bachelor_of_philosophy',
                    'bachelor_of_physical_therapy_and_physiotherapy',
                    'bachelor_of_science',
                    'bachelor_of_science_2',
                    'bachelor_of_science_honours',
                    'bachelor_of_science_in_education',
                    'bachelor_of_science_in_electrical_engineering',
                    'bachelor_of_science_in_electrical_engineering_2',
                    'bachelor_of_science_in_nursing',
                    'bachelor_of_technology',
                    'bachelor_of_technology_honours',
                    'common_professional_examination',
                    'diploma_of_college_studies',
                    'doctor_of_dental_surgery',
                    'doctor_of_education',
                    'doctor_of_medicine',
                    'doctor_of_medicine_in_dentistry',
                    'doctor_of_naturopathic_medicine',
                    'doctor_of_nursing_practice',
                    'doctor_of_osteopathic_medicine',
                    'doctor_of_pharmacy',
                    'doctor_of_pharmacy_2',
                    'doctor_of_philosophy',
                    'doctor_of_philosophy_2',
                    'doctor_of_physical_therapy_and_physiotherapy',
                    'doctor_of_veterinary_medicine',
                    'graduate_diploma',
                    'graduate_diploma_2',
                    'graduate_diploma_in_law',
                    'high_school_diploma',
                    'higher_national_diploma',
                    'juris_doctor',
                    'master_in_urban_planning',
                    'master_of_advanced_studies',
                    'master_of_advanced_studies_2',
                    'master_of_architecture',
                    'master_of_arts',
                    'master_of_arts_2',
                    'master_of_business_administration',
                    'executive_master_of_business_administration',
                    'master_of_chemistry',
                    'master_of_commerce',
                    'master_of_commerce_2',
                    'master_of_commerce_3',
                    'master_of_computer_applications',
                    'master_of_divinity',
                    'master_of_education',
                    'master_of_education_2',
                    'master_of_engineering',
                    'master_of_engineering_2',
                    'master_of_fine_arts',
                    'master_of_international_affairs',
                    'master_of_international_studies',
                    'master_of_laws',
                    'master_of_music',
                    'master_of_music_2',
                    'master_of_pharmacy',
                    'master_of_pharmacy_2',
                    'master_of_philosophy',
                    'master_of_physical_therapy_and_physiotherapy',
                    'master_of_physics',
                    'master_of_physics_honours',
                    'master_of_public_health',
                    'master_of_research',
                    'master_of_science',
                    'master_of_science_2',
                    'master_of_science_3',
                    'master_of_science_4',
                    'master_of_science_in_education',
                    'master_of_social_work',
                    'master_of_studies',
                    'master_of_technology',
                    'nurse_practitioner',
                    'postgraduate_diploma',
                    'postgraduate_diploma_2',
                    'postgraduate_diploma_3',
                    'postgraduate_diploma_4',
                    'postgraduate_diploma_in_law',
                ],
            });
            this.degreeOptionsSet = {};
            _.forEach(this.degreeOptions, key => {
                this.degreeOptionsSet[key] = true;
            });

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.transcripts = this.transcripts || [];
            });

            Object.defineProperty(this.prototype, 'imageUrl', {
                get() {
                    return this.educational_organization && this.educational_organization.image_url
                        ? this.educational_organization.image_url
                        : defaultImageUrl;
                },
            });

            Object.defineProperty(this.prototype, 'orgName', {
                get() {
                    return this.educational_organization && this.educational_organization.text;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'degreeAndMajorString', {
                get() {
                    return _.compact([this.degreeName, this.major]).join(', ');
                },
            });

            Object.defineProperty(this.prototype, 'degreeAndOrgNameString', {
                get() {
                    // Degree is required in the application form, but I do see some in the db
                    // without it, so being defensive here
                    return this.degreeName ? `${this.degreeName} - ${this.orgName}` : this.orgName;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'degreeName', {
                get() {
                    return this.constructor.degreeOptionsSet[this.degree]
                        ? educationExperienceDetailTranslationHelper.get(this.degree)
                        : this.degree;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'degreeProgram', {
                get() {
                    return this.degree_program;
                },
                set(val) {
                    this.degree_program = val;
                },
            });

            Object.defineProperty(this.prototype, 'willNotComplete', {
                get() {
                    return this.will_not_complete;
                },
                set(val) {
                    this.will_not_complete = val;
                },
            });

            // this determines if the education experience is empty in the context of the education form.
            // if any of the form fields change, we should verify that this property still works as expected
            Object.defineProperty(this.prototype, 'isEmpty', {
                get() {
                    return (
                        (!this.educational_organization || !this.educational_organization.text) &&
                        !this.degree &&
                        !this.major &&
                        !this.minor &&
                        !this.gpa &&
                        angular.isUndefined(this.willNotComplete)
                    );
                },
            });

            Object.defineProperty(this.prototype, 'transcriptUploaded', {
                get() {
                    return this.transcripts.length > 0;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'officialTranscriptUploaded', {
                get() {
                    return this.transcripts.filter(transcript => transcript.transcript_type === 'official').length > 0;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'unofficialTranscripts', {
                get() {
                    return this.transcripts.filter(transcript => transcript.transcript_type === 'unofficial');
                },
                configurable: true,
            });

            // See also transcript_requirement_helper.rb
            Object.defineProperty(this.prototype, 'canRequireOfficial', {
                get() {
                    return (
                        this.degreeProgram &&
                        !this.will_not_complete &&
                        this.graduation_year <= new Date().getFullYear()
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'officialTranscriptUploadedOrWaived', {
                get() {
                    return this.officialTranscriptUploaded || this.transcriptWaived;
                },
                configurable: true,
            });

            // See also education_experience.rb
            Object.defineProperty(this.prototype, 'transcriptApprovedOrWaived', {
                get() {
                    return this.transcript_approved || this.transcriptWaived;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'officialTranscriptInReview', {
                get() {
                    return !this.transcript_approved && this.officialTranscriptUploaded;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'transcriptWaived', {
                get() {
                    return !!this.transcript_waiver;
                },
                configurable: true,
            });

            return {};
        });
    },
]);
