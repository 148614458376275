import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('Period', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const Action = $injector.get('PeriodAction');
        const Exercise = $injector.get('PeriodExercise');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.alias('Period');
            this.embeddedIn('cohort');
            this.embedsMany('actions', 'PeriodAction');
            this.embedsMany('exercises', 'PeriodExercise');
            this.embedsMany('learner_projects', 'LearnerProject');

            // eslint-disable-next-line func-names
            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.title = this.title || '';
                this.stream_entries = this.stream_entries || [];
                this.style = this.style || 'standard';
                this.days = this.days || 7;
                this.days_offset = this.days_offset || 0;
                this.additional_specialization_playlists_to_complete =
                    this.additional_specialization_playlists_to_complete || 0;
                this.actions = this.actions || [];
                this.exercises = this.exercises || [];
                this.learner_projects = this.learner_projects || [];

                // cleaning up old stuff.  This can be removed once they've all
                // been cleaned out of existing content
                this.required_stream_pack_ids = undefined;
                this.optional_stream_pack_ids = undefined;
            });

            // eslint-disable-next-line func-names
            this.defineSetter('style', function (newStyle) {
                const result = this.writeKey('style', newStyle);

                // make sure that exam_style is not set when
                // it is not applicable
                if (!this.canHaveExamStyle) {
                    this.exam_style = null;
                }

                // default exam_style to intermediate
                if (this.canHaveExamStyle && !this.exam_style) {
                    this.exam_style = 'intermediate';
                }

                return result;
            });

            // eslint-disable-next-line func-names
            this.defineSetter('exam_style', function (newStyle) {
                if (newStyle && !this.canHaveExamStyle) {
                    throw new Error('exam_style can only be set if the style is exam or review');
                }
                return this.writeKey('exam_style', newStyle);
            });

            Object.defineProperty(this.prototype, 'index', {
                get() {
                    const cohort = this.cohort();
                    return cohort && cohort.periods.indexOf(this);
                },
            });

            Object.defineProperty(this.prototype, 'periodTitle', {
                get() {
                    return this.title ? this.title : `Week ${this.index + 1}`;
                },
            });

            Object.defineProperty(this.prototype, 'endDate', {
                get() {
                    return this.$$endDate;
                },
                set(val) {
                    this.$$endDate = val;
                },
            });

            Object.defineProperty(this.prototype, 'isWinterBreakPeriod', {
                get() {
                    return this.style === 'break' && this.title.toLowerCase().indexOf('winter break') >= 0;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'canHaveExamStyle', {
                get() {
                    return _.includes(['exam', 'review'], this.style);
                },
            });

            Object.defineProperty(this.prototype, 'canHaveSpecializationStyle', {
                get() {
                    return _.includes(['specialization', 'project'], this.style);
                },
            });

            Object.defineProperty(this.prototype, 'startDate', {
                get() {
                    return this.$$startDate;
                },
                set(val) {
                    this.$$startDate = val;
                },
            });

            Object.defineProperty(this.prototype, 'requiredStreamLocalePackIds', {
                get() {
                    const self = this;
                    // always return the same array, but rebuild it each time
                    self.$$requiredStreamLocalePackIds = self.$$requiredStreamLocalePackIds || [];
                    self.$$requiredStreamLocalePackIds.splice(0);
                    _.chain(self.stream_entries)
                        .filter({
                            required: true,
                        })
                        .map('locale_pack_id')
                        .forEach(localePackId => {
                            self.$$requiredStreamLocalePackIds.push(localePackId);
                        })
                        .value();
                    return self.$$requiredStreamLocalePackIds;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'optionalStreamLocalePackIds', {
                get() {
                    const self = this;
                    // always return the same array, but rebuild it each time
                    self.$$optionalStreamLocalePackIds = self.$$optionalStreamLocalePackIds || [];
                    self.$$optionalStreamLocalePackIds.splice(0);
                    _.chain(self.stream_entries)
                        .filter({
                            required: false,
                        })
                        .map('locale_pack_id')
                        .forEach(localePackId => {
                            self.$$optionalStreamLocalePackIds.push(localePackId);
                        })
                        .value();
                    return self.$$optionalStreamLocalePackIds;
                },
            });

            Object.defineProperty(this.prototype, 'streamLocalePackIds', {
                get() {
                    const self = this;
                    // always return the same array, but rebuild it each time
                    self.$$streamLocalePackIds = self.$$streamLocalePackIds || [];
                    self.$$streamLocalePackIds.splice(0);
                    _.chain(self.stream_entries)
                        .map('locale_pack_id')
                        .forEach(localePackId => {
                            self.$$streamLocalePackIds.push(localePackId);
                        })
                        .value();
                    return self.$$streamLocalePackIds;
                },
            });

            return {
                addAction() {
                    const action = Action.new();

                    action.$$embeddedIn = this;
                    this.actions.unshift(action);

                    return action;
                },

                addExercise(attrs = {}) {
                    const exercise = Exercise.new(attrs);

                    exercise.$$embeddedIn = this;
                    this.exercises.push(exercise);

                    return exercise;
                },

                removeExercise(exercise) {
                    this.exercises = _.without(this.exercises, exercise);
                },

                requiresStream(localePackId) {
                    const entry = _.find(this.stream_entries, {
                        locale_pack_id: localePackId,
                        required: true,
                    });
                    return !!entry;
                },

                logInfo() {
                    return {
                        title: this.title,
                        style: this.style,
                        exam_style: this.exam_style,
                    };
                },
            };
        });
    },
]);
