import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/choose_a_role.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('chooseARole', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('careers.choose_a_role');
        const fieldOptionsTranslationHelper = new TranslationHelper('careers.field_options');
        const roleKeys = $injector.get('CAREERS_AREA_KEYS');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                placeholderText: '<?',
                mode: '<?',
                min: '<?',
                max: '<?',
                disableOrdering: '<?',
                allowCreate: '<?',
                allOption: '<?',
                displaySelectedOptions: '<?',
                shouldDisable: '<?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, elem, attrs) {
                const topLevelKeys = Object.keys(roleKeys);
                const alphabetizedRoleKeys = {};

                // Sort the top-level keys
                Object.keys(roleKeys)
                    .sort((key, nextKey) =>
                        fieldOptionsTranslationHelper
                            .get(key)
                            .localeCompare(
                                fieldOptionsTranslationHelper.get(nextKey),
                                fieldOptionsTranslationHelper.getCurrentLanguage(),
                            ),
                    )
                    .forEach(sortedKey => {
                        if (sortedKey !== 'other') {
                            alphabetizedRoleKeys[sortedKey] = roleKeys[sortedKey];
                        }
                    });
                alphabetizedRoleKeys.other = undefined; // make sure other is last

                // Sort the low-level keys
                Object.keys(alphabetizedRoleKeys).forEach(key => {
                    alphabetizedRoleKeys[key]?.sort((a, b) =>
                        fieldOptionsTranslationHelper
                            .get(a)
                            .localeCompare(
                                fieldOptionsTranslationHelper.get(b),
                                fieldOptionsTranslationHelper.getCurrentLanguage(),
                            ),
                    ); // in place
                });

                //-------------------------------
                // Global Config
                //-------------------------------

                scope.isRequired = angular.isDefined(attrs.required);
                scope.placeholderText = scope.placeholderText || translationHelper.get('role');
                scope.mode = attrs.mode || 'select';
                scope.displaySelectedOptions = angular.isDefined(scope.displaySelectedOptions)
                    ? scope.displaySelectedOptions
                    : true;

                //-------------------------------
                // Selectize Config
                //-------------------------------

                // it's 2017 and this is apparently the only / best way to consistently indent select option values ...
                // charCode 160 is the unicode for a non-breaking space, which doesn't need escaping, so we can use it without ng-html in views
                const indent =
                    scope.mode === 'selectize'
                        ? ' - '
                        : String.fromCharCode(160) + String.fromCharCode(160) + String.fromCharCode(160);

                //-------------------------------
                // Multi-Select Config
                //-------------------------------

                scope.min = scope.min || 0;
                scope.disableOrdering = scope.disableOrdering || false;
                scope.allowCreate = scope.allowCreate || false;

                // don't indent multi-select list items or top-level entries
                scope.getOptionLabel = (option, listItem) =>
                    (listItem || topLevelKeys.includes(option) ? '' : indent) +
                    fieldOptionsTranslationHelper.get(option);
                scope.getOptionDisabled = option => Array.isArray(alphabetizedRoleKeys[option]);

                //-------------------------------
                // Select Config
                //-------------------------------

                scope.accountForSelectIndent = () => {
                    const option = _.find(scope.roleOptions, {
                        value: scope.ngModel,
                    });

                    if (option && option.indent) {
                        scope.selectStyles = {
                            'padding-left': '0px', // enough to offset the &nbsp; indent pattern
                        };
                    } else {
                        scope.selectStyles = undefined;
                    }
                };

                //-------------------------------
                // Initialization
                //-------------------------------

                // iterate through tree and produce appropariate data provider for target mode
                function processCategory(detailed, general) {
                    if (scope.mode === 'multi') {
                        return [general, detailed];
                    }

                    const topLevel = {
                        value: general,
                        label: fieldOptionsTranslationHelper.get(general),
                    };

                    // Disable top-level items
                    if (detailed) {
                        topLevel.disabled = true;
                    }

                    const nestedLevel = _.map(detailed, detailedRole => ({
                        value: detailedRole,
                        indent: true,
                        label: indent + fieldOptionsTranslationHelper.get(detailedRole),
                    }));

                    return [topLevel, nestedLevel];
                }

                // build data provider for target mode
                scope.roleOptions = _.chain(alphabetizedRoleKeys).map(processCategory).flattenDeep().compact().value();

                // handle any special-case rendering tweaks
                if (scope.mode === 'select' && scope.ngModel) {
                    scope.accountForSelectIndent();
                }
            },
        };
    },
]);
