import { getRelativeDate } from './Cohort';
import { type Cohort, type IdVerificationPeriod } from './Cohort.types';

export function verificationPeriodStartDate(cohort: Cohort, idVerificationPeriod: IdVerificationPeriod) {
    return getRelativeDate(cohort, idVerificationPeriod.startDateDaysOffset);
}

export function verificationPeriodDueDate(cohort: Cohort, idVerificationPeriod: IdVerificationPeriod) {
    return getRelativeDate(cohort, idVerificationPeriod.dueDateDaysOffset);
}

export function verificationPeriodPastDue(cohort: Cohort, idVerificationPeriod: IdVerificationPeriod) {
    return verificationPeriodDueDate(cohort, idVerificationPeriod) < new Date();
}
