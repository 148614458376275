import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/answers_too_short_modal.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('answersTooShortModal', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            scope: {
                onFinish: '<',
            },
            templateUrl,
            link(scope) {
                scope.closeModal = () => {
                    scope.onFinish();
                };
            },
        };
    },
]);
