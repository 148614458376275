
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.basic_info_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.basic_info_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.basic_info_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.basic_info_form, ...{
    "name": "Full Name",
    "nickname": "First Name or Nickname",
    "nickname_what_prefer": "What do you prefer we call you?",
    "nickname_for_example": "e.g.: your first name",
    "location": "Location",
    "mailing_address": "Mailing Address",
    "mailing_address_description": "Strictly confidential. For identity verification and diploma delivery.",
    "birthdate": "Birthdate",
    "city_state": "City, State",
    "address_line_1": "Address line 1",
    "address_line_1_placeholder": "Street address, P.O. box, company name, c/o",
    "address_line_2": "Address line 2",
    "address_line_2_placeholder": "Apartment, suite, unit, building, floor, etc.",
    "city": "City",
    "state": "State / Province / Region",
    "zip": "ZIP",
    "country": "Country",
    "invalid_birthdate": "Invalid age ({{age}} years old)"
}
}
    