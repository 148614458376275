import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/candidate_list_card.html';
import candidateExpandedProfileTemplate from 'Careers/angularModule/views/candidate_expanded_profile.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import moment from 'moment-timezone';

const templateUrl = cacheAngularTemplate(angularModule, template);

cacheAngularTemplate(angularModule, 'Careers/candidate_expanded_profile.html', candidateExpandedProfileTemplate);

angularModule.directive('candidateListCard', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const candidateListCardViewHelper = $injector.get('candidateListCardViewHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const EventLogger = $injector.get('EventLogger');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            scope: {
                careerProfile: '<',
                disableActions: '<?',
                showFullProfile: '<?',
                preview: '<?',
            },
            templateUrl,

            link(scope) {
                NavigationHelperMixin.onLink(scope);

                // used for showing user's full name or just their initials
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.showAuthorizedToWorkInUS =
                    scope.careerProfile.authorized_to_work_in_us &&
                    scope.careerProfile.authorized_to_work_in_us !== 'citizen';
                scope.showOpenAndFlexible =
                    scope.careerProfile.willing_to_relocate && scope.careerProfile.openToAllLocations;

                // By default, open_to_remote_work is set to true, but the user can't immediately update this value until their profile
                // has been screened by an admin and the user has been granted permission to edit their profile, so we don't put this bit
                // of information on display until they've completed their career profile, when we know they've at least seen the form field.
                // So we need to check if the user looking at the candidate list card is the owner of the career profile.
                // If they aren't the owner, we want to show that their open to remote work if they've indicated as such.
                scope.showOpenToRemoteWork =
                    scope.careerProfile.open_to_remote_work &&
                    (scope.currentUser.id !== scope.careerProfile.user_id || scope.careerProfile.completedAt);

                // the helper does all the work to summarize the data in a clean form
                scope.userInfo = candidateListCardViewHelper.getSummarizedProfileInfo(scope.careerProfile);

                function degreeProgramFilter(educationExperience) {
                    return educationExperience.degreeProgram;
                }

                scope.degreePrograms = scope.careerProfile.educationExperiences
                    ? _.filter(scope.careerProfile.educationExperiences, degreeProgramFilter)
                    : [];
                scope.nonFormalPrograms = scope.careerProfile.educationExperiences
                    ? _.reject(scope.careerProfile.educationExperiences, degreeProgramFilter)
                    : [];

                const translationHelper = new TranslationHelper('careers.candidate_list_card');

                scope.workExperienceDuration = duration => {
                    const monthInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 1 month min for display
                    duration = Math.max(monthInMilliseconds, duration);

                    const output = [];
                    const years = moment.duration(duration).years();
                    const months = moment.duration(duration).months();

                    if (years) {
                        output.push(
                            translationHelper.get(
                                'num_years',
                                {
                                    num: years,
                                },
                                undefined,
                                'messageformat',
                            ),
                        );
                    }

                    if (months) {
                        output.push(
                            translationHelper.get(
                                'num_months',
                                {
                                    num: months,
                                },
                                undefined,
                                'messageformat',
                            ),
                        );
                    }

                    return output.join(', ');
                };

                scope.openExternalLink = (url, event_label) => {
                    scope.logEvent('candidate_list_card:clicked_external_link', {
                        label: event_label,
                        link: url,
                    });
                    scope.loadUrl(url, '_blank');
                };

                scope.logEvent = (name, payload) => {
                    EventLogger.log(
                        name,
                        angular.extend(payload || {}, {
                            candidate_id: scope.careerProfile.user_id,
                        }),
                    );
                };

                scope.editProfile = section => {
                    scope.$emit('gotoEditProfileSection', section);
                };

                scope.editFunFact = () => {
                    scope.editProfile('student_network_settings');
                };
            },
        };
    },
]);
