
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.more_about_you_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.more_about_you_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.more_about_you_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.more_about_you_form, ...{
    "add_a_skill": "add a skill",
    "add_an_award": "add an award or interest",
    "awards_and_interests": "Awards & Interests",
    "awards_and_interests_please_list": "Please list 2 or more awards or interests you'd like to highlight, in order of importance.",
    "awards_and_interests_please_list_non_english": "Please list 2 or more awards or interests you'd like to highlight, in order of importance. Please answer in English.",
    "awards_and_interests_desc": "Please list 2 or more awards or interests you’d like to highlight to employers. High rank items will be shown more prominently.",
    "awards_and_interests_examples": "Examples: Startup Weekend Finalist, Competitive poker player, etc.",
    "my_attributes": "My Attributes",
    "my_attributes_desc": "Choose and rank the 3 words that best define you. The top being the most defining.",
    "my_motivations": "My Motivations",
    "my_motivations_desc": "Choose and rank the 3 motivations that best define you. The top being the most defining.",
    "my_strengths": "My Strengths",
    "my_strengths_desc": "Choose and rank the 3 attributes that best define your workplace strengths. The top being the most defining.",
    "hispanic_or_latino": "Are you Hispanic or Latino?",
    "primary_reason_for_applying_post_application": "What is the primary reason you applied to the {{brandName}} {{programType}}?",
    "smartscores": "Subject Areas",
    "smartscores_desc": "Choose the 3 subjects in the {{programType}} that you are most interested in.",
    "other_primary_reason_placeholder": "Primary reason (other)"
}
}
    