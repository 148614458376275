import 'DateHelper/angularModule';
import 'EventLogger/angularModule';
import 'ImageFadeInOnLoad/angularModule';
import 'Telephone/angularModule';
import 'Translation/angularModule';
import 'Users/angularModule';

export default angular.module('FrontRoyal.Cohorts', [
    'EventLogger',
    'Translation',
    'FrontRoyal.Users',
    'DateHelper',
    'FrontRoyal.Telephone',
    'imageFadeInOnLoad',
]);
