import { createApi } from 'ReduxHelpers';
import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { COHORT_FOR_ADMIN_LISTS_ATTRS } from './CohortAttrs';
import { type CohortForAdminListsResponse } from './CohortIndex.types';
import { type CohortForAdminListsAttrs } from './CohortAttrs.types';

export const cohortsApi = createApi('CohortsApi', {
    ...getBackRoyalApiBaseConfig('CohortsApi'),
    endpoints: builder => ({
        getCohortsForAdmin: builder.query<CohortForAdminListsAttrs[], void>({
            query: () => ({
                url: 'cohorts.json',
                params: { 'fields[]': COHORT_FOR_ADMIN_LISTS_ATTRS },
            }),
            transformResponse: (response: CohortForAdminListsResponse) =>
                response.contents.cohorts.sort((a, b) => (a.startDate < b.startDate ? -1 : 1)),
        }),
    }),
});

export default cohortsApi;
