import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';
import moment from 'moment-timezone';
import hasDateAsTimestampInJson from 'hasDateAsTimestampInJson';
import camelCaseKeys from 'camelcase-keys';

angularModule.factory('Cohort', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $filter = $injector.get('$filter');
        const dateHelper = $injector.get('dateHelper');
        const Schedulable = $injector.get('Schedulable');
        const $rootScope = $injector.get('$rootScope');
        const Stream = $injector.get('Lesson.Stream');
        const guid = $injector.get('guid');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.setCollection('cohorts');
            this.alias('Cohort');
            this.setIdProperty('id');
            this.include(Schedulable);

            this.embedsMany('cohort_sections', 'CohortSection');

            hasDateAsTimestampInJson(this, 'startDate');

            // eslint-disable-next-line func-names
            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.specialization_playlist_pack_ids = this.specialization_playlist_pack_ids || [];
                this.periods = this.periods || [];
                this.start_date = this.start_date || this.computeDefaultStartDate().getTime() / 1000;

                // Sort the admission_rounds
                // FIXME: Check if there is an Iguana way to do this
                this.admission_rounds = _.sortBy(this.admission_rounds, 'application_deadline');

                // Fill out dates for all the periods
                this.computePeriodDates();

                this.slack_rooms = this.slack_rooms || [];
                this.cohort_sections = this.cohort_sections || [];

                this.setDefaultEnrollmentDocumentsDeadline();
                this.setDefaultOfficialTranscriptsDeadline();

                // For use in es6 functions
                this.idVerificationPeriods = this.id_verification_periods?.map(period => camelCaseKeys(period));
                this.enrollmentDeadlineDaysOffset = this.enrollment_deadline_days_offset;
            });

            this.VALID_STATUSES = ['closed', 'open'];

            this.DEFAULT_REGISTRATION_DEADLINE_DAYS_OFFSET = -7; // see also cohort.rb
            this.DEFAULT_AUTO_DECLINE_ADMISSION_OFFERS_DAYS_OFFSET = this.DEFAULT_REGISTRATION_DEADLINE_DAYS_OFFSET + 2;

            this.extend({
                // Given an admission round, a cohort that includes that admission round, and a set of cohorts with that cohort, determine the previous
                // admission round if possible
                getPreviousAdmissionRound(admissionRound, cohort, cohorts) {
                    // Find the index of the admission round we want the previous for
                    const admissionRoundIndex = _.indexOf(cohort.admission_rounds, admissionRound);

                    // If it is not the first then just snag the one before it. Easy peasy.
                    if (admissionRoundIndex > 0) {
                        return cohort.admission_rounds[admissionRoundIndex - 1].applicationDeadline;
                    }
                    if (admissionRoundIndex === 0) {
                        // If it is the first admission around then we need to go to the previous cohort's (of the same type)
                        // last admission round.
                        cohorts = _.filter(cohorts, {
                            program_type: cohort.program_type,
                        });
                        cohorts = _.sortBy(cohorts, 'startDate');

                        // Find the cohort that we want the previous for
                        const cohortIndex = _.indexOf(cohorts, cohort);

                        if (cohortIndex > 0) {
                            const previousCohort = cohorts[cohortIndex - 1];
                            return _.isEmpty(previousCohort.admission_rounds)
                                ? null
                                : _.last(previousCohort.admission_rounds).applicationDeadline;
                        }
                        // There is no cohort to get a previous admission round for
                        return null;
                    }
                    // eslint-disable-next-line no-console
                    console.warn('Could not find admission round');
                    return null;
                },
            });

            Object.defineProperty(this.prototype, 'currentPeriod', {
                get() {
                    if (
                        this.current_period_index === null ||
                        this.current_period_index === undefined ||
                        this.current_period_index < 0
                    ) {
                        return null;
                    }

                    return this.periods[this.current_period_index];
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'foundationsPlaylistLocalePackId', {
                get() {
                    return this.foundations_playlist_locale_pack ? this.foundations_playlist_locale_pack.id : null;
                },
            });

            // this is true for cohorts, but not curriculum templates
            Object.defineProperty(this.prototype, 'supportsExternalNotes', {
                // eslint-disable-next-line lodash-fp/prefer-constant
                get() {
                    return true;
                },
            });

            Object.defineProperty(this.prototype, 'localizedFoundationsPlaylist', {
                get() {
                    let localizedFoundationsPlaylist = _.find(this.foundations_playlist_locale_pack.content_items, {
                        locale: $rootScope.currentUser.pref_locale,
                    });

                    // If a version of the foundations playlist does not exist then default to 'en'
                    if (!localizedFoundationsPlaylist) {
                        localizedFoundationsPlaylist = _.find(this.foundations_playlist_locale_pack.content_items, {
                            locale: 'en',
                        });
                        // eslint-disable-next-line no-console
                        console.warn(
                            `No localized version exists for ${this.name} foundation playlist. Defaulting to en.`,
                        );
                    }

                    return localizedFoundationsPlaylist;
                },
            });

            Object.defineProperty(this.prototype, 'isUnfinishedDegreeProgram', {
                get() {
                    return this.isDegreeProgram && this.endDate > new Date();
                },
            });

            Object.defineProperty(this.prototype, 'isFinishedDegreeProgram', {
                get() {
                    return this.isDegreeProgram && this.endDate <= new Date();
                },
            });

            Object.defineProperty(this.prototype, 'createdAt', {
                get() {
                    return new Date(this.created_at * 1000);
                },
            });

            Object.defineProperty(this.prototype, 'updatedAt', {
                get() {
                    return new Date(this.updated_at * 1000);
                },
            });

            // See also cohort.rb#trial_end_date
            Object.defineProperty(this.prototype, 'trialEndDate', {
                get() {
                    if (!this.$$trialEndDate) {
                        this.$$trialEndDate = new Date(this.trial_end_date * 1000);
                    }
                    return this.$$trialEndDate;
                },
                configurable: true, // specs
            });

            Object.defineProperty(this.prototype, 'lastSavedAtDateString', {
                get() {
                    if (!this.updated_at) {
                        return 'Unknown';
                    }
                    return $filter('amDateFormat')(this.updatedAt, 'l h:mm:ss a');
                },
            });

            Object.defineProperty(this.prototype, 'modifiedAtDateString', {
                get() {
                    if (!this.modified_at) {
                        return 'Unknown';
                    }
                    return $filter('amDateFormat')(this.modified_at, 'l h:mm:ss a');
                },
            });

            Object.defineProperty(this.prototype, 'publishedAt', {
                get() {
                    return (this.published_at && new Date(1000 * this.published_at)) || undefined;
                },
            });

            Object.defineProperty(this.prototype, 'lastPublishedDateString', {
                get() {
                    if (!this.published_at) {
                        return 'Unknown';
                    }
                    return $filter('amDateFormat')(this.publishedAt, 'l h:mm:ss a');
                },
            });

            Object.defineProperty(this.prototype, 'incomplete', {
                get() {
                    return !this.name || !this.title || !this.start_date;
                },
            });

            _.forEach(['exam', 'review', 'break', 'specialization', 'project'], style => {
                Object.defineProperty(this.prototype, `${style}Periods`, {
                    get() {
                        return _.filter(this.periods, {
                            style,
                        });
                    },
                });
            });

            Object.defineProperty(this.prototype, 'supportsExternalScheduleUrl', {
                get() {
                    return this.supportsSchedule;
                },
            });

            Object.defineProperty(this.prototype, 'requiredStreamPackIdsCache', {
                get() {
                    const self = this;
                    if (!self._requiredStreamPackIdsCache) {
                        self._requiredStreamPackIdsCache = {};
                        _.forEach(self.getRequiredStreamPackIdsFromPeriods(), localePackId => {
                            self._requiredStreamPackIdsCache[localePackId] = true;
                        });
                    }
                    return self._requiredStreamPackIdsCache;
                },
                configurable: true,
            });

            /*
                This date only applies to users who have deferred from a previous Cohort and are not registered.
                They will not be able to register for their new Cohort until 180 days before the registration deadline.
                Admission offers are not sent out until well after the registrationOpenDate anyway, so this date is not
                important for people receiving a fresh admission offer.
            */
            Object.defineProperty(this.prototype, 'registrationOpenDate', {
                get() {
                    return new Date(moment(this.registrationDeadline).subtract(180, 'days'));
                },
                configurable: true,
            });

            // See also the Cohort#after_registration_open_date? method on the server where this logic is duplicated.
            Object.defineProperty(this.prototype, 'afterRegistrationOpenDate', {
                get() {
                    if (this.isExecEd) return true; // HACK: https://trello.com/c/gbvEER9G

                    return moment().valueOf() >= moment(this.registrationOpenDate).startOf('day').valueOf();
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'inEarlyAdmissionsRegistrationPeriod', {
                get() {
                    if (this.isExecEd) return false;

                    return (
                        moment().valueOf() < moment(this.lastDecisionDate).startOf('day').subtract(1, 'days').valueOf()
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'registrationDeadlineHasPassed', {
                get() {
                    return moment().valueOf() >= moment(this.registrationDeadline).valueOf();
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasVerificationPeriods', {
                get() {
                    return _.some(this.id_verification_periods);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isLegacyEnrollmentCohort', {
                get() {
                    // special handling for EMBA10 and earlier / MBA15 and earlier where we don't necessarily
                    // want to corrupt the notion of receiving an enrollment agreement by updating the backing data,
                    // but want to indicate that these applications had gone through a similar / comparable process
                    return (
                        (this.program_type === 'mba' && this.startDate < new Date('2018/08/21')) ||
                        (this.program_type === 'emba' && this.startDate < new Date('2018/07/17'))
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'learnerProjectIdsFromPeriods', {
                get() {
                    return _.chain(this.periods).map('learner_project_ids').flattenDeep().uniq().compact().value();
                },
            });

            Object.defineProperty(this.prototype, 'lastDecisionDate', {
                get() {
                    const lastAdmissionRound = this.admission_rounds.at(-1);
                    return lastAdmissionRound && lastAdmissionRound.decisionDate;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'programGuideUrl', {
                get() {
                    return this.program_guide_url;
                },
            });

            Object.defineProperty(this.prototype, 'hasSlackRooms', {
                get() {
                    return this.slack_rooms ? this.slack_rooms.length > 0 : false;
                },
            });

            Object.defineProperty(this.prototype, 'requiresMailingAddress', {
                get() {
                    // diplomas are physical objects tht we need to be able to mail.
                    // If this logic ever changes, see sidebarBoxConfigs -> SidebarBoxKey.diploma_check -> desktopContentHtml
                    return this.supportsPhysicalDiploma;
                },
            });

            return {
                persistsEndDate: true,
                supportsTitle: true,

                getConcentrationPlaylists(playlists) {
                    playlists = _.keyBy(playlists, 'localePackId');
                    return _.chain(this.concentrationPlaylistPackIds)
                        .map(localePackId => playlists[localePackId])
                        .compact()
                        .value();
                },

                getSpecializationPlaylists(playlists) {
                    playlists = _.keyBy(playlists, 'localePackId');
                    return _.chain(this.specialization_playlist_pack_ids)
                        .map(localePackId => playlists[localePackId])
                        .compact()
                        .value();
                },

                isStreamInFoundationsPlaylist(streamLocalePackId) {
                    return this.foundations_lesson_stream_locale_pack_ids.includes(streamLocalePackId);
                },

                // NOTE: this calculation should return the same value as cohort_selected_courses_percent_complete in server code
                // It includes all courses that are either in concentration playlists or required in the schedule.  This means that
                // we include non-required foundations streams in here.  We do that because they show up in the UI as part of the
                // curriculum, so it would be odd if the curriculum was not all filled in, but at the top it said 100% complete.  Also,
                // we want it to be clear to someone who hasn't finished all of foundations that there is still more for them to do,
                // even if it isn't required.  See https://trello.com/c/wWAhZqLY
                getSelectedCoursesPercentComplete(playlists) {
                    // Used for uniq()
                    const getComparisonValue = item => item.id;

                    // Get the playlists that are in this cohort
                    const concentrationPlaylistsForCohort = this.getConcentrationPlaylists(playlists);

                    // Throw an error if we don't have all the playlists available
                    if (concentrationPlaylistsForCohort.length !== this.concentrationPlaylistPackIds.length) {
                        throw new Error('Loaded Playlists does not match Cohort#concentrationPlaylistPackIds');
                    }

                    const streamsFromConcentrationPlaylists = _.chain(concentrationPlaylistsForCohort)
                        .map('streams')
                        .flattenDeep()
                        .uniqBy(getComparisonValue)
                        .value();
                    const streamPackIdsFromConcentrationPlaylists = _.map(
                        streamsFromConcentrationPlaylists,
                        'localePackId',
                    );

                    const requiredStreamsFromSchedule = _.chain(this.getRequiredStreamPackIdsFromPeriods())
                        .difference(streamPackIdsFromConcentrationPlaylists)
                        .map(localePackId => Stream.getCachedForLocalePackId(localePackId))
                        .value();

                    const streamsForCohort = streamsFromConcentrationPlaylists.concat(requiredStreamsFromSchedule);
                    const streamsCompletedForCohort = _.chain(streamsForCohort).filter('complete').value();

                    return Math.round((100 * streamsCompletedForCohort.length) / streamsForCohort.length);
                },

                lastAdmissionRoundDeadlineHasPassed() {
                    if (!this.supportsAdmissionRounds) {
                        return false;
                    }

                    return !this.getApplicableAdmissionRound();
                },

                admissionRoundDeadlineHasPassed(appliedAt) {
                    if (!this.supportsAdmissionRounds) {
                        return false;
                    }

                    const applicableAdmissionRound = this.getApplicableAdmissionRound(appliedAt);
                    return !applicableAdmissionRound || applicableAdmissionRound.applicationDeadline <= Date.now();
                },

                getApplicableAdmissionRound(appliedAt) {
                    if (appliedAt) {
                        // There are known cases of users who apply for a cohort, but ultimately end up being placed in a previous cohort (typically at the request of the user).
                        // In these situations, the appliedAt value on the program application will be a more recent date than all of the application deadlines for that cohort's
                        // admission rounds. This can cause getApplicableAdmissionRound to return null, which can cause various issues for users, such as not being able to edit their
                        // application prior to admission. To prevent this, we should simply return the final admission round of the cohort.
                        return (
                            this.admission_rounds.find(
                                admissionRound => appliedAt < admissionRound.applicationDeadline,
                            ) ?? this.admission_rounds.at(-1)
                        );
                    }

                    const now = new Date();
                    if (this.startDate < now) {
                        return null;
                    }
                    return _.find(this.admission_rounds, admissionRound => admissionRound.applicationDeadline > now);
                },

                applicationDeadline() {
                    const applicableAdmissionRound = this.getApplicableAdmissionRound();
                    return applicableAdmissionRound?.applicationDeadline;
                },

                getApplicationDeadlineMessage(appliedAt) {
                    // Create default message about the deadline
                    const translationHelper = new TranslationHelper('settings.application_status'); // just cause it is already there

                    if (!this.supportsAdmissionRounds) {
                        return translationHelper.get('deadline_message_rolling_basis');
                    }

                    const applicableAdmissionRound = this.getApplicableAdmissionRound(appliedAt);

                    if (!applicableAdmissionRound) {
                        return null;
                    }

                    let localeKey;

                    // In most cases, students will not be viewing this locale string after the decision date. See
                    // exceptions on https://trello.com/c/ngU07aP2. We only show the decision date part of the message
                    // when the user has applied and the decision date is in the future.
                    const showDecisionDate = !!appliedAt && applicableAdmissionRound.decisionDate > Date.now();
                    const withDecisionDate = showDecisionDate ? '_with_decision_date' : '';

                    // Confusingly, this stuff is partially tested in cohort_spec.js and partially tested in application_status_dir_spec.js
                    if (this.admissionRoundDeadlineHasPassed(appliedAt) && this.admission_rounds.length > 1) {
                        // multiple rounds (closed)
                        localeKey = `application_deadline_multiple_rounds_closed${
                            applicableAdmissionRound.index + 1 === this.admission_rounds.length ? '_final' : ''
                        }${withDecisionDate}`;
                    } else if (this.admissionRoundDeadlineHasPassed(appliedAt)) {
                        // single round (closed)
                        localeKey = `application_deadline_single_round_closed${withDecisionDate}`;
                    } else if (this.admission_rounds.length > 1) {
                        // multiple rounds (not closed)
                        localeKey = `application_deadline_multiple_rounds${
                            applicableAdmissionRound.index + 1 === this.admission_rounds.length ? '_final' : ''
                        }${withDecisionDate}`;
                    } else {
                        // single round (not closed)
                        localeKey = `application_deadline_single_round${withDecisionDate}`;
                    }

                    const applicationDeadlineMessage = translationHelper.get(localeKey, {
                        roundIndex: applicableAdmissionRound.index + 1,
                        applicationDeadline: dateHelper.formattedUserFacingMonthDayYearLong(
                            applicableAdmissionRound.applicationDeadline,
                        ),
                        decisionDate: dateHelper.formattedUserFacingMonthDayYearLong(
                            applicableAdmissionRound.decisionDate,
                            false,
                        ),
                    });

                    return applicationDeadlineMessage;
                },

                getReapplyAfterDeadlineMessage() {
                    // Create default message about the deadline
                    const translationHelper = new TranslationHelper('settings.application_status'); // just cause it is already there

                    if (!this.supportsAdmissionRounds) {
                        return null;
                    }

                    const applicableAdmissionRound = _.last(this.admission_rounds);

                    if (!applicableAdmissionRound) {
                        return null;
                    }

                    const localeKey =
                        this.admission_rounds.length > 1
                            ? 're_apply_after_multiple_rounds'
                            : 're_apply_after_single_round';
                    const applicationDeadlineMessage = translationHelper.get(localeKey, {
                        roundIndex: applicableAdmissionRound.index + 1,
                        applicationDeadline: dateHelper.formattedUserFacingMonthDayYearLong(
                            applicableAdmissionRound.applicationDeadline,
                        ),
                    });

                    return applicationDeadlineMessage;
                },

                getRequiredStreamPackIdsFromPeriods(maxPeriod) {
                    if (angular.isUndefined(maxPeriod)) {
                        maxPeriod = this.periods.length - 1;
                    }

                    if (!this.supportsSchedule || maxPeriod < 0) {
                        return [];
                    }

                    return _.chain(this.periods.slice(0, maxPeriod + 1))
                        .map('requiredStreamLocalePackIds')
                        .flattenDeep()
                        .uniq()
                        .value();
                },

                getStreamPackIdsFromSpecializations(playlists) {
                    if (!playlists) {
                        throw new Error(
                            'Cannot determine curriculum order of specialization stream pack ids without playlists',
                        );
                    }

                    return _.chain(this.getSpecializationPlaylists(playlists))
                        .map('streamLocalePackIds')
                        .flatten()
                        .uniq()
                        .value();
                },

                // Returns an array that first has all of the stream pack ids from the schedule in the
                // order they appear in the schedule, and then has all of the stream pack ids from the
                // specializations in the order that the specializations appear in specialization_playlist_pack_ids
                getStreamPackIdsInCurriculumOrder(playlists) {
                    if (!this.supportsSchedule) {
                        return this.getStreamPackIdsFromPlaylistCollections(playlists);
                    }

                    return this.getRequiredStreamPackIdsFromPeriods().concat(
                        this.getStreamPackIdsFromSpecializations(playlists),
                    );
                },

                assignCohortSection(cohortSection) {
                    this.cohort_sections.push(cohortSection);
                },

                addSlackRoom(cohortSection) {
                    // If we have a Room 1 in one cohort section, we don't mind if we also
                    // have a Room 1 in another section, since the section identifier is
                    // appended to the room name, and the sections are visually separated
                    const section_room_count = this.slack_rooms.filter(
                        room => room.cohort_section_id === cohortSection.id,
                    ).length;

                    let title = `Room ${section_room_count + 1} (${cohortSection.identifier})`;

                    // Make sure we don't select a title that already exists (which could
                    // happen if rooms were added and then removed)
                    if (_.chain(this.slack_rooms).map('title').includes(title).value()) {
                        title = '';
                    }

                    const room = {
                        id: guid.generate(),
                        cohort_id: this.id,
                        title,
                        cohort_section_id: cohortSection.id,
                    };

                    this.slack_rooms.push(room);

                    _.chain(this.periods)
                        .map('exercises')
                        .flattenDeep()
                        .forEach(exercise => {
                            // If the exercise is set up to send distinct messages or documents
                            // to each slack room, then create an entry for this slack room.
                            if (exercise.slack_room_overrides) {
                                exercise.slack_room_overrides[room.id] = {};
                            }
                        })
                        .value();

                    return room;
                },

                removeSlackRoom(room) {
                    const self = this;
                    self.slack_rooms = _.without(self.slack_rooms, room);

                    _.chain(self.periods)
                        .map('exercises')
                        .flattenDeep()
                        .forEach(exercise => {
                            // If the exercise is set up to send distinct messages or documents
                            // to each slack room, then remove this slack room from the list
                            // of overrides.  If, after removing it, there is only one slack room
                            // left, then revert back to the option of sending one message to
                            // all slack rooms.
                            if (exercise.slack_room_overrides) {
                                delete exercise.slack_room_overrides[room.id];

                                if (exercise.slack_room_overrides && self.slack_rooms.length < 2) {
                                    const entry = _.values(exercise.slack_room_overrides)[0];
                                    exercise.message = entry.message;
                                    exercise.document = entry.document;
                                    delete exercise.slack_room_overrides;
                                }
                            }
                        })
                        .value();
                },
            };
        });
    },
]);
