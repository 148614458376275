import { type CurrentUserIguanaObject } from 'Users';
import { type TranslationHelperClass } from 'Translation';
import { targetBrandConfig } from 'AppBranding';
import { Brand, type BrandConfig, fetchBrandConfig } from 'AppBranding';
import { type CohortClass } from 'Cohorts';
import { type ProgramType } from 'Program';
import {
    ProgramPreferencesStateKey,
    type ProgramPreferencesBlurbLogoConfig,
    type ProgramPreferencesConfig,
} from './Careers.types';

export default function programPreferencesConfigs(
    $injector: ng.auto.IInjectorService,
    user: CurrentUserIguanaObject,
    programType: ProgramType,
): Record<ProgramPreferencesStateKey, ProgramPreferencesConfig> {
    const _Cohort: CohortClass = $injector.get('Cohort');
    const supportsValarOptIn = _Cohort.supportsValarOptIn(programType);
    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const programPreferencesTranslationHelper = new TranslationHelper(
        `careers.edit_career_profile.program_preferences_form`,
    );
    const quanticBrandConfig = fetchBrandConfig(Brand.quantic);
    const valarBrandConfig = fetchBrandConfig(Brand.valar);
    const userBrandConfig = targetBrandConfig(user);

    const getBlurbLogoConfig = (brandConfig: BrandConfig): ProgramPreferencesBlurbLogoConfig => ({
        alt: brandConfig.brandNameShort,
        classes: [brandConfig.brandStyleClass],
        imgSrc: brandConfig.programPreferencesLogo,
        imgStyles: brandConfig.programPreferencesLogoImgStyles,
    });

    const showValarOptIn = () => supportsValarOptIn;

    return {
        [ProgramPreferencesStateKey.generic_basic_info_incomplete]: {
            classes: ['center'],
            saveButtonsDisabled: true,
            showValarOptIn: () => false,
            blurbs: [
                {
                    show: true,
                    classes: ['error'],
                    content: programPreferencesTranslationHelper.get('error_please_complete_earlier_questions'),
                },
            ],
        },
        [ProgramPreferencesStateKey.generic_minimum_education_not_met]: {
            saveButtonsDisabled: true,
            showValarOptIn: () => false,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('minimum_education_requirements'),
                    content: programPreferencesTranslationHelper.get('minimum_education_requirements_desc_generic', {
                        brandName: userBrandConfig.brandNameShort,
                        programTitle: _Cohort.fullTitle(programType),
                    }),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_basic_info_incomplete]: {
            classes: ['center'],
            saveButtonsDisabled: true,
            showValarOptIn: () => false,
            blurbs: [
                {
                    show: !supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('only_valar_generic_offerings_description'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('quantic_generic_offerings_description'),
                    logoConfig: getBlurbLogoConfig(quanticBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_generic_offerings_description'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
                {
                    show: true,
                    classes: ['error'],
                    content: programPreferencesTranslationHelper.get('error_please_complete_earlier_questions'),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_minimum_education_not_met]: {
            saveButtonsDisabled: true,
            showValarOptIn: () => false,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('minimum_education_requirements'),
                    content: programPreferencesTranslationHelper.get('minimum_education_requirements_desc', {
                        brandName: userBrandConfig.brandNameShort,
                    }),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_minimum_experience]: {
            showValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    classes: [userBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('considered_for_mba', {
                        brandName: userBrandConfig.brandNameShort,
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    classes: [valarBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('valar_mba_desc'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_younger_with_low_experience]: {
            showValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    classes: [userBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('considered_for_mba', {
                        brandName: userBrandConfig.brandNameShort,
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    classes: [valarBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('valar_mba_desc'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_older_with_low_experience]: {
            showValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('registered_as_one_but_considered_for_both', {
                        registeredAs: 'MBA',
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_mba_and_emba_desc_mba_default'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_middle_experience]: {
            showValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('registered_as_one_but_considered_for_both', {
                        registeredAs: 'Executive MBA',
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_mba_and_emba_desc_emba_default'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_younger_with_high_experience]: {
            showValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('registered_as_one_but_considered_for_both', {
                        registeredAs: 'Executive MBA',
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_mba_and_emba_desc_emba_default'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_older_with_high_experience]: {
            showValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('considered_for_emba', {
                        brandName: userBrandConfig.brandNameShort,
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_emba_desc'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
    };
}
