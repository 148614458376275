export default angular.module('captchaHelper', []).factory('captchaHelper', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');

        return {
            resetCaptcha() {
                if ($window.grecaptcha && 'reset' in $window.grecaptcha && !$window.CORDOVA) {
                    $window.captchaComplete = false;
                    $window.grecaptcha.reset();
                }
                if ($window.hcaptcha && 'reset' in $window.hcaptcha && !$window.CORDOVA) {
                    $window.captchaComplete = false;
                    $window.hcaptcha.reset();
                }
            },
        };
    },
]);
