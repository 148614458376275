import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/work_experience_detail.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import trashcanBeige from 'vectors/trashcan_beige.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('workExperienceDetail', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const WorkExperience = $injector.get('WorkExperience');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                min: '<?',
                max: '<?',
                requiredResponsibilities: '<?',
                employmentType: '@',
                moveWorkExperience: '&',
            },
            require: '?^form',
            templateUrl,
            link(scope, elem, attrs, formController) {
                scope.trashcanBeige = trashcanBeige;
                scope.isNonEnglish = FormHelper.isNonEnglish();

                // this is named this because we are using this to check for the root user's
                // admin access. Sometimes this directive is used in a case where the root
                // user is an admin, but the "current user" is the user whose career profile
                // is being edited
                Object.defineProperty(scope, 'rootUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watch(
                    'ngModel',
                    () => {
                        scope.workExperiences = _.filter(
                            scope.ngModel,
                            experience => experience.employment_type === scope.employmentType,
                        );

                        getButtonKey();
                    },
                    true,
                );

                getButtonKey();

                function getButtonKey() {
                    scope.addButtonKey =
                        scope.workExperiences && scope.workExperiences.length >= 1
                            ? 'careers.edit_career_profile.work_experience_detail.add_employment'
                            : 'careers.edit_career_profile.work_experience_detail.add_employment_first';
                }

                //---------------------------
                // Initialization
                //---------------------------

                const translationHelper = new TranslationHelper('careers.edit_career_profile.work_experience_detail');

                scope.min = scope.min || 0;
                scope.addPlaceholder = translationHelper.get('responsibilities_placeholder');
                scope.organizationPlaceholderText = translationHelper.get('organization');

                // Add support for custom form validation and dirtying
                FormHelper.supportDirtyState(scope, formController);
                FormHelper.supportCollectionSizeValidation(scope, attrs, formController);

                //---------------------------
                // Collection CRUD
                //---------------------------

                scope.getCollectionLookup = () =>
                    _.fromPairs(_.map(scope.ngModel, item => [`workExperience_${scope.ngModel.indexOf(item)}`, item]));

                scope.removeItem = workExperience => {
                    const index = scope.ngModel.indexOf(workExperience);
                    scope.ngModel.splice(index, 1);
                    scope.updateDirty();
                    scope.updateValidity();
                };

                scope.addItem = () => {
                    const experience = WorkExperience.new({
                        employment_type: scope.employmentType,
                    });
                    scope.ngModel.push(experience);
                    scope.updateDirty();
                    scope.updateValidity();
                };
            },
        };
    },
]);
