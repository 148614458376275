import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/salary_survey_form_section.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('salarySurveyFormSection', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const SALARY_RANGE_OPTION_KEYS = $injector.get('CAREERS_CANDIDATE_SALARY_RANGE_KEYS');
        const SALARY_RANGE_OPTION_KEYS_LEGACY = $injector.get('CAREERS_CANDIDATE_SALARY_RANGE_KEYS_LEGACY');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                careerProfile: '<',
            },
            link(scope) {
                const translationHelper = new TranslationHelper(
                    'careers.edit_career_profile.salary_survey_form_section',
                );

                scope.setSalaryRangeOptions = () => {
                    // For users that have the legacy less_than_40000 value selected,
                    // we need to show them the legacy keys. If they select a new
                    // salary range that isn't the legacy value, we should show them
                    // the new keys and not allow them to change it back.
                    const optionKeys =
                        scope.careerProfile?.salary_range === 'less_than_40000'
                            ? SALARY_RANGE_OPTION_KEYS_LEGACY
                            : SALARY_RANGE_OPTION_KEYS;

                    scope.salaryRangeOptions = optionKeys.map(key => ({
                        value: key,
                        label: translationHelper.get(key),
                    }));
                };

                scope.$watch('careerProfile', () => {
                    if (scope.careerProfile) {
                        // default salary_range to 'less_than_40000' if not already set
                        scope.careerProfile.salary_range = scope.careerProfile.salary_range || 'less_than_20000';
                    }
                });

                scope.$watch('careerProfile.salary_range', () => {
                    scope.setSalaryRangeOptions();
                });
            },
        };
    },
]);
