import { useState, useRef, useLayoutEffect, type HTMLAttributes, type ReactElement } from 'react';

import './CollapsibleContent.styles.scss';

interface Props {
    title: ReactElement | string;
    children: ReactElement | string;
    color?: 'red' | 'green' | 'default';
}

function CollapsibleContent(props: Props): ReactElement {
    const { title, children, color = 'default' } = props;
    const className = 'collapsible-content-container';
    const container = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState(false);

    // Since there's no "previous adjacent sibling" selector in CSS, we've gotta use a bit of javascript
    // to figure out of this CollapsibleContent has no other similar siblings (for border styling purposes).
    const [isLast, setIsLast] = useState(true);
    useLayoutEffect(() => {
        const nextSibling = container?.current?.nextSibling as HTMLAttributes<HTMLElement>;

        if (nextSibling) {
            const collapsibleContentAfter = nextSibling.className?.includes(className);
            setIsLast(!collapsibleContentAfter);
        }
    }, [container]);

    return (
        <div className={`${className} ${expanded ? 'open' : 'closed'} ${isLast ? 'last' : ''}`.trim()} ref={container}>
            <div className={`header ${color}`.trim()} onClick={() => setExpanded(!expanded)} aria-hidden="true">
                {title}
                <i className="fa fa-chevron-down toggle-caret" />
            </div>

            <div className={`body-container ${expanded ? 'expanded' : ''}`.trim()}>
                <div className="body-content">{expanded && children}</div>
            </div>
        </div>
    );
}

export default CollapsibleContent;
