export default angular.module('compileDir', []).directive('compile', [
    '$compile',
    $compile => (scope, element, attrs) => {
        scope.$watch(
            // watch the 'compile' expression for changes
            scope => scope.$eval(attrs.compile),
            value => {
                // use jquery to clean out the existing content in order
                // to make sure everything is cleaned up appropriately
                element.empty();

                const contents = value || '';
                // when the 'compile' expression changes
                // assign it into the current DOM
                element.html(contents);

                // compile the new DOM and link it to the current
                // scope.
                // NOTE: we only compile .childNodes so that
                // we don't get into infinite loop compiling ourselves
                $compile(element.contents())(scope);
            },
        );
    },
]);
