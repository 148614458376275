import angularModule from 'Careers/angularModule/scripts/careers_module';
import { PlaceFormatter } from 'Location';

angularModule.factory('HasLocation', [
    '$injector',

    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const TranslationHelper = $injector.get('TranslationHelper');
        const fieldOptionsTranslationHelper = new TranslationHelper('careers.field_options');

        const HasLocation = new AModuleAbove({
            included(target) {
                Object.defineProperty(target.prototype, 'locationString', {
                    get() {
                        return HasLocation.locationString(this.place_details, this.city, this.state, this.country);
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'formattedAdrAddress', {
                    get() {
                        if (
                            !this.place_details ||
                            !this.place_details.formatted_address ||
                            !this.place_details.adr_address
                        ) {
                            return undefined;
                        }

                        this.$$formattedAdrAddressCache = this.$$formattedAdrAddressCache || {};
                        const formattedAddress = this.place_details.formatted_address;

                        if (!this.$$formattedAdrAddressCache[formattedAddress]) {
                            this.$$formattedAdrAddressCache[formattedAddress] = HasLocation.formattedAdrAddress(
                                this.place_details,
                            );
                        }
                        return this.$$formattedAdrAddressCache[formattedAddress];
                    },
                });

                // See https://developers.google.com/maps/documentation/geocoding/intro#Types
                [
                    'hasPlaceDetails',
                    'coordinates',
                    'placeCity',
                    'placeState',
                    'placeCountry',
                    'placeCountryLong',
                ].forEach(prop => {
                    Object.defineProperty(target.prototype, prop, {
                        get() {
                            return HasLocation[prop](this.place_details);
                        },
                        configurable: true,
                    });
                });
            },

            // Calculates and returns the distance in kilometers "as the crow flies" between this record's
            // place_details lat/lng values and the lat/lng values on the otherPlaceDetails object that's
            // passed in. See https://www.movable-type.co.uk/scripts/latlong.html.
            distanceTo(otherPlaceDetails) {
                if (
                    !this.place_details.lat ||
                    !this.place_details.lng ||
                    !otherPlaceDetails.lat ||
                    !otherPlaceDetails.lng
                ) {
                    return undefined;
                }

                const lat1 = this.place_details.lat;
                const lng1 = this.place_details.lng;
                const lat2 = otherPlaceDetails.lat;
                const lng2 = otherPlaceDetails.lng;
                const R = 6371e3; // radius of the Earth in meters

                const lat1InRadians = this._degreesToRadians(lat1);
                const lat2InRadians = this._degreesToRadians(lat2);
                const deltaLatInRadians = this._degreesToRadians(lat2 - lat1);
                const deltaLngInRadians = this._degreesToRadians(lng2 - lng1);

                const a =
                    Math.sin(deltaLatInRadians / 2) * Math.sin(deltaLatInRadians / 2) +
                    Math.cos(lat1InRadians) *
                        Math.cos(lat2InRadians) *
                        Math.sin(deltaLngInRadians / 2) *
                        Math.sin(deltaLngInRadians / 2);
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                return Math.round((R * c) / 1000); // distance between the two students in kilometers
            },

            _degreesToRadians(degrees) {
                return degrees * (Math.PI / 180);
            },
        });

        // Note: A majority of these functions are reimplemented in place_formatting_mixin.rb, albeit
        // these functions have poorer names (ex. placeCity vs city_name, placeState vs state_abbr). If
        // a change is made to one of these functions or special case lists then be sure to also update
        // the ruby implementations.
        _.extend(HasLocation, PlaceFormatter, {
            // If these placeDetails come from the special list defined in careers_module,
            // return the localized key (for example, San Francisco will show up
            // as 'San Francisco Bay Area' instead of 'San Francisco, CA').  If this is
            // not from the special list, just return locationString.
            localizedLocationOrLocationString(placeDetails) {
                const locationKey = this._locationKeyForPlaceDetails(placeDetails);
                if (locationKey) {
                    return fieldOptionsTranslationHelper.get(locationKey);
                }
                return this.locationString(placeDetails);
            },

            hasPlaceDetails(placeDetails) {
                return !_.isEmpty(placeDetails);
            },

            coordinates(placeDetails) {
                if (placeDetails && placeDetails.lat && placeDetails.lng) {
                    return [placeDetails.lat, placeDetails.lng];
                }
            },

            /*
                This method parses the contents of the `place_details.adr_address` property and sanitizes/reformats it for easier
                display in the UI.

                For reference, here are some actual `adr_address` values that were used for smoke testing this method:

                    1. 'Near Nakheel Metro - <span class="region">Dubai</span> - <span class="country-name">United Arab Emirates</span>'
                    2. '<span class="street-address">327號 Prince Edward Rd W</span>, <span class="locality">Kowloon City</span>, <span class="country-name">Hong Kong</span>'
                    3. '<span class="street-address">St Martin's Ln</span>, <span class="locality">London</span> <span class="postal-code">WC2N 4BG</span>, <span class="country-name">UK</span>'
                    4. '<span class="street-address">532 Upper Serangoon Rd</span>, <span class="country-name">Singapore</span> <span class="postal-code">534547</span>'
                    5. '<span class="street-address">41-A Court Square</span>, <span class="locality">Harrisonburg</span>, <span class="region">VA</span> <span class="postal-code">22801</span>, <span class="country-name">USA</span>'
                    6. '<span class="street-address">1145 17th St NW</span>, <span class="locality">Washington</span>, <span class="region">DC</span> <span class="postal-code">20036</span>, <span class="country-name">USA</span>'
                    7. '3/<span class="street-address">50 Murray St</span>, <span class="locality">Pyrmont</span> <span class="region">NSW</span> <span class="postal-code">2009</span>, <span class="country-name">Australia</span>'
                    8. '<span class="street-address">3000 K St NW Suite 275</span>, <span class="locality">Washington</span>, <span class="region">DC</span> <span class="postal-code">20007</span>, <span class="country-name">USA</span>'
                    9. '<span class="street-address">118 Soy St</span>, <span class="locality">Mong Kok</span>, <span class="country-name">Hong Kong</span>'
            */
            formattedAdrAddress(placeDetails) {
                if (placeDetails && placeDetails.adr_address) {
                    let hasStreetAddress;
                    const addressParts = [];
                    const elemHasCssClass = (elem, cssClass) =>
                        !!(elem && elem.classList && elem.classList.contains(cssClass));

                    // Certain `adr_address` strings have characters that are part of the address as the very beginning. When
                    // jQuery parses the string, it expects the first token to be a valid HTML tag, so it fails to parse the
                    // `adr_address` HTML string. To fix this, we simply wrap the `adr_address` string in `<div></div>` tags,
                    // so that jQuery can parse the string succssfully. See comment below about `street-address-container`.
                    const elems = $(`<div>${placeDetails.adr_address}</div>`)[0].childNodes;

                    elems.forEach((elem, i) => {
                        // The `adr_address` property puts a comma after the `street-address` element (not 100% confirmed, but
                        // this seems to be the trend), but in the UI, we show the street address on its own line, so we don't
                        // want the trailing comma. Even if the `country-name` comes immediately after the `street-address`,
                        // we still want to remove the trailing comma because an address like this is most likely for a city-state,
                        // so the `country-name` is most likely being used to represent the city in this case.
                        const previousElem = elems[i - 1];
                        const currentElemIsStreetAddress = elemHasCssClass(elem, 'street-address');
                        if (
                            hasStreetAddress &&
                            elem.textContent.trim() === ',' &&
                            elemHasCssClass(previousElem, 'street-address')
                        ) {
                            return;
                        }
                        if (currentElemIsStreetAddress) {
                            hasStreetAddress = true;
                        }

                        // Most of the time, we want to make sure that the country name is on its own line in the UI, which means that we
                        // need to remove the `,` or the `-` that comes right before the country name in the `adr_address` HTML string.
                        // There are cases, though, where the country name isn't the last element in the `adr_address` property, which
                        // may be expected for locations in city-states (venues in Singapore tend to follow this pattern). If we see
                        // this is the case, don't bother removing the preceding `,`/`-` as the `country-name` elem is probably replacing
                        // the `locality` element and therefore shouldn't be placed on its own line. See http://microformats.org/wiki/adr
                        // and student_network_event_details.scss for more info.
                        const elemIsLastElem = i === elems.length - 1;
                        const previousAddressPart = _.last(addressParts);
                        if (previousAddressPart && elemIsLastElem && elemHasCssClass(elem, 'country-name')) {
                            if (_.includes([',', '-'], previousAddressPart.trim())) {
                                addressParts.pop();
                            }

                            // We don't bother showing the country name for the location if it's in the US. NOTE: This behavior is
                            // slightly different from the `locationString` method in that we only exclude the US/USA country code.
                            // This is because in the `locationString` method we've written our own logic to make sure that if the
                            // city name and country name match, we only show one. The `adr_address` property on the `place_details`
                            // object has already taken care of this work for us, so we don't need to worry about that here.
                            if (_.includes(['US', 'USA'], elem.textContent)) {
                                return;
                            }
                        }

                        let addressPart;

                        // For some reason, some `adr_address` properties have characters that are part of the street address portion
                        // of the address, but they're not contained in the `street-address` element, so we wrap the preceding characters
                        // and the `street-address` element in `<div></div>` tags, which makes the CSS styling easier.
                        if (hasStreetAddress && currentElemIsStreetAddress) {
                            const precedingStreetAddressParts = addressParts.splice(0).join('');
                            addressPart = `<div class="street-address-container">${precedingStreetAddressParts}${elem.outerHTML}</div>`;
                            addressParts.pop();
                        } else {
                            addressPart = elem.tagName ? elem.outerHTML : elem.textContent;
                        }

                        addressParts.push(addressPart);
                    });

                    return addressParts.join('');
                }
            },

            _locationKeyForPlaceDetails(placeDetails) {
                const self = this;
                if (!self._locationKeysByLatLong) {
                    self._locationKeysByLatLong = {};
                    _.forEach($injector.get('LOCATION_PLACE_DETAILS'), (_placeDetails, key) => {
                        const latLng = `${_placeDetails.lat}-${_placeDetails.lng}`;
                        self._locationKeysByLatLong[latLng] = key;
                    });
                }
                const latLng = `${placeDetails.lat}-${placeDetails.lng}`;
                return self._locationKeysByLatLong[latLng];
            },
        });

        return HasLocation;
    },
]);
