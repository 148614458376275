import angularModule from 'ClientStorage/angularModule/scripts/client_storage_module';
import Cookies from 'js-cookie';

// provide window.sessionStorage if available, otherwise fall back to
// cookies using the sessionStorage interface with js-cookie under the hood.
angularModule.factory('TransientClientStorage', [
    '$injector',
    $injector => {
        const $window = $injector.get('$window');

        //--------------------------
        // Test Mode
        //--------------------------

        if (window.RUNNING_IN_TEST_MODE) {
            const sessionStorage = {};
            sessionStorage.getItem = key => sessionStorage[key];

            sessionStorage.setItem = (key, value) => {
                value = String(value);
                sessionStorage[key] = value;
                return value;
            };
            sessionStorage.removeItem = key => {
                delete sessionStorage[key];
            };
            return sessionStorage;
        }

        //--------------------------
        // ClientStorage Exists
        //--------------------------

        if ($injector.get('Capabilities').sessionStorage) {
            return $window.sessionStorage;
        }

        //--------------------------
        // Cookies Fallback
        //--------------------------

        if (!$window.navigator.cookieEnabled) {
            throw new Error('Cookies are required to use this site');
        }

        return {
            getItem(key) {
                return Cookies.get(key);
            },
            setItem(key, value) {
                // implicitly session-based if no expiration options provided
                Cookies.set(key, String(value), {
                    path: '/',
                });
                return value;
            },
            removeItem(key) {
                Cookies.remove(key, {
                    path: '/',
                });
            },
        };
    },
]);
