
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.answers_too_short_modal = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.answers_too_short_modal || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.answers_too_short_modal = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.answers_too_short_modal, ...{
    "title": "Please note…",
    "description": "Your responses are a little too brief for us to get a good picture of you! Successful candidates typically provide thoughtful, thorough answers in this section. Please add some additional thoughts, insights or reflections to submit your application for the upcoming class.",
    "close": "Close"
}
}
    