import { type EventLogger } from 'EventLogger';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { generateGuid } from 'guid';
import { type InAppBrowser, navigationHelper } from 'navigationHelper';
import { type StatusPoller } from 'Status';
import { type LaunchParams, type VerifyParams } from './BioSig.types';
import { getBioSigUrl } from './BioSigApi';

export async function handleGetBioSigUrlRequest(
    endpoint: string,
    $injector: ng.auto.IInjectorService,
    setLoading: (loading: boolean) => void,
    verifyParams?: VerifyParams,
) {
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    const currentUser = $rootScope.currentUser;
    const { loadUrlInAppBrowserIfAvailable } = navigationHelper($injector);

    setLoading(true);
    let params: LaunchParams = {
        cordova: String(!!window.CORDOVA),
        // we generate a unique ID for every launch so we can tie the result to it
        biosig_attempt_id: generateGuid(),
    };
    // Since currentUser or relevantCohort can be undefined/null, we should
    // only try to set cohort_name if it's defined. We later transform this object
    // into URLSearchParams and don't want `&cohort_name=undefined` or `&cohort_name=null`.
    if (currentUser?.relevantCohort?.name) {
        params.cohort_name = currentUser.relevantCohort.name;
    }
    if (verifyParams) {
        params = { ...params, ...verifyParams };
    }

    logLaunchEvent(endpoint, params, $injector);

    return getBioSigUrl(endpoint, params).then((url: string) => {
        setLoading(false);
        loadUrlInAppBrowserIfAvailable(
            url,
            'beforeload=yes,location=no,footer=yes,footercolor=#ff4d63,closebuttoncolor=#ffffff',
            (inAppBrowserReference: InAppBrowser) =>
                attachInAppBrowserEventListener(inAppBrowserReference, $injector, setLoading),
        );
    });
}

export function logLaunchEvent(endpoint: string, params: LaunchParams, $injector: ng.auto.IInjectorService) {
    const $rootScope = $injector.get<FrontRoyalRootScope>('$rootScope');
    const currentUser = $rootScope.currentUser;
    const eventLogger = $injector.get<EventLogger>('EventLogger');
    eventLogger.log('biosig:launch', {
        has_created_exam_signature: currentUser?.has_created_exam_signature,
        action: endpoint,
        ...params,
    });
}

export function clearBioSigQuery($injector: ng.auto.IInjectorService) {
    const $location = $injector.get('$location');
    // Handle redirects from BioSig back to Quantic on web. We just need to clear the query
    // parameter and do nothing else here. Since we're on web and this is a true redirect
    // back to Quantic, we'll do a full page reload, which will include push messages with
    // any relevant data we wrote as a response to the BioSig webhook.
    if ($location.search()?.biosig_status) {
        $location.search('biosig_status', null);
    }
}

// Handle redirects from BioSig back to Quantic while in the inAppBrowser on Cordova.
export function attachInAppBrowserEventListener(
    inAppBrowserReference: InAppBrowser,
    $injector: ng.auto.IInjectorService,
    setLoading: (loading: boolean) => void,
) {
    const statusPoller = $injector.get<StatusPoller>('FrontRoyal.StatusPoller');
    inAppBrowserReference.addEventListener('beforeload', (event, callback) => {
        const searchParams = new URLSearchParams(event.url?.split('?')[1]);

        // If we got redirected back to a Quantic URL from BioSig while inside the inAppBrowser
        // on Cordova, we will have the biosig_status query param.
        // We want to immediately send a ping to the server. If the verification was a success,
        // we either set user.has_created_exam_signature (in the case of enrollBioSig), or we
        // wrote an ExamVerification record (in the case of verifyBioSIg), both of which are
        // included in push messages, and both of which are needed to determine state in the client.
        if (searchParams.get('biosig_status')) {
            setLoading(true);
            statusPoller.send(() => {
                setLoading(false);
                inAppBrowserReference.close();
            });
        } else {
            callback(event.url);
        }
    });
}
