
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile, ...{
    "lets_go": "Let's Go!",
    "edit_profile": "Edit Profile",
    "view_profile": "View Profile",
    "profile_completion_x": "<strong>Profile Completion:</strong> <em>{{percent}}%</em>",
    "completion_x": "<strong>Completion:</strong> <em>{{percent}}%</em>",
    "program_preferences": "Program Preferences",
    "basic_info": "Basic Info",
    "education": "Education",
    "work": "Work History",
    "job_preferences": "Job Preferences",
    "more_about_you": "More About You",
    "resume_and_links": "Resume and Links (Optional)",
    "preview_profile": "Preview Profile",
    "submit_application": "Review and Submit",
    "submit_application_header": "Review and Submit",
    "save": "Save",
    "save_and_next": "Save and Next",
    "cancel": "Cancel",
    "unsaved_changes_confirm": "You currently have unsaved changes. Are you sure you wish to navigate away?",
    "inbox": "Inbox",
    "complete_all_fields": "* Please complete all required fields.",
    "the_following": "The following questions help us build your student / alumni <br class=\"hidden-xs\"> profile should you be accepted into the MBA program.",
    "the_following_emba": "The following questions help us build your student / alumni <br class=\"hidden-xs\"> profile should you be accepted into the EMBA program.",
    "resume_and_links_desc": "Our admissions team values complete applications that provide a resume and public LinkedIn profile.",
    "resume_and_links_desc_zh": "Our admissions team values complete applications that provide a resume.",
    "program_preferences_desc": "A family of degree programs for high potential learners and high achieving professionals.",
    "program_preferences_desc_valar": "Business degree programs for high potential learners and high achieving professionals.",
    "student_network_settings": "Network",
    "student_network_settings_desc": "Admitted applicants gain access to our global alumni network, where they can connect with other students locally and around the world.",
    "use_edit_buttons": "Use the <i class=\"fa fa-pencil\"></i> edit buttons to navigate to relevant parts of your profile.",
    "application_questions": "Application Questions",
    "application_questions_sub_header_degree_program": "We encourage you to personalize your application with the required and optional questions below. <br class=\"hidden-xs\"> You may be invited to an online interview to elaborate.",
    "demographics_desc": "Questions regarding demographics comply with the <br class=\"hidden-xs\"/>U.S. Department of Education's standards. They are completely optional.",
    "demographics": "Demographics (Optional)",
    "tuition_and_scholarships": "Tuition & Scholarships",
    "tuition_and_scholarships_desc": "We're proud of the innovations that enable such <a ng-click=\"handleAffordableTuitionClick()\">affordable tuition</a>.<br class=\"hidden-xs\"/> Scholarships for our exceptional candidates can further reduce cost."
}
}
    