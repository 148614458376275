import angularModule from 'Careers/angularModule/scripts/careers_module';

angular.module('ListOffsetHelper', []).factory('listOffsetHelper', [
    () => ({
        setOffset(offset, action, listLimit) {
            if (action === 'first') {
                offset = 0;
            } else if (action === 'previous') {
                offset = _.max([offset - listLimit, 0]); // prevent offset from becoming negative
            } else if (action === 'next') {
                offset += listLimit;
            } else {
                throw new Error(`Unsupported pagination control action: ${action}`);
            }

            return offset;
        },
    }),
]);
