
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.preview_profile_status_message = window.Smartly.locales.modules.en.back_royal.careers.preview_profile_status_message || {};
window.Smartly.locales.modules.en.back_royal.careers.preview_profile_status_message = {...window.Smartly.locales.modules.en.back_royal.careers.preview_profile_status_message, ...{
                                        
                                      
                                        
    "active": "Profile Active!",
    "active_desc": "Looking good! Your profile is complete and active in the career network. Employers that are interested in connecting can reach out to you through messages in the Connections section.",

    "inactive": "Profile Inactive",
    "inactive_desc": "Your profile is complete, but currently deactivated. Activate your profile below to allow employers to match with you in the network.",

    "incomplete": "Profile Incomplete",
    "incomplete_desc": "Complete and activate your profile to allow employers to match with you in the network.",

                                        
                                       
                                        

                          
    "applied": "Pending Application",
    "applied_desc": "Your application to {{brandName}} has been submitted! If you applied to the MBA, EMBA, or our certificate programs, your career profile will be reviewed at the same time as your program application.<br><br>If you qualify, this is where you'll connect with hundreds of leading companies and innovative startups looking for candidates just like you!",

                                 
    "reviewing_degree": "Congratulations!",
    "reviewing_degree_desc": "Your profile is now complete. After a brief review, it should be visible in our network within 1-2 weeks of the start of classes.",

                                     
    "reviewing_non_degree": "In Review",
    "reviewing_non_degree_desc": "Congratulations on your acceptance to the certificate program!<br><br>At this time, we don’t have opportunities that match your interests, role or location preferences. We’ll notify you if and when that changes so you can gain access.",

               
    "deferred": "Deferred",
    "deferred_desc": "Congratulations on your acceptance! Your profile will be activated after you join a new class.",

                                                                       
    "no_cn_access": "Career Network Access",
    "no_cn_access_desc": "Thanks for applying to {{brandName}}! Unfortunately, only accepted students can qualify for access to our career network. We appreciate your interest and wish you the best in your learning and career goals!",

                                                  
    "no_cn_access_cno": "Career Network Access",
    "no_cn_access_cno_desc": "Thanks for applying to {{brandName}}! Unfortunately, we don’t have enough opportunities that match your role and location preferences. We’ll notify you if and when that changes so you can gain access.",

                                            
                                          
                                            

                                                                                             
    "accepted_degree_incomplete_profile": "Profile Incomplete",
    "accepted_degree_incomplete_profile_desc": "Congratulations on your acceptance! Please complete your profile as part of enrollment. Incomplete steps are marked with a blue circle below.",

                                                                                                 
    "accepted_degree_incomplete_profile_alt": "Profile Incomplete",
    "accepted_degree_incomplete_profile_alt_desc": "Congratulations on your acceptance! Please complete your profile as part of enrollment.",

                                            
                                                             
                                            

                                                 
    "pending_student_network": "Student Network Access",
    "pending_student_network_desc": "Your profile is currently only visible to our admissions team. In this section you can add and edit details and decide how much to share with your fellow students if you are admitted.",

                                                                       
    "no_student_network_access": "Student Network Access",
    "no_student_network_access_desc": "Thanks for applying to {{brandName}}! Unfortunately, only accepted students can qualify for access to our student network. We appreciate your interest and wish you the best in your learning and career goals!",

               
    "reviewing_student_network": "Congratulations!",
    "reviewing_student_network_desc": "Your profile is now complete. After a brief review, it should be visible in our student network within 1-2 weeks of the start of classes."
}
}
    