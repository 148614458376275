export * from './Cohort.types';
export * from './CohortAttrs.types';
export * from './CohortIndex.types';
export * from './Cohort';
export * from './api';
export * from './Cohort.types';
export * from './downloadSchedule';

export const COHORT_SECTIONS = {
    GLOBAL: Object.freeze({
        id: 'bec89df2-c25d-4484-8684-d7b4d78196c8',
        identifier: 'global',
    }),
    SSEA: Object.freeze({
        id: '689ff015-0566-45bd-b8d5-32cf12db4aa8',
        identifier: 's_se_asia',
    }),
    AFRICA: Object.freeze({
        id: 'aa367c22-3ba0-494b-988b-039b3c9ef127',
        identifier: 'africa',
    }),
};
