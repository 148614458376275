
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.application_questions_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.application_questions_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.application_questions_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.application_questions_form, ...{
    "short_answer_why_pursuing_master_of_business_administration": "Why are you pursuing a Master of Business Administration at this time? And why are you considering {{brandName}} in particular?",
    "short_answer_why_pursuing_master_of_science_in_business_analytics": "Why are you pursuing a Master of Science in Business Analytics at this time? And why are you considering {{brandName}} in particular?",
    "short_answer_why_pursuing_master_of_science_in_software_engineering": "Why are you pursuing a Master of Science in Software Engineering at this time? And why are you considering {{brandName}} in particular?",
    "short_answer_use_skills_to_advance": "How specifically will you use the skills you will gain in the program to advance your career?",
    "short_answer_prior_experience_master_of_business_administrations": "How do you believe your prior academic or life experience positions you for success in {{brandNameShort}}'s degree program?",
    "short_answer_prior_experience_master_of_science_in_business_analytics": "How does your prior education and/or past experiences position you for academic success in the program?",
    "short_answer_prior_experience_master_of_science_in_software_engineering": "Do you have prior experience (paid or in your own time) related to developing software and/or websites?",

                                      
    "funding_and_scholarships": "Funding and Scholarships",

    "employer_funding": "Employer Funding",
    "employer_funding_caption": "{{brandName}} works with many companies that fund employee education through tuition reimbursement benefits.",

    "scholarships": "Scholarships (optional)",
    "how_hear": "How did you hear about {{brandName}}?",
    "how_hear_placeholder": "Describe where you heard about us",
    "anything_else_to_tell_us": "Is there anything else you would like to tell us?"
}
}
    