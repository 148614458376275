import angularModule from 'ClientStorage/angularModule/scripts/client_storage_module';
import ClientStorage from 'ClientStorage';

// Note: We don't need the AngularJS wrapper here anymore now that
// we've created a ClientStorage ES2015 module
angularModule.factory('ClientStorage', [
    '$injector',
    () => {
        // When ClientStorage was defined inside the angular module, instead
        // of as a separate es2015 module, setting up a new angular app would
        // clear out the storage.  We relied on that in specs all over the place,
        // so we need to keep it working.  Don't love it, but we'll deal with it
        // outside of angular as we move forward.
        if (window.RUNNING_IN_TEST_MODE) {
            ClientStorage.clear();
        }

        return ClientStorage;
    },
]);
