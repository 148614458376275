import { type ProgramType, ProgramTypeConfigs } from 'Program';
import { type InstitutionId } from 'Institutions';
import { type ProgramPreferencesStateKey } from './Careers.types';

export function determineTargetProgramType(
    programPreferencesStateKey: ProgramPreferencesStateKey,
    institutionId: InstitutionId,
): ProgramType | null {
    return (
        Object.values(ProgramTypeConfigs).find(
            config =>
                config.institutionID === institutionId &&
                config.programPreferenceStateKeys.includes(programPreferencesStateKey),
        )?.key || null
    );
}

export default determineTargetProgramType;
