
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_experience_detail = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_experience_detail || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_experience_detail = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_experience_detail, ...{
    "add_education": "Add Another Program",
    "add_education_first": "Add a Program",
    "college": "University/College",
    "degree": "Degree",
    "institution_location": "Institution's Location (country)",
    "grad_year": "Grad Year",
    "year_completed": "Year Completed",
    "major": "Major or Specialization",
    "minor": "Minor (optional)",
    "gpa": "GPA (if applicable)",
    "gpa_description": "Describe GPA scale if not out of 4.0",
    "education_starting_with": "Please list the <strong>formal degrees</strong> you have earned or enrolled in, beginning with your undergraduate or equivalent university degree. This is required to be considered for our masters degree programs. You may list certificates, non-degree programs, or other diplomas in the next section.",
    "education_starting_with_slim_application": "Please list the <strong>formal degrees</strong> you have earned or enrolled in, beginning with your undergraduate or equivalent university degree. This is required to be considered for our masters degree programs. You may list certificates, non-degree programs, or other diplomas in the next section.",
    "education_instructions_when_checkbox_visible": "Please list the <strong>formal degrees</strong> you have earned or enrolled in, beginning with your undergraduate or equivalent university degree. This is required to be considered for our masters degree programs. You may list certificates, non-degree programs, or other diplomas in the next section.",
    "if_accepted_provide_copies": "Upon acceptance, we will require copies of official academic transcripts.",
    "non_degree_programs": "You may list any certificates or non-degree programs that have contributed to your professional education.",
    "associate_of_applied_arts": "A.A.A.",
    "associate_of_applied_arts_full": "Associate of Applied Arts",
    "associate_of_applied_science": "A.A.S.",
    "associate_of_applied_science_full": "Associate of Applied Science",
    "associate_of_arts": "A.A.",
    "associate_of_arts_full": "Associate of Arts",
    "associate_of_engineering": "A.E.",
    "associate_of_engineering_full": "Associate of Engineering",
    "associate_of_political_science": "A.P.S.",
    "associate_of_political_science_full": "Associate of Political Science",
    "associate_of_science": "A.S.",
    "associate_of_science_full": "Associate of Science",
    "bachelor_of_accounting": "B.Acc.",
    "bachelor_of_accounting_full": "Bachelor of Accounting",
    "bachelor_of_accountancy": "B.Acy.",
    "bachelor_of_accountancy_full": "Bachelor of Accountancy",
    "bachelor_of_applied_science": "B.App.Sc.",
    "bachelor_of_applied_science_2": "B.A.Sc.",
    "bachelor_of_applied_science_full": "Bachelor of Applied Science",
    "bachelor_of_architecture": "B.Arch.",
    "bachelor_of_architecture_full": "Bachelor of Architecture",
    "bachelor_of_arts": "B.A.",
    "bachelor_of_arts_2": "A.B.",
    "bachelor_of_arts_full": "Bachelor of Arts",
    "bachelor_of_arts_honours": "B.A. (Hons)",
    "bachelor_of_arts_honours_full": "Bachelor of Arts with Honours",
    "bachelor_of_business_administration": "B.B.A.",
    "bachelor_of_business_administration_full": "Bachelor of Business Administration",
    "bachelor_of_business_management": "B.B.M.",
    "bachelor_of_business_management_full": "Bachelor of Business Management",
    "bachelor_of_business_studies": "B.B.S.",
    "bachelor_of_business_studies_full": "Bachelor of Business Studies",
    "bachelor_of_civil_engineering": "B.C.E.",
    "bachelor_of_civil_engineering_full": "Bachelor of Civil Engineering",
    "bachelor_of_commerce": "B.Com.",
    "bachelor_of_commerce_full": "Bachelor of Commerce",
    "bachelor_of_commerce_honours": "B.Com. (Hons)",
    "bachelor_of_commerce_honours_2": "H.B.Com.",
    "bachelor_of_commerce_honours_full": "Honours Bachelor of Commerce",
    "bachelor_of_computer_applications": "B.C.A",
    "bachelor_of_computer_applications_full": "Bachelor of Computer Applications",
    "bachelor_of_computer_science": "B.Comp.Sc.",
    "bachelor_of_computer_science_2": "B.C.S.",
    "bachelor_of_computer_science_3": "B.S.C.S.",
    "bachelor_of_computer_science_full": "Bachelor of Computer Science",
    "bachelor_of_dentistry": "B.D.S.",
    "bachelor_of_dentistry_full": "Bachelor of Dentistry",
    "bachelor_of_education": "B.Ed.",
    "bachelor_of_education_full": "Bachelor of Education",
    "bachelor_of_electrical_engineering": "B.E.E.",
    "bachelor_of_electrical_engineering_full": "Bachelor of Electrical Engineering",
    "bachelor_of_engineering": "B.E.",
    "bachelor_of_engineering_2": "B.Eng.",
    "bachelor_of_engineering_full": "Bachelor of Engineering",
    "bachelor_of_engineering_honours": "B.Eng. (Hons)",
    "bachelor_of_engineering_honours_full": "Bachelor of Engineering with Honours",
    "bachelor_of_engineering_in_electrical_engineering": "B.E.E.E.",
    "bachelor_of_engineering_in_electrical_engineering_full": "Bachelor of Engineering in Electrical Engineering",
    "bachelor_of_fine_arts": "B.F.A.",
    "bachelor_of_fine_arts_full": "Bachelor of Fine Arts",
    "bachelor_of_information_technology": "B.I.T.",
    "bachelor_of_information_technology_full": "Bachelor of Information Technology",
    "bachelor_of_laws": "B.L.",
    "bachelor_of_laws_2": "LL.B.",
    "bachelor_of_laws_full": "Bachelor of Laws",
    "bachelor_of_laws_honours": "LL.B. (Hons)",
    "bachelor_of_laws_honours_full": "Bachelor of Laws with Honours",
    "bachelor_of_library_science": "B.Lib.",
    "bachelor_of_library_science_2": "B.L.S.",
    "bachelor_of_library_science_full": "Bachelor of Library Science",
    "bachelor_of_literature": "B.Lit.",
    "bachelor_of_literature_full": "Bachelor of Literature",
    "bachelor_of_management_studies": "B.M.S.",
    "bachelor_of_management_studies_full": "Bachelor of Management Studies",
    "bachelor_of_mathematics": "B.Math.",
    "bachelor_of_mathematics_full": "Bachelor of Mathematics",
    "bachelor_of_mechanical_engineering": "B.M.E.",
    "bachelor_of_mechanical_engineering_full": "Bachelor of Mechanical Engineering",
    "bachelor_of_medical_laboratory_science": "B.M.L.Sc.",
    "bachelor_of_medical_laboratory_science_full": "Bachelor of Medical Laboratory Science",
    "bachelor_of_medical_science": "B.Med.Sc.",
    "bachelor_of_medical_science_full": "Bachelor of Medical Science",
    "bachelor_of_medicine": "M.B.",
    "bachelor_of_medicine_full": "Bachelor of Medicine",
    "bachelor_of_medicine_and_surgery": "M.B.B.S.",
    "bachelor_of_medicine_and_surgery_2": "B.M.B.S.",
    "bachelor_of_medicine_and_surgery_3": "M.B Ch.B.",
    "bachelor_of_medicine_and_surgery_4": "M.B. B.Ch. (Oxon)",
    "bachelor_of_medicine_and_surgery_5": "M.B. B.Ch.",
    "bachelor_of_medicine_and_surgery_6": "M.B. B.Chir. (Cantab)",
    "bachelor_of_medicine_and_surgery_full": "Bachelor of Medicine, Bachelor of Surgery",
    "bachelor_of_music": "B.M.",
    "bachelor_of_music_2": "B.Mus.",
    "bachelor_of_music_full": "Bachelor of Music",
    "bachelor_of_nursing": "B.N.",
    "bachelor_of_nursing_full": "Bachelor of Nursing",
    "bachelor_of_pharmacy": "B.Pharm",
    "bachelor_of_pharmacy_2": "Pharm.B.",
    "bachelor_of_pharmacy_full": "Bachelor of Pharmacy",
    "bachelor_of_philosophy": "B.Ph.",
    "bachelor_of_philosophy_full": "Bachelor of Philosophy",
    "bachelor_of_physical_therapy_and_physiotherapy": "B.P.T.",
    "bachelor_of_physical_therapy_and_physiotherapy_full": "Bachelor of Physical Therapy, Bachelor of Physiotherapy",
    "bachelor_of_science": "B.S.",
    "bachelor_of_science_2": "B.Sc.",
    "bachelor_of_science_full": "Bachelor of Science",
    "bachelor_of_science_honours": "B.Sc. (Hons)",
    "bachelor_of_science_honours_full": "Bachelor of Science with Honours",
    "bachelor_of_science_in_education": "B.S.Ed.",
    "bachelor_of_science_in_education_full": "Bachelor of Science in Education",
    "bachelor_of_science_in_electrical_engineering": "B.Sc.E.E.",
    "bachelor_of_science_in_electrical_engineering_2": "B.S.E.E.",
    "bachelor_of_science_in_electrical_engineering_full": "Bachelor of Science in Electrical Engineering",
    "bachelor_of_science_in_nursing": "B.S.N.",
    "bachelor_of_science_in_nursing_full": "Bachelor of Science in Nursing",
    "bachelor_of_technology": "B.Tech.",
    "bachelor_of_technology_full": "Bachelor of Technology",
    "bachelor_of_technology_honours": "B.Tech. (Hons)",
    "bachelor_of_technology_honours_full": "Bachelor of Technology with Honours",
    "common_professional_examination": "C.P.E.",
    "common_professional_examination_full": "Common Professional Examination",
    "diploma_of_college_studies": "D.C.S.",
    "diploma_of_college_studies_full": "Diploma of College Studies",
    "doctor_of_dental_surgery": "D.D.S.",
    "doctor_of_dental_surgery_full": "Doctor of Dental Surgery",
    "doctor_of_education": "Ed.D.",
    "doctor_of_education_full": "Doctor of Education",
    "doctor_of_medicine": "M.D.",
    "doctor_of_medicine_full": "Doctor of Medicine",
    "doctor_of_medicine_in_dentistry": "D.M.D.",
    "doctor_of_medicine_in_dentistry_full": "Doctor of Medicine in Dentistry",
    "doctor_of_naturopathic_medicine": "N.D.",
    "doctor_of_naturopathic_medicine_full": "Doctor of Naturopathic Medicine",
    "doctor_of_nursing_practice": "D.N.P.",
    "doctor_of_nursing_practice_full": "Doctor of Nursing Practice",
    "doctor_of_osteopathic_medicine": "D.O.",
    "doctor_of_osteopathic_medicine_full": "Doctor of Osteopathic Medicine",
    "doctor_of_pharmacy": "Pharm.D.",
    "doctor_of_pharmacy_2": "D.Pharm.",
    "doctor_of_pharmacy_full": "Doctor of Pharmacy",
    "doctor_of_philosophy": "Ph.D.",
    "doctor_of_philosophy_2": "D.Phil.",
    "doctor_of_philosophy_full": "Doctor of Philosophy",
    "doctor_of_physical_therapy_and_physiotherapy": "D.P.T.",
    "doctor_of_physical_therapy_and_physiotherapy_full": "Doctor of Physical Therapy, Doctor of Physiotherapy",
    "doctor_of_veterinary_medicine": "D.V.M.",
    "doctor_of_veterinary_medicine_full": "Doctor of Veterinary Medicine",
    "graduate_diploma": "G.Dip.",
    "graduate_diploma_2": "GradDip",
    "graduate_diploma_full": "Graduate Diploma",
    "graduate_diploma_in_law": "G.D.L.",
    "graduate_diploma_in_law_full": "Graduate Diploma in Law",
    "high_school_diploma": "H.S.D.",
    "high_school_diploma_full": "High School Diploma",
    "higher_national_diploma": "HND",
    "higher_national_diploma_full": "Higher National Diploma",
    "juris_doctor": "J.D.",
    "juris_doctor_full": "Doctor of Jurisprudence",
    "master_in_urban_planning": "M.U.P.",
    "master_in_urban_planning_full": "Master in Urban Planning",
    "master_of_advanced_studies": "M.A.S.",
    "master_of_advanced_studies_2": "M.A.St.",
    "master_of_advanced_studies_full": "Master of Advanced Studies",
    "master_of_architecture": "M.Arch.",
    "master_of_architecture_full": "Master of Architecture",
    "master_of_arts": "M.A.",
    "master_of_arts_2": "A.M.",
    "master_of_arts_full": "Master of Arts",
    "master_of_business_administration": "M.B.A.",
    "master_of_business_administration_full": "Master of Business Administration",
    "executive_master_of_business_administration": "E.M.B.A.",
    "executive_master_of_business_administration_full": "Executive Master of Business Administration",
    "master_of_chemistry": "M.Chem.",
    "master_of_chemistry_full": "Master of Chemistry",
    "master_of_commerce": "M.Com.",
    "master_of_commerce_2": "M.Comm.",
    "master_of_commerce_3": "Mag.Comm.",
    "master_of_commerce_full": "Master of Commerce",
    "master_of_computer_applications": "M.C.A.",
    "master_of_computer_applications_full": "Master of Computer Applications",
    "master_of_divinity": "M.Div",
    "master_of_divinity_full": "Master of Divinity",
    "master_of_education": "M.Ed.",
    "master_of_education_2": "Ed.M.",
    "master_of_education_full": "Master of Education",
    "master_of_engineering": "M.Eng.",
    "master_of_engineering_2": "M.E.",
    "master_of_engineering_full": "Master of Engineering",
    "master_of_fine_arts": "M.F.A.",
    "master_of_fine_arts_full": "Master of Fine Arts",
    "master_of_international_affairs": "M.I.A.",
    "master_of_international_affairs_full": "Master of International Affairs",
    "master_of_international_studies": "M.I.S.",
    "master_of_international_studies_full": "Master of International Studies",
    "master_of_laws": "LL.M.",
    "master_of_laws_full": "Master of Laws",
    "master_of_music": "M.M.",
    "master_of_music_2": "M.Mus.",
    "master_of_music_full": "Master of Music",
    "master_of_pharmacy": "M.Pharm.",
    "master_of_pharmacy_2": "Pharm.M.",
    "master_of_pharmacy_full": "Master of Pharmacy",
    "master_of_philosophy": "M.Phil.",
    "master_of_philosophy_full": "Master of Philosophy",
    "master_of_physical_therapy_and_physiotherapy": "M.P.T.",
    "master_of_physical_therapy_and_physiotherapy_full": "Master of Physical Therapy, Master of Physiotherapy",
    "master_of_physics": "M.Phys.",
    "master_of_physics_full": "Master of Physics",
    "master_of_physics_honours": "M.Phys. (Hons)",
    "master_of_physics_honours_full": "Master of Physics with Honours",
    "master_of_public_health": "M.P.H.",
    "master_of_public_health_full": "Master of Public Health",
    "master_of_research": "M.Res.",
    "master_of_research_full": "Master of Research",
    "master_of_science": "M.S.",
    "master_of_science_2": "S.M.",
    "master_of_science_3": "M.Sc.",
    "master_of_science_4": "Sc.M.",
    "master_of_science_full": "Master of Science",
    "master_of_science_in_education": "M.S.Ed.",
    "master_of_science_in_education_full": "Master of Science in Education",
    "master_of_social_work": "M.S.W.",
    "master_of_social_work_full": "Master of Social Work",
    "master_of_studies": "M.St.",
    "master_of_studies_full": "Master of Studies",
    "master_of_technology": "M.Tech.",
    "master_of_technology_full": "Master of Technology",
    "nurse_practitioner": "N.P.",
    "nurse_practitioner_full": "Nurse Practitioner",
    "postgraduate_diploma": "P.G.Dip.",
    "postgraduate_diploma_2": "Pg.D.",
    "postgraduate_diploma_3": "Pg.Dip.",
    "postgraduate_diploma_4": "PostGradDip",
    "postgraduate_diploma_full": "Postgraduate Diploma",
    "postgraduate_diploma_in_law": "P.G.D.L.",
    "postgraduate_diploma_in_law_full": "Postgraduate Diploma in Law",
    "education_blank_required": "Please add at least one education entry.",
    "select": "Select",
    "yes": "Yes",
    "no": "No",
    "last_year_attended": "Last Year Attended",
    "add_degree": "Add Another Degree Program",
    "add_degree_first": "Add a Degree Program",
    "institution_name": "Institution Name",
    "program_name": "Program Name",
    "have_completed": "I have completed or expect to complete this program:",
    "completed_year": "Year Completed",
    "start_year": "Start Year",
    "end_year": "End Year",
    "degree_tip": "Choose from the list or type to add your own.",
    "have_already_graduated": "Have you already graduated with this degree?",
    "expect_to_graduate": "Do you expect to graduate with this degree?",
    "country_not_applicable": "Not applicable / Online",
    "country_not_listed": "My country is not listed"
}
}
    