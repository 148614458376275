import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';
import template from 'Cohorts/angularModule/views/user_id_verification_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import phoneDocuments from 'vectors/phone-documents.svg';
import phoneSelfie from 'vectors/phone-selfie.svg';
import blueCheckMark from 'vectors/blue-check-mark.svg';
import exclamationPointInCircle from 'vectors/exclamation-point-in-circle.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('userIdVerificationForm', [
    '$injector',
    $injector => {
        const $interval = $injector.get('$interval');
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');
        const NavigationHelperMixn = $injector.get('Navigation.NavigationHelperMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('cohorts.user_id_verification_form');

        return {
            scope: {
                userIdVerificationViewModel: '<',
            },
            restrict: 'E',
            templateUrl,

            link(scope) {
                scope.phoneDocuments = phoneDocuments;
                scope.phoneSelfie = phoneSelfie;
                scope.blueCheckMark = blueCheckMark;
                scope.exclamationPointInCircle = exclamationPointInCircle;

                NavigationHelperMixn.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watch('userIdVerificationViewModel.contactMethod', () => {
                    if (scope.userIdVerificationViewModel.contactMethod === 'mobilePhone') {
                        scope.formLabel = translationHelper.get('mobile_phone');
                        scope.toggleContactMethodLabel = translationHelper.get('send_an_email');
                    } else {
                        scope.formLabel = translationHelper.get('email');
                        scope.toggleContactMethodLabel = translationHelper.get('send_a_text');
                    }
                });

                // while verifying, ping more often so we get the id_verification
                // when it comes in
                let pingInterval;
                scope.$watch('userIdVerificationViewModel.step', step => {
                    $interval.cancel(pingInterval);
                    if (step === 'polling') {
                        pingInterval = $interval(
                            () => {
                                scope._sendPing();
                            },
                            5 * 1000,
                            0,
                            false,
                        );
                    }
                });

                // separate method for specs
                scope._sendPing = () => {
                    $http.get(`${window.ENDPOINT_ROOT}/api/idology/ping.json?user_id=${scope.currentUser.id}`);
                };

                scope.$on('$destroy', () => {
                    $interval.cancel(pingInterval);
                });
            },
        };
    },
]);
