import { useAngularContext } from 'AngularContext';
import { type VerifyParams } from 'BioSig/BioSig.types';
import { clearBioSigQuery, handleGetBioSigUrlRequest } from 'BioSig/helpers';
import { useCallback, useState } from 'react';
import { useEffectOnce } from 'react-use';

// This hook is for react what the BioSigMixin is for angular
// The shared logic is in ./helpers.ts
export function useBioSig() {
    const $injector = useAngularContext();
    const [isLoadingBioSig, setIsLoadingBioSig] = useState(false);

    const enrollBioSig = useCallback(async () => {
        handleGetBioSigUrlRequest('enroll.json', $injector, setIsLoadingBioSig);
    }, [$injector, setIsLoadingBioSig]);

    const verifyBioSig = useCallback(
        async (params: VerifyParams) => {
            handleGetBioSigUrlRequest('verify.json', $injector, setIsLoadingBioSig, params);
        },
        [$injector, setIsLoadingBioSig],
    );

    useEffectOnce(() => {
        clearBioSigQuery($injector);
    });

    return {
        isLoadingBioSig,
        enrollBioSig,
        verifyBioSig,
    };
}
