import angularModule from 'Careers/angularModule/scripts/careers_module';

angularModule.factory('answersTooShortHelper', [
    '$injector',
    $injector => {
        const DialogModal = $injector.get('DialogModal');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            maybeShowModal(object, fields, minLength) {
                let requiredAnswersLength = 0;

                fields.forEach(field => {
                    requiredAnswersLength += object[field] ? object[field].length : 0;
                });

                if (requiredAnswersLength <= minLength) {
                    DialogModal.alert({
                        title: new TranslationHelper('careers.edit_career_profile.answers_too_short_modal').get(
                            'title',
                        ),
                        content: '<answers-too-short-modal on-finish="onFinish"></answers-too-short-modal>',
                        scope: {
                            onFinish() {
                                DialogModal.hideAlerts();
                            },
                        },
                        hideCloseButton: true,
                    });

                    return true;
                }

                return false;
            },
        };
    },
]);
