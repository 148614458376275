// This defines the subset of Cohort attributes that are used in CohortForAdminListsAttrs,
// which is used when we need a list of all the cohorts with just the basic information about them.

export const attrs = {
    id: 'id',
    name: 'name',
    programType: 'programType',
    startDate: 'startDate',
    endDate: 'endDate',
    startOfAdmissionCycle: 'startOfAdmissionCycle',
    studentNetworkActivationDate: 'studentNetworkActivationDate',
    graduationDate: 'graduationDate',
    graduationDaysOffsetFromEnd: 'graduationDaysOffsetFromEnd',
    cohortSections: 'cohortSections',
    slackRooms: 'slackRooms',
    availableScholarshipIds: 'availableScholarshipIds',
    availableTuitionPlanIds: 'availableTuitionPlanIds',
    isolatedNetwork: 'isolatedNetwork',
} as const;

// This should include all of the fields defined in CohortForAdminListsAttrs. We use this
// list for constructing api requests, which need to specify the fields they need.
export const COHORT_FOR_ADMIN_LISTS_ATTRS = Object.keys(attrs) as (keyof typeof attrs)[];
