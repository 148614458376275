
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.thanks_for_applying_modal = window.Smartly.locales.modules.en.back_royal.careers.thanks_for_applying_modal || {};
window.Smartly.locales.modules.en.back_royal.careers.thanks_for_applying_modal = {...window.Smartly.locales.modules.en.back_royal.careers.thanks_for_applying_modal, ...{
    "message_network_only": "You’ll hear back from us soon. Earn a reward by sharing your referral link, then enjoy our free open courses on the Home tab!",
    "message_other": "<p>Watch for updates by email or check your {{brandName}} dashboard.</p><p>In the coming weeks, you may receive an invitation for a brief Admissions interview. Good luck!</p>",
    "action": "Close",
    "title": "Thank you for applying."
}
}
    