
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.schedule_interview_modal = window.Smartly.locales.modules.en.back_royal.careers.schedule_interview_modal || {};
window.Smartly.locales.modules.en.back_royal.careers.schedule_interview_modal = {...window.Smartly.locales.modules.en.back_royal.careers.schedule_interview_modal, ...{
    "title": "Thank You!",
    "body": "We have received your application. The next step is to schedule your interview with an Admissions Counselor. The interview is your opportunity to expand on your written application, while also learning more about the program. Please schedule your interview using the below application."
}
}
    