import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';
import { COHORT_SECTIONS } from 'Cohorts';

angularModule.factory('CohortSectionOffer', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('cohort_section_offers');
            this.alias('CohortSectionOffer');
            this.setIdProperty('id');

            Object.defineProperty(this.prototype, 'createdAt', {
                get() {
                    return new Date(this.created_at);
                },
            });

            Object.defineProperty(this.prototype, 'updatedAt', {
                get() {
                    return new Date(this.updated_at);
                },
            });

            Object.defineProperty(this.prototype, 'isGlobalOffer', {
                get() {
                    return this.cohort_section_id === COHORT_SECTIONS.GLOBAL.id;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'isRegionalOffer', {
                get() {
                    return this.cohort_section_id !== COHORT_SECTIONS.GLOBAL.id;
                },
                configurable: true,
            });
        });
    },
]);
