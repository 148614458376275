
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.field_options = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.field_options || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.field_options = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.field_options, ...{
                       
    "eligible": "I am eligible for tuition assistance through my company",
    "not_eligible": "I am not eligible for tuition assistance",
    "not_sure": "I am not sure if I am eligible",
    "not_applicable": "Not applicable"
}
}
    