import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/short_answer_scholarship_field.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('shortAnswerScholarshipField', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                programFamilyFormDatum: '<',
                isRequired: '<',
                maxChars: '<?',
                minChars: '<?',
                textareaClass: '@?',
            },
            link(scope) {
                scope.isRequired = scope.isRequired || false;
                scope.minChars = scope.minChars ?? 0;
                scope.maxChars = scope.maxChars ?? 350;
                scope.textareaClass = scope.textareaClass || 'sm-tall-min-height md-tall-min-height';
            },
        };
    },
]);
