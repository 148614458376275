
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.cohorts = window.Smartly.locales.modules.en.back_royal.cohorts || {};
window.Smartly.locales.modules.en.back_royal.cohorts.user_id_verification_form = window.Smartly.locales.modules.en.back_royal.cohorts.user_id_verification_form || {};
window.Smartly.locales.modules.en.back_royal.cohorts.user_id_verification_form = {...window.Smartly.locales.modules.en.back_royal.cohorts.user_id_verification_form, ...{
    "verify_your_identity": "Please Verify Your Identity",
    "send_link": "Send Link",
    "we_will_send_a_link": "We'll send you a link to complete verification. You must follow this link on a smartphone with a working camera.",
    "if_you_are_unable_to_complete": "If you are unable to complete the automated identity verification process, please contact us at <a href=\"mailto:{{brandEmail}}\" target=\"_blank\" >{{brandEmail}}</a>",
    "why_we_do_this": "Why we do this",
    "send_an_email": "Send an email instead",
    "send_a_text": "Send a text instead",
    "mobile_phone": "Mobile Phone Number",
    "email": "Email Address",
    "link_has_been_sent": "Your time-sensitive link has been sent to {{formattedEmailOrPhone}}. Open the link on your smartphone to complete the verification process. <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">Send me another link</a>.",
    "success": "Success!",
    "verification_failed": "Identity Verification Failed",
    "verification_complete": "Success! Your identity verification is complete.",
    "something_went_wrong": "We were unable to verify your ID and photo. Please <a ng-click = \"userIdVerificationViewModel.goBackToForm()\">try again</a>.",
    "important_tips": "Important Tips",
    "place_document": "Place document on dark, flat surface",
    "ensure_corners": "Ensure all 4 corners are visible in frame",
    "avoid_glare": "Avoid glare, blur, and shadows",
    "take_clear_photo": "Take a clear, well-lit photo",
    "ensure_head_visible": "Ensure entire head is visible and centered",
    "avoid_blur": "Avoid blur and shadows"
}
}
    