import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/candidate_card_preview.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import emptyLearnerProfile from 'vectors/empty_learner_profile.svg';
import emptyLearnerProfileDesktop from 'vectors/empty_learner_profile_desktop.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('candidateCardPreview', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            scope: {
                showProfile: '<',
                fullProfileDisplayMode: '<',
                allowEdit: '<?',
            },
            templateUrl,

            link(scope) {
                scope.emptyLearnerProfile = emptyLearnerProfile;
                scope.emptyLearnerProfileDesktop = emptyLearnerProfileDesktop;

                scope.allowEdit = scope.allowEdit || false;

                NavigationHelperMixin.onLink(scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });
            },
        };
    },
]);
