
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.submit_application_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.submit_application_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.submit_application_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.submit_application_form, ...{
    "primary_message": "That's it! Just use the button below to submit your application for review.",
    "primary_message_network_only": "Thanks for completing your application. Just use the button below to submit your application for review.",
    "deadline_message_network_only": "We review on a rolling basis, and usually get back to applicants within a few days.",
    "chearful_message_mba": "We hope to see you as part of the next MBA class!",
    "chearful_message_emba": "We hope to see you as part of the next Executive MBA class!",
    "not_complete_message": "Please complete all required steps to unlock submission. Required fields are marked with: <span>*</span>",
    "submit_application": "Submit Application",
    "application_updated": "Application Updated!",
    "profile_card_may_be_viewed": "Your profile card is only visible to our Admissions team. If you're accepted, it may be seen by classmates, alumni and potential employers if you opt in to our career network.",
    "profile_card_may_be_viewed_student_network": "Your profile card is only visible to our Admissions team. If you're accepted, it may be seen by classmates and alumni if you opt in to our student network.",
    "review_and_edit": "Review your profile and go back to edit if necessary.",
    "mobile_phone_number": "Mobile Phone Number",
    "we_will_message_you": "We will message you on the day of admission decisions. Message and data rates may apply.",
    "toggle_full_profile": "Toggle Full Profile",
    "certify_all_info_and_documents_are_correct": "By submitting this application, I certify that I am the applicant and that all information and supporting documents provided are true and correct. I understand that if I am accepted, I will be required to provide official transcripts that verify my academic eligibility.",
    "choose_admission_preference": "Choose Your Admission Preference",
    "early_admission": "Early Admission",
    "regular_admission": "Regular Admission",
    "early_admission_desc": "Receive priority review with accelerated admission decisions, higher acceptance rates, and often greater scholarship awards. After submitting this application, you will need to complete just one self-paced course available in your {{brandNameShort}} dashboard.",
    "early_admission_desc_no_scholarship": "Receive priority review with accelerated admission decisions and higher acceptance rates. After submitting this application, you will need to complete just one self-paced course available in your {{brandNameShort}} dashboard.",
    "regular_admission_desc": "Receive your admission decision in the regular pool of applicants on {{decisionDate}}. Acceptance rates are slightly lower for this group and scholarship funding is more limited.",
    "regular_admission_desc_no_scholarship": "Receive your admission decision in the regular pool of applicants on {{decisionDate}}. Acceptance rates are slightly lower for this group.",

    "valar_opt_in_desc": "Valar Institute, a division of Quantic, offers an {{programType}} on the same technology platform, but with a greater emphasis on management and leadership. We strongly encourage applicants to remain in consideration for both programs to <strong>improve their overall chance of admission.</strong>",
    "valar_opt_in_agreement": "I would like to be considered for both the Valar and Quantic {{programType}} degree (recommended)",

                                              
    "you_are_about_to_apply": "You are about to apply to our MBA…",
    "we_also_offer": "We also offer an affordable Executive MBA for more experienced professionals that adds management and entrepreneurship courses with greater opportunities for live and online collaboration. Merit and need-based scholarships are available.",
    "switch_to_emba": "Switch to Executive MBA",
    "or": "or",
    "submit_mba_application": "Submit MBA Application"
}
}
    