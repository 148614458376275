import angularModule from 'Careers/angularModule/scripts/careers_module';

angularModule.factory('CareerProfileSearch', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.alias('CareerProfileSearch');

            _.extend(this, {
                cloneFilters(filters) {
                    return {
                        places: _.clone(filters.places),
                        industries: _.clone(filters.industries),
                        preferred_primary_areas_of_interest: _.clone(filters.preferred_primary_areas_of_interest),
                        // NOTE: see comment in server code about the other role filters
                        // related to preferred_primary_areas_of_interest
                        years_experience: _.clone(filters.years_experience),
                        only_local: filters.only_local,
                        keyword_search: filters.keyword_search,
                        skills: filters.skills,
                        employment_types_of_interest: _.clone(filters.employment_types_of_interest),
                        levels_of_interest: _.clone(filters.levels_of_interest),
                        company_name: filters.company_name,
                        school_name: filters.school_name,
                        in_school: filters.in_school,
                    };
                },
            });

            return {
                cloneFilters() {
                    return this.constructor.cloneFilters(this);
                },
            };
        });
    },
]);
