import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/deferral_link.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import moment from 'moment-timezone';
import { COHORT_SECTIONS } from 'Cohorts';
import { getTuitionContract } from 'Users';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('deferralLink', [
    '$injector',

    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const dateHelper = $injector.get('dateHelper');
        const Cohort = $injector.get('Cohort');
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');

        return {
            scope: {
                id: '@',
            },
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                NavigationHelperMixin.onLink(scope);
                const translationHelper = new TranslationHelper('careers.deferral_link');
                const cohortSectionsTranslationHelper = new TranslationHelper('cohort_sections');

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.proxyModel = {
                    toCohortId: undefined,
                    billingConfirmation: undefined,
                };
                scope.showForm = false;

                scope.$watch('proxyModel.toCohortId', id => {
                    if (id) {
                        const upcomingCohort = scope.upcomingCohorts.find(cohort => cohort.id === id);
                        scope.billingConfirmationText = translationHelper.get('billing_confirmation', {
                            trialEndDate: dateHelper.formattedUserFacingMonthDayYearLong(upcomingCohort.trialEndDate),
                        });
                    }
                });

                loadUseDeferralLinkAction();

                scope.useDeferralLink = toCohortId => executeUseDeferralLinkAction(toCohortId);

                function loadUseDeferralLinkAction() {
                    scope.loadingDeferralLink = true;
                    return $http
                        .get(`${window.ENDPOINT_ROOT}/api/user_actions/program_inclusion/use_deferral_link.json`, {
                            params: { record_id: scope.id },
                        })
                        .then(response => {
                            const action = response.data.contents.action;
                            scope.loadingDeferralLink = false;

                            if (action.validation_errors.length > 0) {
                                scope.gotoHome();
                                return;
                            }

                            scope.showForm = action.validation_errors.length === 0;
                            scope.fromCohortTitle = action.current_cohort_title;
                            scope.included_cohort_section_id = action.included_cohort_section_id;
                            onDeferralLinkLoaded(
                                action.available_cohorts,
                                action.program_type,
                                action.can_switch_to_global_section,
                            );
                        });
                }

                function getCohortOptionLabel(cohort, canSwitchToGlobalSection) {
                    const labelArr = [cohort.name];
                    if (canSwitchToGlobalSection) {
                        const currentSection = cohort.cohort_sections.find(
                            cs => cs.id === scope.included_cohort_section_id,
                        );
                        labelArr.push(
                            `(${cohortSectionsTranslationHelper.get(
                                `${currentSection?.identifier || COHORT_SECTIONS.GLOBAL.identifier}_short`,
                            )})`,
                        );
                    }
                    labelArr.push(
                        `starting ${dateHelper.formattedUserFacingMonthDayYearLong(cohort.start_date * 1000)}`,
                    );
                    return labelArr.join(' ');
                }

                function onDeferralLinkLoaded(cohortResponse, programType, canSwitchToGlobalSection = false) {
                    scope.upcomingCohorts = cohortResponse.map(cohortJson => Cohort.new(cohortJson));
                    scope.cohortOptions = scope.upcomingCohorts.map(cohort => ({
                        id: cohort.id,
                        label: getCohortOptionLabel(cohort, canSwitchToGlobalSection),
                    }));

                    // We only show the billing confirmation checkbox if the user has a subscription established.
                    // Right now we don't support creating a deferral link for a user who will need a new subscription,
                    // so if they don't have a subscription already, we assume here that they don't need one.
                    // See GenerateDeferralLink
                    scope.shouldShowBillingConfirmation = !!getTuitionContract(scope.currentUser)?.subscription;

                    scope.supportEmail = Cohort.supportEmailAddress(programType);

                    scope.loadingDeferralLink = false;
                }

                function executeUseDeferralLinkAction(toCohortId) {
                    scope.usingDeferralLink = true;
                    return $http
                        .post(`${window.ENDPOINT_ROOT}/api/user_actions/program_inclusion/use_deferral_link.json`, {
                            cohort_id: toCohortId,
                            record_id: scope.id,
                        })
                        .then(afterExecute);
                }

                function afterExecute() {
                    const programInclusionId = scope.id;

                    scope.programInclusion = scope.currentUser.programInclusions.find(
                        pi => pi.id === programInclusionId,
                    );

                    scope.calendarEventStartDate = moment(1000 * scope.programInclusion.cohortStartDate)
                        .startOf('day')
                        .toDate();
                    scope.calendarEventEndDate = moment(1000 * scope.programInclusion.cohortStartDate)
                        .add(1, 'days')
                        .startOf('day')
                        .toDate();
                    scope.calendarEventDescription = translationHelper.get('calendar_event_description', {
                        cohortTitle: scope.programInclusion.cohort_title,
                    });
                    scope.toCohortStartDate = dateHelper.formattedUserFacingMonthDayYearLong(
                        scope.programInclusion.cohortStartDate * 1000,
                    );
                    scope.usingDeferralLink = false;
                    scope.showForm = false;
                    scope.deferralLinkUsed = true;
                }
            },
        };
    },
]);
