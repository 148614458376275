import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('CohortSection', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const TranslationHelper = $injector.get('TranslationHelper');
        const cohortSectionsTranslationHelper = new TranslationHelper('cohort_sections');

        return Iguana.subclass(function () {
            this.setCollection('cohort_sections');
            this.alias('CohortSection');
            this.setIdProperty('id');

            Object.defineProperty(this.prototype, 'translatedIdentifier', {
                get() {
                    return cohortSectionsTranslationHelper.get(`${this.identifier}_short`);
                },
            });

            return {};
        });
    },
]);
