import angularModule from 'Careers/angularModule/scripts/careers_module';

angularModule.factory('WorkExperience', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.alias('WorkExperience');
            this.setIdProperty('id');

            this.extend({});

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.responsibilities = this.responsibilities || [];
                this.employment_type = this.employment_type || 'full_time';
            });

            Object.defineProperty(this.prototype, 'imageUrl', {
                get() {
                    return this.professional_organization && this.professional_organization.image_url
                        ? this.professional_organization.image_url
                        : $injector.get('DEFAULT_PROFESSIONAL_ORGANIZATION_LOGO_URL');
                },
            });

            Object.defineProperty(this.prototype, 'orgName', {
                get() {
                    return this.professional_organization && this.professional_organization.text;
                },
            });

            Object.defineProperty(this.prototype, 'duration', {
                get() {
                    return this.end_date ? this.end_date - this.start_date : new Date() - this.start_date;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'longLabel', {
                get() {
                    return this.orgName && this.job_title ? [this.orgName, this.job_title].join(' -- ') : '';
                },
            });

            return {};
        });
    },
]);
