
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.profile_photo_form_section = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.profile_photo_form_section || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.profile_photo_form_section = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.profile_photo_form_section, ...{
    "profile_photo": "Profile Photo",
    "upload_avatar_desc": "Smile! Your photo adds personality to your student and career network profile.",
    "upload_avatar_desc_alt": "Smile! Your photo adds personality to your career network profile.",
    "upload_avatar_note": "Your profile picture will be visible to classmates and employers, should you join the career network.",
    "upload_avatar_note_alt": "Your profile picture will be publicly visible to employers in our career network.",
    "upload_avatar_desc_classmates_only": "Smile! Your photo adds personality to your student profile.",
    "upload_avatar_note_classmates_only": "Your profile picture will be visible to classmates and alumni."
}
}
    