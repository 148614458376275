import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import {
    type CareerProfile,
    type RecommendedClassmatesData,
    type RecommendedClassmatesResponse,
} from './CareerProfiles.types';

export const careerProfilesApi = createApi('careerProfilesApi', {
    ...getBackRoyalApiBaseConfig('careerProfilesApi'),
    endpoints: builder => ({
        getRecommendedClassmates: builder.query<RecommendedClassmatesData[], void>({
            query: () => 'career_profiles/recommended_classmates.json',
            transformResponse: (response: RecommendedClassmatesResponse) =>
                response.contents.careerProfiles?.map(({ name, avatarUrl, workExperiences, userId, ...rest }) => ({
                    name,
                    avatarUrl,
                    userId,
                    jobTitle: workExperiences?.[0]?.jobTitle,
                    company: workExperiences?.[0]?.professionalOrganization?.text,
                    workExperiences,
                    ...rest,
                })) || [],
            keepUnusedDataFor: 30 * 60,
        }),
        getRelevantSendbirdUserCareerProfiles: builder.query<CareerProfile[], string[] | undefined>({
            query: (userIds = []) => ({
                url: 'career_profiles.json',
                params: {
                    filters: {
                        sendbird_user_ids: true,
                        user_ids: userIds,
                    },
                    view: 'student_network_profiles',
                },
            }),
            transformResponse: (response: { contents: { careerProfiles: CareerProfile[] } }) =>
                response.contents.careerProfiles,
            keepUnusedDataFor: 30 * 60,
        }),
    }),
});
