
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.resume_and_links_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.resume_and_links_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.resume_and_links_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.resume_and_links_form, ...{
    "li_profile_url": "Public LinkedIn URL",
    "resume_cv": "Resume / CV (optional)",
    "website_url": "Personal Website",
    "blog_url": "Blog URL",
    "tw_profile_url": "Twitter Profile URL",
    "fb_profile_url": "Facebook Profile URL",
    "fb_connect": "Facebook",
    "upload": "Upload",
    "uploaded_date": "Uploaded {{uploadDate}}",
    "connect": "Connect",
    "github_profile_url": "GitHub Profile URL",
    "resume_caption": "If you have a resume, please upload it as a PDF.",
    "add_more_links": "Add More Links",
    "show_less": "Show Less",
    "add_relevant_links": "Please add any relevant professional links below."
}
}
    