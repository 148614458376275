
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.student_network_settings_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.student_network_settings_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.student_network_settings_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.student_network_settings_form, ...{
    "fun_fact": "Fun Fact",
    "fun_fact_tell_us": "Write something personal that could prompt conversation with a potential employer or classmate.",
    "fun_fact_tell_us_non_english": "Write something personal that could prompt conversation with a potential employer or classmate. Please answer in English.",
    "fun_fact_desc": "Write something personal that could prompt conversation with a potential employer.",
    "fun_fact_desc_non_english": "Write something personal that could prompt conversation with a potential employer. Please answer in English.",
    "fun_fact_placeholder": "I can make 3D printed clothing",
    "fun_fact_tell_us_classmates_only": "Write something personal that could prompt a conversation with a classmate.",
    "fun_fact_tell_us_classmates_only_non_english": "Write something personal that could prompt a conversation with a classmate. Please answer in English.",
    "show_my_profile_to_non_degree_students": "Show my profile to non degree students"
}
}
    