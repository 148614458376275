import angularModule from 'Careers/angularModule/scripts/careers_module';

angularModule.factory('CareerProfileList', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const TranslationHelper = $injector.get('TranslationHelper');

        const roleTranslationHelper = new TranslationHelper('careers.field_options');

        return Iguana.subclass(function () {
            this.setCollection('career_profile_lists');
            this.alias('CareerProfileList');
            this.setIdProperty('id');
            this.embedsMany('career_profiles', 'CareerProfile');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.career_profile_ids = this.career_profile_ids || [];
                this.name = this.name || '';
            });

            Object.defineProperty(this.prototype, 'length', {
                get() {
                    return this.career_profile_ids ? this.career_profile_ids.length : 0;
                },
            });

            Object.defineProperty(this.prototype, 'roleNames', {
                get() {
                    if (!this.role_descriptors) {
                        return '';
                    }

                    if (!this.$$roleNames || this.$$roleNamesSource !== this.role_descriptors.join()) {
                        this.$$roleNames = _.map(this.role_descriptors, roleDescriptor =>
                            roleTranslationHelper.get(roleDescriptor),
                        ).sort();
                        this.$$roleNamesSource = this.role_descriptors.join();
                    }
                    return this.$$roleNames;
                },
            });
        });
    },
]);
