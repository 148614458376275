import angularModule from '../careers_module';

angularModule.factory('EducationExperienceDetailMixin', [
    '$injector',
    $injector => {
        const TranslationHelper = $injector.get('TranslationHelper');
        const EducationExperience = $injector.get('EducationExperience');
        const FormHelper = $injector.get('FormHelper');
        const Locale = $injector.get('Locale');
        const LocalizedCountries = $injector.get('LocalizedCountries');

        const translationHelper = new TranslationHelper('careers.edit_career_profile.education_experience_detail');

        return {
            onLink: (scope, elem, attrs, formController) => {
                // Both the adminEducationExperienceDetail and educationExperienceDetail
                // rely on the FormHelper mixin and the FormController, which is why we decided to
                // create this mixin instead of duplicating the functionality between the two directives.

                // Add support for custom form validation and dirtying
                FormHelper.supportDirtyState(scope, formController);
                FormHelper.supportCollectionSizeValidation(scope, attrs, formController);

                if (scope.degreeProgram) {
                    scope.suggestFilters = { suggest_formal: true };
                } else {
                    scope.suggestFilters = { suggest: true };
                }

                scope.countries = LocalizedCountries.getForCurrentLocale()
                    .map(({ value, text }) => ({ value, label: text }))
                    .concat([
                        { value: 'country_not_applicable', label: translationHelper.get('country_not_applicable') },
                        { value: 'country_not_listed', label: translationHelper.get('country_not_listed') },
                    ]);

                scope.countryConfig = {
                    ...FormHelper.getSelectizeConfigDefaults(),
                    placeholder: translationHelper.get('institution_location'),
                    searchField: ['value', 'label'],
                };

                // We use the `$$hashKey` on the education experience as a CSS class identifier so
                // that we show the tooltip for the degree field for the relevant education experience.
                // See the `onFocus` and `onBlur` handlers in `scope.getDegreeConfig`.
                scope.getSanitizedHashKeyId = educationExperience => educationExperience.$$hashKey.replace(':', '_');

                const degreeConfigsCache = {};

                scope.getDegreeConfig = educationExperience => {
                    const key = scope.getSanitizedHashKeyId(educationExperience);
                    degreeConfigsCache[key] = degreeConfigsCache[key] || {
                        ...FormHelper.getSelectizeConfigDefaults(),
                        create: true,
                        placeholder: translationHelper.get('degree'),
                        plugins: ['inputMaxlength'],
                        inputMaxlength: 75,
                        onFocus() {
                            elem[0].querySelector(`.${key} .degree-tooltip`).classList.add('show');
                        },
                        onBlur() {
                            elem[0].querySelector(`.${key} .degree-tooltip`).classList.remove('show');
                        },
                        onOptionAdd(val, data) {
                            // adds 'search' field for easy searching without periods
                            // used via searchField config below
                            data.search = val.split('.').join('');
                        },
                        searchField: ['value', 'label', 'search'],
                        // We have to turn off 'sortField' here because selectize does not support
                        // Chinese. See comment near `Selectize.defaults.sortField in
                        // FrontRoyal/angularModuleAngular/index.js
                        //
                        // The options are sorted above using localeCompare
                        sortField: '$order',
                    };
                    return degreeConfigsCache[key];
                };

                scope.removeItem = educationExperience => {
                    const index = scope.ngModel.indexOf(educationExperience);
                    scope.ngModel.splice(index, 1);
                    scope.updateDirty();
                    scope.updateValidity();
                };

                scope.addItem = () => {
                    const experience = EducationExperience.new();
                    experience.educational_organization = {
                        text: '',
                        locale: Locale.activeCode,
                    };
                    experience.degreeProgram = scope.degreeProgram;
                    scope.ngModel.push(experience);
                    scope.updateDirty();
                    scope.updateValidity();
                };
            },
        };
    },
]);
