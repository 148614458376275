import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/demographics_form_section.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('demographicsFormSection', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                careerProfile: '<',
                gdprAppliesToUser: '<',
            },
            link(scope) {
                // Note: If you change this list also see the "Race" Airtable column
                scope.raceKeys = [
                    'american_indian_or_alaska',
                    'asian',
                    'african_american',
                    'latin_american',
                    'arab',
                    'native_hawaiian_or_other',
                    'white',
                    'other',
                    'no_identify',
                ];
                const raceKeysWithoutOther = scope.raceKeys.filter(key => key !== 'other');

                const propsValuesMap = {
                    race: scope.raceKeys,
                };

                // setup for custom input race value
                // This is set up this way because "other" is a selectable option, but is not actually a value we save.
                // if "other" is selected, it shows an input for a self described race, which is the value that actually gets saved
                scope.raceProxy = {
                    race: scope.careerProfile.race.map(val => (raceKeysWithoutOther.includes(val) ? val : 'other')),
                };
                scope.selfDescribedRaceProxy = {
                    value: scope.careerProfile.race.filter(key => !raceKeysWithoutOther.includes(key))[0],
                };
                scope.isSelfDescribedRace = !!scope.selfDescribedRaceProxy.value;

                // watchers set up to handle mapping the custom input value back into the career profile and vice versa
                scope.$watch(
                    'raceProxy.race',
                    val => {
                        if (val.some(key => !raceKeysWithoutOther.includes(key))) {
                            scope.isSelfDescribedRace = true;
                            scope.careerProfile.race = val
                                .filter(key => raceKeysWithoutOther.includes(key))
                                .concat(scope.selfDescribedRaceProxy.value)
                                .filter(Boolean);
                            return;
                        }

                        scope.isSelfDescribedRace = false;
                        scope.selfDescribedRaceProxy.value = null;
                        scope.careerProfile.race = val;
                    },
                    true,
                );

                scope.$watch(
                    'selfDescribedRaceProxy.value',
                    (newVal, oldVal) => {
                        if (oldVal) {
                            scope.careerProfile.race = scope.careerProfile.race.filter(v => v !== oldVal);
                        }
                        if (newVal) {
                            scope.careerProfile.race = scope.careerProfile.race.concat(newVal);
                        }
                    },
                    true,
                );

                FormHelper.supportCheckboxGroups(scope, scope.raceProxy, propsValuesMap);
            },
        };
    },
]);
