import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/submit_application_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import programPreferencesConfigs from 'Careers/programPreferencesConfigs';
import determineProgramPreferencesStateKey from 'Careers/determineProgramPreferencesStateKey';
import { getPendingOrSuccessAoiRecord } from 'Users';
import { ProgramFamily } from 'Program';
import { getFormsCompletePercent, getFirstIncompleteForm } from 'FormCompletionHelper';
import transformKeyCase from 'Utils/transformKeyCase';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('submitApplicationForm', [
    '$injector',
    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const TranslationHelper = $injector.get('TranslationHelper');
        const ngToast = $injector.get('ngToast');
        const $timeout = $injector.get('$timeout');
        const AuthFormHelperMixin = $injector.get('AuthFormHelperMixin');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const FormHelper = $injector.get('FormHelper');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $http = $injector.get('$http');
        const Cohort = $injector.get('Cohort');
        const $q = $injector.get('$q');
        const $window = $injector.get('$window');
        const dateHelper = $injector.get('dateHelper');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                //---------------------------
                // Mixins
                //---------------------------

                FormHelper.supportForm(scope, scope.submit_application);
                NavigationHelperMixin.onLink(scope);
                AuthFormHelperMixin.onLink(scope);

                //---------------------------
                // Mixin Initialization
                //---------------------------

                const translationHelper = new TranslationHelper('careers.edit_career_profile.submit_application_form');

                //---------------------------
                // Scope Properties
                //---------------------------

                scope.submitApplicationFormConfig = scope.currentUser.relevantCohort.applicationFormConfigs.find(
                    formConfig => formConfig.stepName === 'submit_application',
                );

                Object.defineProperty(scope, 'applicationComplete', {
                    get() {
                        const steps = scope.currentUser.relevantCohort.applicationFormConfigs;
                        return (
                            getFormsCompletePercent(scope.currentUser, steps) === 100 ||
                            getFirstIncompleteForm(scope.currentUser, steps)?.stepName === 'submit_application'
                        );
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'missingEarlyAdmissionResponse', {
                    get() {
                        return (
                            scope.submitApplicationFormConfig.inputs.earlyAdmissionOptIn &&
                            [null, undefined].includes(scope.programFamilyFormDatum?.earlyAdmissionOptIn)
                        );
                    },
                });

                Object.defineProperty(scope, 'optedInToScholarshipConsideration', {
                    get() {
                        return scope.programFamilyFormDatum?.scholarshipLabelsInterestedIn?.length;
                    },
                    configurable: true,
                });

                scope.decisionDate = dateHelper.formattedUserFacingMonthDayLong(
                    scope.currentUser.relevantCohort.lastDecisionDate,
                    false,
                );

                scope.chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();

                //---------------------------
                // Scope Initialization
                //---------------------------

                scope.helper.logEventForApplication('viewed-submit_application');

                // We don't show the program blurb container for MSBA/MSSE.
                scope.showProgramBlurb =
                    scope.applicationComplete &&
                    scope.currentUser.relevantCohort.programFamily === ProgramFamily.master_of_business_administration;

                // I tried putting this directly into the ng-click, but it would not work for some reason
                scope.toggleFullProfile = () => {
                    scope.showFullProfile = !scope.showFullProfile;
                };

                // See how programType is set in edit_career_profile_dir.js using `determineTargetProgramType`
                // If no programType is selected over there, then the user cannot submit the application here.
                if (scope.programType) {
                    $http
                        .get(`${$window.ENDPOINT_ROOT}/api/cohorts/promoted/${scope.programType}.json`)
                        .then(response => {
                            scope.cohort = Cohort.new(response.data.contents.cohorts[0]);
                        });
                }

                Object.defineProperty(scope, 'applicationDeadlineMessage', {
                    get() {
                        return scope.cohort.getApplicationDeadlineMessage();
                    },
                    configurable: true,
                });

                //---------------------------
                // Submit Application
                //---------------------------

                function showPostSubmitModal() {
                    return $q(resolve => {
                        const modalOptions = {
                            content: '<thanks-for-applying-modal></thanks-for-applying-modal>',
                            title: new TranslationHelper('careers.thanks_for_applying_modal').get('title'),
                            size: 'small',
                            close: resolve,
                        };

                        DialogModal.alert({
                            ...modalOptions,
                            close: resolve,
                        });
                    });
                }

                scope.pendingApplication = getPendingOrSuccessAoiRecord(scope.currentUser);
                const hasPendingApplication = !!scope.pendingApplication;
                const showApplicationUpdatedToast = () => {
                    ngToast.create({
                        content: translationHelper.get('application_updated'),
                        className: 'success',
                    });
                };

                scope.submitApplication = () => {
                    scope.submitting = true;
                    let promise;

                    // Add meta because the programFamilyFormDatum.earlyAdmissionOptIn field is on this form step
                    const meta = {
                        // Transform this to snake_case for the server. We don't need to be destructive here, we can
                        // simply use a clone of scope.programFamilyFormDatum to send along in the meta
                        program_family_form_datum: transformKeyCase(scope.programFamilyFormDatum, {
                            to: 'snakeCase',
                        }),
                    };

                    // save career profile because the phone field is on this page
                    scope.careerProfile.save(meta).then(() => {
                        // The unsaved changes confirmation dialog modal pops up when the route changes but we see that the
                        // form on the page is still dirty. At this point in the logic, the user has successfully saved
                        // their career profile, so they shouldn't have any unsaved changes, but the form may still dirty
                        // so we need to set the currentForm to pristine to prevent the unsaved changes confirmation dialog
                        // modal from popping up unexpectedly.
                        scope.currentForm?.$setPristine();
                        scope.currentUser.career_profile = scope.careerProfile;
                        const admissionsValarOptInValueChanged =
                            scope.config.showValarOptIn() &&
                            scope.pendingApplication?.admissionsValarOptIn !== scope.valarOptInProxy?.value;

                        if (hasPendingApplication && admissionsValarOptInValueChanged) {
                            promise = $http
                                .post(
                                    `${$window.ENDPOINT_ROOT}/api/user_actions/program_application/change_settings.json`,
                                    {
                                        record_id: scope.pendingApplication.id,
                                        admissions_valar_opt_in: scope.valarOptInProxy?.value,
                                    },
                                )
                                .then(showApplicationUpdatedToast);
                        } else if (hasPendingApplication) {
                            // We don't actually have to update anything here, but the timeout
                            // is necessary because the toast won't reliably show up unless defer a digest
                            promise = $timeout().then(showApplicationUpdatedToast);
                        } else {
                            promise = scope.currentUser
                                .applyToCohort(scope.cohort, scope.valarOptInProxy?.value ?? false)
                                .then(showPostSubmitModal);
                        }

                        return promise
                            .then(() => {
                                scope.loadRoute(hasPendingApplication ? '/settings/application' : '/dashboard');
                            })
                            .finally(() => {
                                scope.submitting = false;
                            });
                    });
                };

                scope.$watchGroup(['careerProfile', 'currentUser'], () => {
                    if (scope.careerProfile && scope.currentUser) {
                        const stateKey = determineProgramPreferencesStateKey(scope.careerProfile);
                        const configs = programPreferencesConfigs($injector, scope.currentUser, scope.programType);
                        scope.config = configs[stateKey];

                        scope.setDisableSaveForStep('submit_application', !!scope.config.saveButtonsDisabled);
                        handleValarOptIn();
                    }
                });

                scope.interviewTimePreferences = ['weekdays', 'weekend', 'no_pref'];

                function handleValarOptIn() {
                    if (!scope.config.showValarOptIn()) return;

                    // default the checkbox to checked unless there is already a stored boolean value
                    scope.valarOptInProxy = { value: scope.pendingApplication?.admissionsValarOptIn ?? true };
                }
            },
        };
    },
]);
