import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';
import { getProgramConfigValue } from 'Program';

angularModule.factory('CurriculumTemplate', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const Schedulable = $injector.get('Schedulable');

        // eslint-disable-next-line func-names
        return Iguana.subclass(function () {
            this.setCollection('curriculum_templates');
            this.alias('CurriculumTemplate');
            this.setIdProperty('id');
            this.include(Schedulable);

            // eslint-disable-next-line func-names
            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.specialization_playlist_pack_ids = this.specialization_playlist_pack_ids || [];
                this.periods = this.periods || [];
                this.computePeriodDates();

                // We don't have any relevant data points on a CurriculumTemplate to determine which
                // templates support these deadlines and which don't. Therefore, we set the initial
                // value to true if the offsets are set the in the DB, and then allow admins to manually
                // change these properties.
                this.supportsEnrollmentDocumentsDeadline = this.enrollment_documents_deadline_days_offset != null;
                this.supportsOfficialTranscriptsDeadline = this.official_transcripts_deadline_days_offset != null;
            });

            // This is not actually saved to the db, but it is used in the admin.
            // It follows the structure of cohort
            Object.defineProperty(this.prototype, 'startDate', {
                get() {
                    if (!this.$$startDate) {
                        this.$$startDate = this.computeDefaultStartDate();
                    }
                    return this.$$startDate;
                },
                set(val) {
                    this.$$startDate = val;
                },
            });

            // We do not assign slack rooms at the template level
            Object.defineProperty(this.prototype, 'supportsSlackRooms', {
                value: false,
            });

            // We do not allow the setting of this flag at the template level
            Object.defineProperty(this.prototype, 'supportsIsolatedNetwork', {
                value: false,
            });

            Object.defineProperty(this.prototype, 'supportsEnrollmentDocumentsDeadline', {
                get() {
                    return !!this.$$supportsEnrollmentDocumentsDeadline;
                },
                set(val) {
                    this.$$supportsEnrollmentDocumentsDeadline = !!val;
                    this.setDefaultEnrollmentDocumentsDeadline();
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'supportsOfficialTranscriptsDeadline', {
                get() {
                    return !!this.$$supportsOfficialTranscriptsDeadline;
                },
                set(val) {
                    this.$$supportsOfficialTranscriptsDeadline = !!val;
                    this.setDefaultOfficialTranscriptsDeadline();
                },
                configurable: true,
            });

            // We only support setting the external schedule URL at the curriculum template level
            // for programs that share the same URL across all cohorts
            Object.defineProperty(this.prototype, 'supportsExternalScheduleUrl', {
                get() {
                    return !!getProgramConfigValue(this.program_type, 'supportsSharedExternalScheduleUrl');
                },
            });

            Object.defineProperty(this.prototype, 'supportsCourseSummary', {
                get() {
                    return !!getProgramConfigValue(this.program_type, 'supportsCourseSummary');
                },
            });

            return {
                // match api for cohorts
                supportsTitle: false,
            };
        });
    },
]);
