
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.salary_survey_form_section = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.salary_survey_form_section || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.salary_survey_form_section = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.salary_survey_form_section, ...{
    "salary_survey": "Salary Survey",
    "salary_survey_caption": "This information is confidential and will not be shown publicly. It helps us match you with potential employers if you join the career network.",
    "total_salary": "Please indicate your total salary (not including bonus or commission) in the previous year",
    "prefer_not_to_disclose": "Prefer not to disclose",
    "less_than_20000": "Less than $20,000 USD",
    "20000_to_29999": "$20,000 to $29,999 USD",
    "30000_to_39999": "$30,000 to $39,999 USD",
    "less_than_40000": "Less than $40,000 USD",                
    "40000_to_49999": "$40,000 to $49,999 USD",
    "50000_to_59999": "$50,000 to $59,999 USD",
    "60000_to_69999": "$60,000 to $69,999 USD",
    "70000_to_79999": "$70,000 to $79,999 USD",
    "80000_to_89999": "$80,000 to $89,999 USD",
    "90000_to_99999": "$90,000 to $99,999 USD",
    "100000_to_119999": "$100,000 to $119,999 USD",
    "120000_to_149999": "$120,000 to $149,999 USD",
    "150000_to_199999": "$150,000 to $199,999 USD",
    "over_200000": "Over $200,000 USD"
}
}
    