import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/work_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { isEqual, sortBy } from 'lodash/fp';
import * as userAgentHelper from 'userAgentHelper';
import trashcanBeige from 'vectors/trashcan_beige.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('workForm', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const frontRoyalUpload = $injector.get('frontRoyalUpload');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $window = $injector.get('$window');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                scope.helper = EditCareerProfileHelper;
                FormHelper.supportForm(scope, scope.work);

                scope.helper.logEventForApplication('viewed-work');

                // set required responsibilities to pass to work experience detail
                scope.requiredResponsibilities = 1;

                scope.proxy = {};

                scope.careerProfile.addDummyWorkExperienceIfNecessary();

                scope.recentRolesOptions = scope.helper.recentRolesOptions(scope.careerProfile);
                scope.yearsExperienceOptions = scope.helper.yearsExperienceOptions(scope.careerProfile);

                scope.workFormFullTimeWorkHistoryKey = ['7_10', '11_15', '16_plus'].includes(
                    scope.careerProfile.survey_years_full_time_experience,
                )
                    ? 'work_history_description_high_experience'
                    : 'work_history_description_low_experience';

                // deep watch the career profile for end_date changes on the work experiences so we can
                // change the featured work experience when appropriate.
                scope.$watch(
                    'careerProfile',
                    (newCareerProfile, oldCareerProfile) => {
                        const newEndDates = newCareerProfile.work_experiences.map(we => we.end_date);
                        const oldEndDates = oldCareerProfile.work_experiences.map(we => we.end_date);
                        if (!isEqual(newEndDates, oldEndDates)) {
                            /*
                                We want to force set the featured work experience ourselves if a new experience was NOT
                                added and 1 of the following 3 scenarios has occurred:

                                1) The user can't see the "Featured Work Experience" field to manually change it themselves,
                                    i.e. the number of current work experiences is less than 2.
                                2) The featured work experience has been made not current.
                                3) The "Featured Work Experience" field just became visible to the user, so they can
                                    manually set it themselves.

                                Basically, we want to be hands-on and actively setting the featured work experience in the
                                background if the user wasn't able to set it themselves before, and hands-off if the user
                                can see it.
                            */
                            const oldNullEndDates = oldEndDates.filter(endDate => endDate === null);
                            const currentFullTimeWorkExperiences = scope.careerProfile.currentFullTimeWorkExperiences;
                            if (
                                newEndDates.length <= oldEndDates.length &&
                                (currentFullTimeWorkExperiences.length < 2 ||
                                    (scope.proxy.featuredWorkExperience &&
                                        scope.proxy.featuredWorkExperience.end_date !== null) ||
                                    (oldNullEndDates.length === 1 && currentFullTimeWorkExperiences.length === 2))
                            ) {
                                setFeaturedWorkExperience();
                            }
                        }
                    },
                    true,
                );

                scope.$watch('proxy.featuredWorkExperience', handleProxyFeaturedWorkExperienceChange);

                function handleProxyFeaturedWorkExperienceChange() {
                    if (
                        !scope.proxy.featuredWorkExperience &&
                        scope.careerProfile.currentFullTimeWorkExperiences.length > 0 &&
                        scope.careerProfile.featuredWorkExperience &&
                        scope.careerProfile.featuredWorkExperience.end_date === null
                    ) {
                        // In the event that the career profile gets saved, proxy.featuredWorkExperience gets reset to undefined.
                        // In this instance we should be setting it to the currently featured work experience on the career profile.
                        scope.proxy.featuredWorkExperience = scope.careerProfile.featuredWorkExperience;
                    }
                    scope.careerProfile.featuredWorkExperience = scope.proxy.featuredWorkExperience;
                }

                setFeaturedWorkExperience(scope.careerProfile.featuredWorkExperience);

                // logic for setting featured work experience
                function setFeaturedWorkExperience(existingFeaturedWorkExperience) {
                    const currentFullTimeWorkExperiences = scope.careerProfile.currentFullTimeWorkExperiences;
                    if (existingFeaturedWorkExperience) {
                        scope.proxy.featuredWorkExperience = scope.careerProfile.featuredWorkExperience;
                    } else if (scope.careerProfile.work_experiences.length === 1) {
                        scope.proxy.featuredWorkExperience = scope.careerProfile.work_experiences[0];
                    } else if (currentFullTimeWorkExperiences.length > 0) {
                        // if the career profile has current work experiences, default the featured work experience
                        // to the current work experience with the most recent start_date
                        const sortedCurrentWorkExps = sortBy(
                            workExp => workExp.start_date,
                            currentFullTimeWorkExperiences,
                        );
                        scope.proxy.featuredWorkExperience = sortedCurrentWorkExps.at(-1);
                    } else {
                        // if the career profile has no current work experiences, default the featured work
                        // experience to the work exp with the most recent end_date. If multiple work exps
                        // have the most recent end_date, default the featured work exp to the exp with the
                        // most recent start_date.
                        let sortedWorkExps = sortBy(workExp => workExp.end_date, scope.careerProfile.work_experiences);

                        const sameEndDateWorkExps = _.filter(sortedWorkExps, {
                            end_date: sortedWorkExps.length > 0 && _.last(sortedWorkExps).end_date,
                        });
                        sortedWorkExps = sortBy(workExp => workExp.start_date, sameEndDateWorkExps);
                        scope.proxy.featuredWorkExperience = sortedWorkExps.at(-1);
                    }
                }

                scope.moveWorkExperience = (workExperience, employmentType) => {
                    const index = scope.careerProfile.work_experiences.indexOf(workExperience);
                    scope.careerProfile.work_experiences.splice(index, 1);
                    workExperience.employment_type = employmentType === 'full_time' ? 'part_time' : 'full_time';
                    scope.careerProfile.work_experiences.push(workExperience);
                };

                scope.trashcanBeige = trashcanBeige;
                scope.chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();
                scope.fileName = scope.careerProfile.resume ? scope.careerProfile.resume.file_file_name : undefined;
                scope.uploadedAt = scope.careerProfile.resume ? scope.careerProfile.resume.file_updated_at : undefined;
                scope.linksCollapsed = true;

                const translationHelper = new TranslationHelper('careers.edit_career_profile.resume_and_links_form');
                scope.uploadString = translationHelper.get('upload');

                scope.toggleLinksCollapsed = () => {
                    scope.linksCollapsed = !scope.linksCollapsed;
                };

                // We don't use the ng-model that ng-file-upload sets for our careerProfile form, but we
                // do use it for required validation and ng-file-upload sets it when uploading a file.
                // Therefore we need to initialize this proxyFile to something that validates to true when
                // first loading the page in order for required validation to work properly. See also removeResume
                // below.
                scope.proxyFile = scope.careerProfile.resume ? scope.careerProfile.resume : undefined;

                // We used to hide the resume upload section if the User-Agent was on an iOS devices, regardless of the device type
                // (see https://trello.com/c/GbHl1dnr). Since then, we've relaxed this rule a bit to allow iOS tablet devices to
                // upload a resume (see https://trello.com/c/L5VQxmG1).
                scope.hideResumeUpload =
                    $window.CORDOVA && userAgentHelper.isiOSDevice() && userAgentHelper.isMobileDevice();

                //-----------------------------
                // File upload / delete
                //-----------------------------

                scope.removeResume = () => {
                    scope.careerProfile.resume_id = null;
                    scope.fileName = undefined;
                    scope.proxyFile = undefined;
                };

                scope.onFileSelect = ($file, errFiles) => {
                    if (!scope.currentUser) return;

                    scope.errMessage = null;
                    scope.uploading = true;

                    frontRoyalUpload
                        .handleNgfSelect($file, errFiles, file => ({
                            url: scope.currentUser.resumeUploadUrl(),
                            data: { resume_file: file, resume_file_name: file.name },
                            supportedFormatsForErrorMessage: '.pdf, .doc, .docx, .jpg, .png',
                        }))
                        .then(response => {
                            scope.careerProfile.resume_id = response.data.contents.resume.id;
                            scope.careerProfile.resume = response.data.contents.resume;
                            scope.fileName = response.data.contents.resume.file_file_name;
                            scope.uploadedAt = response.data.contents.resume.file_updated_at;
                        })
                        .catch(err => {
                            scope.errMessage = err && err.message;
                        })
                        .finally(() => {
                            scope.uploading = false;
                        });
                };
            },
        };
    },
]);
