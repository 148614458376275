import angularModule from 'Careers/angularModule/scripts/careers_module';
import transformKeyCase from 'Utils/transformKeyCase';
import moment from 'moment';

angularModule.factory('CareerProfile', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const EducationExperience = $injector.get('EducationExperience');
        const WorkExperience = $injector.get('WorkExperience');
        const HasLocation = $injector.get('HasLocation');
        const $translate = $injector.get('$translate');
        const $rootScope = $injector.get('$rootScope');
        const ErrorLogService = $injector.get('ErrorLogService');
        const ClientStorage = $injector.get('ClientStorage');
        const Cohort = $injector.get('Cohort');

        return Iguana.subclass(function () {
            this.setCollection('career_profiles');
            this.alias('CareerProfile');
            this.setIdProperty('id');
            this.embedsMany('education_experiences', 'EducationExperience');
            this.embedsMany('work_experiences', 'WorkExperience');

            // Since the filters for index calls can get really long,
            // we use a post to avoid having get urls that go beyond browser
            // limits.
            this.overrideAction('index', {
                method: 'POST',
                path: 'index',
            });
            this.include(HasLocation);

            this.setCallback('after', 'copyAttrsOnInitialize', function copyAttrsOnInitializeCallback() {
                this.education_experiences ||= [];
            });

            this.setCallback('after', 'copyAttrs', function copyAttrsCallback() {
                // Any time we copyAttrs on a CareerProfile (after initialize or when pushing onto the
                // CareerProfile via CareerProfileInterceptor), we want to copy all of these underscore
                // properties to their camelCase equivalents.
                // We consider this "destructive" because we're mutating the original object, not a clone.
                transformKeyCase(this, {
                    to: 'camelCase',
                    keys: ['completed_at'],
                    destructive: true,
                });
            });

            this.extend({
                ACTIVE_INTEREST_LEVELS: _.reject(
                    $injector.get('NEW_COMPANY_INTEREST_LEVELS'),
                    interestLevel => interestLevel === 'not_interested',
                ),
            });

            this.defineSetter('program_type', function (val) {
                // We saw a user mysteriously switch back to MBA after
                // converting to EMBA. We struggled to reproduce the issue,
                // but if it happens again, we'd like to to know how/when.
                // See also: https://trello.com/c/hAsMiFFJ
                // Update: The logic here is not totally correct.  If the user has been
                // rejected, then they should now be allowed to change program types
                // again.  (I didn't bother to update this logic because it has only happened
                // once and it is really just affecting logging to help engineers.  It might make
                // sense in the future to update this logic though.)  See https://trello.com/c/RUUexX7Q
                if (val !== this.program_type && ClientStorage.getItem('converted_to_program_type')) {
                    ErrorLogService.notifyInProd(
                        'Unexpected program_type change after converted_to_program_type on career_profile',
                        undefined,
                        {
                            level: 'warning',
                            career_profile_id: this.id,
                        },
                    );
                }

                this.writeKey('program_type', val);
            });

            // During the Quantic transition we are only allowing
            // Quantic degree programs to be (re)applied to
            Object.defineProperty(this.prototype, 'programTypeIfAllowed', {
                get() {
                    const currentUser = $rootScope.currentUser;

                    // This should only trigger in the admin, in which
                    // case our checks below won't be applicable
                    if (currentUser.id !== this.user_id) {
                        return this.program_type;
                    }

                    if (!Cohort.isDegreeProgram(this.program_type)) {
                        return null;
                    }

                    return this.program_type;
                },
            });

            // Gets the initials of the user (e.g. 'John Doe' => 'J. D.')
            Object.defineProperty(this.prototype, 'userInitials', {
                get() {
                    const namesArray = this.name.split(' ');
                    return _.map(namesArray, name => `${name[0]}. `)
                        .join('')
                        .trim();
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'age', {
                get() {
                    if (!this.birthdate) {
                        return null;
                    }
                    const today = moment();
                    const birthdate = moment(this.birthdate);
                    return today.diff(birthdate, 'year');
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'longLabel', {
                get() {
                    const candidateEmail = this.email ? ` [${this.email}]` : ' ';
                    // Example: Johnny Appleseed [johnny.appleseed@foobar.com]
                    return this.name + candidateEmail;
                },
                configurable: true, // for specs
            });

            Object.defineProperty(this.prototype, 'workExperiences', {
                get() {
                    // not an empty one
                    return _.filter(this.work_experiences, w => !!w.orgName);
                },
            });

            Object.defineProperty(this.prototype, 'educationExperiences', {
                get() {
                    // not an empty one
                    return _.filter(this.education_experiences, e => !!e.orgName);
                },
            });

            Object.defineProperty(this.prototype, 'formalEducationExperiences', {
                get() {
                    return _.filter(this.education_experiences, e => e.degreeProgram);
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasLoneEmptyFormalEducationExperience', {
                get() {
                    // if any of the form fields change in education_experience_detail, we should verify that the `isEmpty` property still works as expected
                    return this.formalEducationExperiences.length === 1 && this.formalEducationExperiences[0].isEmpty;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'hasUploadedTranscripts', {
                get() {
                    return !!this.educationExperienceRequiringOfficialTranscript?.transcriptUploaded;
                },
                configurable: true,
            });

            // We used to duplicate this logic on the server, but the logic on the server has since
            // deviated from the client-side logic. See missing_official_transcripts? in career_profile.rb
            Object.defineProperty(this.prototype, 'missingOfficialTranscripts', {
                get() {
                    const experience = this.educationExperienceRequiringOfficialTranscript;
                    return experience && !experience.officialTranscriptUploadedOrWaived;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'missingTranscriptApprovals', {
                get() {
                    const experience = this.educationExperienceRequiringOfficialTranscript;
                    return experience && !experience.transcript_approved;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'educationExperienceRequiringOfficialTranscript', {
                get() {
                    return (
                        this.education_experiences.find(e => e.transcript_status === 'official_approved') ||
                        this.education_experiences.find(e => e.official_transcript_required)
                    );
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'educationExperiencesThatCanRequireOfficial', {
                get() {
                    return this.education_experiences.filter(e => e.canRequireOfficial);
                },
                configurable: true,
            });

            // FIXME: https://trello.com/c/wA2Cp2Cs
            Object.defineProperty(this.prototype, 'numRequiredTranscripts', {
                get() {
                    return this.educationExperienceRequiringOfficialTranscript ? 1 : 0;
                },
                configurable: true,
            });

            // FIXME: https://trello.com/c/wA2Cp2Cs
            Object.defineProperty(this.prototype, 'numRequiredTranscriptsUploaded', {
                get() {
                    return this.educationExperienceRequiringOfficialTranscript?.transcriptUploaded ? 1 : 0;
                },
                configurable: true,
            });

            // FIXME: https://trello.com/c/wA2Cp2Cs
            Object.defineProperty(this.prototype, 'numRequiredTranscriptsApproved', {
                get() {
                    return this.educationExperienceRequiringOfficialTranscript?.transcript_approved ? 1 : 0;
                },
                configurable: true,
            });

            // FIXME: https://trello.com/c/wA2Cp2Cs
            Object.defineProperty(this.prototype, 'numRequiredTranscriptsWaived', {
                get() {
                    return this.educationExperienceRequiringOfficialTranscript?.transcript_waiver ? 1 : 0;
                },
                configurable: true,
            });

            // FIXME: https://trello.com/c/wA2Cp2Cs
            Object.defineProperty(this.prototype, 'numRequiredTranscriptsNotWaived', {
                get() {
                    const experience = this.educationExperienceRequiringOfficialTranscript;
                    return experience && !experience.transcript_waiver ? 1 : 0;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'officialTranscriptApprovedOrWaived', {
                get() {
                    return !!this.educationExperienceRequiringOfficialTranscript?.transcriptApprovedOrWaived;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'officialTranscriptApproved', {
                get() {
                    return !!this.educationExperienceRequiringOfficialTranscript?.transcript_approved;
                },
                configurable: true,
            });

            // See also career_profile.rb
            Object.defineProperty(this.prototype, 'indicatesUserShouldProvideTranscripts', {
                get() {
                    return !!this.educationExperienceRequiringOfficialTranscript;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'currentFullTimeWorkExperiences', {
                get() {
                    return _.filter(this.fullTimeWorkExperiences, {
                        end_date: null,
                    });
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'featuredWorkExperience', {
                get() {
                    return _.find(this.work_experiences, {
                        featured: true,
                    });
                },
                set(workExperience) {
                    _.forEach(this.work_experiences, workExp => {
                        if (workExp.featured) {
                            workExp.featured = false;
                        }
                    });
                    if (workExperience) {
                        workExperience.featured = true;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'fullTimeWorkExperiences', {
                get() {
                    return _.filter(this.workExperiences, {
                        employment_type: 'full_time',
                    }).sort((w1, w2) => {
                        // We want the featured one at the top of the list
                        if (w1.featured && !w2.featured) {
                            return -1;
                        }
                        if (!w1.featured && w2.featured) {
                            return 1;
                        }

                        // I guess we should move the most recently started ones
                        // above less-recently started ones
                        if (w1.start_date > w2.start_date) {
                            return -1;
                        }
                        if (w1.start_date < w2.start_date) {
                            return 1;
                        }
                        return 0;
                    });
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'partTimeWorkExperiences', {
                get() {
                    return _.filter(this.workExperiences, {
                        employment_type: 'part_time',
                    });
                },
            });

            Object.defineProperty(this.prototype, '_showPhotosAndNames', {
                get() {
                    return !$rootScope.currentUser || $rootScope.currentUser.pref_show_photos_names;
                },
            });

            Object.defineProperty(this.prototype, 'sanitizedAvatarSrc', {
                get() {
                    return this._showPhotosAndNames ? this.avatar_url : null;
                },
            });

            Object.defineProperty(this.prototype, 'sanitizedName', {
                get() {
                    return this._showPhotosAndNames ? this.name : this.userInitials;
                },
            });

            Object.defineProperty(this.prototype, 'mostRecentPositionText', {
                get() {
                    // grab the featured work experience
                    const workExperience = this.featuredWorkExperience;

                    if (workExperience && workExperience.orgName) {
                        const position = `${workExperience.job_title} @ ${workExperience.orgName}`;

                        // if there's an end-date, it means the featured work experience is in the past
                        if (workExperience.end_date) {
                            return `${$translate.instant('careers.candidate_list_card.formerly')} ${position}`;
                        }
                        return position;
                    }
                    return '';
                },
            });

            Object.defineProperty(this.prototype, 'mostRecentEducationText', {
                get() {
                    // filter out non-degree programs and grab the most recent education experience
                    const educationExperience = _.first(
                        _.filter(this.educationExperiences, e => e.degreeProgram && e.orgName),
                    );

                    return educationExperience
                        ? `${educationExperience.degreeName}, ${educationExperience.orgName}`
                        : '';
                },
            });

            Object.defineProperty(this.prototype, 'openToAllLocations', {
                get() {
                    return (
                        this.locations_of_interest &&
                        this.locations_of_interest.length > 0 &&
                        this.locations_of_interest[0] === 'flexible'
                    );
                },
            });

            Object.defineProperty(this.prototype, 'openToSomeAdditionalLocations', {
                get() {
                    return (
                        this.locations_of_interest &&
                        this.locationsWithoutCurrentLocation.length > 0 &&
                        this.locations_of_interest[0] !== 'none' &&
                        this.locations_of_interest[0] !== 'flexible'
                    );
                },
            });

            Object.defineProperty(this.prototype, 'openToNoAdditionalLocations', {
                get() {
                    return (
                        this.locations_of_interest &&
                        this.locations_of_interest.length > 0 &&
                        this.locations_of_interest[0] === 'none'
                    );
                },
            });

            Object.defineProperty(this.prototype, 'locationsWithoutCurrentLocation', {
                get() {
                    const that = this;
                    return _.chain(this.locations_of_interest || [])
                        .map(location => $translate.instant(`careers.field_options.${location}`))
                        .filter(location => location !== that.locationString)
                        .value();
                },
            });

            Object.defineProperty(this.prototype, 'resumeUrl', {
                get() {
                    if (
                        this.resume &&
                        this.resume.formats &&
                        this.resume.formats.original &&
                        this.resume.formats.original.url
                    ) {
                        return this.resume.formats.original.url;
                    }
                    return false;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'githubUrl', {
                get() {
                    return this.github_profile_url || false;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'salaryRangeForEventPayload', {
                get() {
                    // This mapping comes from FrontRoyal.Careers module and careerPreferencesForm directive
                    const salaryRangeMapping = {
                        prefer_not_to_disclose: 0,
                        less_than_20000: 0,
                        '20000_to_29999': 0,
                        '30000_to_39999': 0,
                        less_than_40000: 0, // legacy value
                        '40000_to_49999': 40000,
                        '50000_to_59999': 50000,
                        '60000_to_69999': 60000,
                        '70000_to_79999': 70000,
                        '80000_to_89999': 80000,
                        '90000_to_99999': 90000,
                        '100000_to_119999': 100000,
                        '120000_to_149999': 120000,
                        '150000_to_199999': 150000,
                        over_200000: 200000,
                    };

                    return salaryRangeMapping[this.salary_range] || 0;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'currentSalaryRange', {
                get() {
                    if (this.salary_range && this.salary_range !== 'prefer_not_to_disclose') {
                        return {
                            lower: parseInt(this.salary_range.split('_').shift(), 10) || null,
                            upper: parseInt(this.salary_range.split('_').pop(), 10) || null,
                        };
                    }
                    return null;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'appliedAt', {
                get() {
                    if (!this.$$appliedAt) {
                        this.$$appliedAt = new Date(this.applied_at * 1000);
                    }
                    return this.$$appliedAt;
                },
            });

            return {
                interestedInEmploymentType(type) {
                    return _.includes(this.employment_types_of_interest, type);
                },

                addDummyEducationExperienceIfNecessary() {
                    // If the career profile has no formal education experiences, add a dummy education experience.
                    // NOTE: used to populate the educationExperienceDetail directive with a blank education experience on the educationForm.
                    if (!_.some(this.formalEducationExperiences)) {
                        this.education_experiences.push(
                            EducationExperience.new({
                                degreeProgram: true,
                            }),
                        ); // dummy education experience
                    }
                },

                addDummyWorkExperienceIfNecessary() {
                    // If the career profile has no work experiences, create a dummy work experience.
                    // NOTE: used to populate the workExperienceDetail directive with a blank work experience on the workForm.
                    if (!_.some(this.work_experiences)) {
                        this.work_experiences = [
                            WorkExperience.new({
                                employment_type: 'full_time',
                                featured: false,
                            }),
                        ]; // dummy work experience
                    }
                },

                asJson($super) {
                    const json = $super();

                    // filter out empty work and education experiences that were added as dummies
                    const hasFields = requiredKeys => element =>
                        _.chain(element).keys().intersection([requiredKeys].flat()).value().length ===
                        requiredKeys.length;

                    json.education_experiences = _.filter(
                        json.education_experiences,
                        hasFields(['educational_organization', 'graduation_year', 'major']),
                    );
                    json.work_experiences = _.filter(
                        json.work_experiences,
                        hasFields(['professional_organization', 'job_title', 'start_date']),
                    );

                    return json;
                },

                hasSharedStudentNetworkInterests(careerProfile) {
                    function mapInterests(interests) {
                        return interests && _.map(interests, interest => interest.text.toLowerCase());
                    }

                    const thisInterests = mapInterests(this.student_network_interests);
                    const profileInterests = mapInterests(careerProfile.student_network_interests);

                    return _.intersection([thisInterests].flat(), [profileInterests].flat()).length > 0;
                },

                hasSameCompany(careerProfile) {
                    return (
                        (this.featuredWorkExperience && this.featuredWorkExperience.professional_organization.id) ===
                        (careerProfile.featuredWorkExperience &&
                            careerProfile.featuredWorkExperience.professional_organization.id)
                    );
                },

                hasSameRole(careerProfile) {
                    return (
                        (this.featuredWorkExperience && this.featuredWorkExperience.role) ===
                        (careerProfile.featuredWorkExperience && careerProfile.featuredWorkExperience.role)
                    );
                },

                hasSharedSchools(careerProfile) {
                    function mapSchools(educationExperiences) {
                        return (
                            educationExperiences &&
                            _.map(
                                educationExperiences,
                                experience =>
                                    experience.educational_organization && experience.educational_organization.id,
                            )
                        );
                    }

                    const thisSchools = mapSchools(this.education_experiences);
                    const profileSchools = mapSchools(careerProfile.education_experiences);

                    return _.intersection([thisSchools].flat(), [profileSchools].flat()).length > 0;
                },
            };
        });
    },
]);
