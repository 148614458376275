import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/demographics_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('demographicsForm', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                FormHelper.supportForm(scope, scope.demographics);

                scope.helper.logEventForApplication('viewed-demographics');
            },
        };
    },
]);
