import angularModule from 'ClientConfig/angularModule/scripts/client_config_module';
import { getBrandName } from 'AppBranding';

angularModule.factory('ClientConfigInterceptor', [
    '$injector',

    $injector => {
        const ClientConfig = $injector.get('ClientConfig');
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const $q = $injector.get('$q');
        const clientConfig = ClientConfig.current;
        const FormatsText = $injector.get('FormatsText');
        const Capabilities = $injector.get('Capabilities');
        const ClientStorage = $injector.get('ClientStorage');

        const shownSuggestedUpgradesJson = ClientStorage.getItem('shownSuggestedUpgrades');
        let shownSuggestedUpgrades;
        try {
            shownSuggestedUpgrades = JSON.parse(shownSuggestedUpgradesJson || '{}');
        } catch (e) {
            shownSuggestedUpgrades = {};
        }

        function showUpdateRequiredView(message) {
            $injector.get('DialogModal').alert({
                content: '<upgrade-client message="message"></upgrade-client>',
                scope: {
                    message,
                },
                title: $window.CORDOVA ? 'Update Required' : undefined,
                size: 'small',
                hideCloseButton: true,
            });
        }

        return {
            request(config) {
                if (config.url.includes('/api')) {
                    // Note: underscores do not work in headers
                    config.headers['fr-client-version'] = clientConfig.versionNumber;
                    config.headers['fr-client'] = clientConfig.identifier;
                }
                return config;
            },
            response(response) {
                // NOTE: Uncomment this if you want to test an unstamped version of the hybrid app
                // return response

                const responseConfig = response.config;
                if (responseConfig.url.includes('/api') && !responseConfig.url.includes('/api/auth')) {
                    // lazy load to avoid circular dependency
                    const TranslationHelper = $injector.get('TranslationHelper');
                    const translationHelper = new TranslationHelper('client_config.client_config_interceptor');
                    const ConfigFactory = $injector.get('ConfigFactory');
                    const meta = response.data.meta || {};

                    if (meta.client_app_store_name && !window.CORDOVA?.miyaMiya) {
                        clientConfig.appStoreName = meta.client_app_store_name;
                    } else if (meta.client_app_store_name_miyamiya && window.CORDOVA?.miyaMiya) {
                        clientConfig.appStoreName = meta.client_app_store_name_miyamiya;
                    }

                    const customUpgradeMessage = meta.upgrade_message
                        ? FormatsText.processMarkdown(meta.upgrade_message)
                        : undefined;

                    if (meta.min_allowed_client_version > clientConfig.versionNumber) {
                        return ConfigFactory.getConfig().then(config => {
                            const customRequiredMessage =
                                customUpgradeMessage ||
                                translationHelper.get('update_is_required', {
                                    brandName: getBrandName($rootScope.currentUser, config, 'short'),
                                });

                            // In native app, use native alert for this message
                            if ($window.CORDOVA) {
                                navigator.notification.alert(
                                    customRequiredMessage,
                                    () => {
                                        // EventLogger has to be lazily-injected to prevent errors
                                        const EventLogger = $injector.get('EventLogger');
                                        clientConfig.upgrade(EventLogger);
                                        showUpdateRequiredView(customRequiredMessage);
                                    },
                                    translationHelper.get('update_is_required_cordova'),
                                    clientConfig.upgradeButtonText,
                                );
                            } else {
                                showUpdateRequiredView(customRequiredMessage);
                            }

                            // return a promise that will never be resolved,
                            // so that this api response is never handled and the
                            // app is effectively frozen
                            return $q(() => {});
                        });
                    }

                    if (
                        !shownSuggestedUpgrades[meta.min_suggested_client_version] &&
                        meta.min_suggested_client_version > clientConfig.versionNumber
                    ) {
                        return ConfigFactory.getConfig().then(config => {
                            shownSuggestedUpgrades[meta.min_suggested_client_version] = true;
                            const customSuggestMessage =
                                customUpgradeMessage ||
                                translationHelper.get('a_new_version_is_available', {
                                    brandName: getBrandName($rootScope.currentUser, config, 'short'),
                                });

                            if (Capabilities.localStorage) {
                                ClientStorage.setItem('shownSuggestedUpgrades', JSON.stringify(shownSuggestedUpgrades));
                            }
                            if ($window.CORDOVA) {
                                navigator.notification.confirm(
                                    customSuggestMessage,
                                    buttonIndex => {
                                        if (buttonIndex === 1) {
                                            // EventLogger has to be lazily-injected to prevent errors
                                            const EventLogger = $injector.get('EventLogger');
                                            clientConfig.upgrade(EventLogger);
                                        }
                                    },
                                    translationHelper.get('update_available_cordova'),
                                    [clientConfig.upgradeButtonText, 'Skip'],
                                );
                            } else {
                                $injector.get('DialogModal').alert({
                                    content: '<upgrade-client message="message"></upgrade-client>',
                                    scope: {
                                        message: customSuggestMessage,
                                    },
                                    size: 'small',
                                    hideCloseButton: false,
                                });
                            }
                            return response;
                        });
                    }
                }
                return response;
            },
        };
    },
]);

angularModule.config([
    '$httpProvider',
    $httpProvider => {
        $httpProvider.interceptors.push('ClientConfigInterceptor');
    },
]);
