
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.education_form, ...{
    "education": "Education",
    "formal_degree_programs": "Formal Degree Programs",
    "non_degree_programs": "Non-Degree Programs (Optional)",
    "yes": "Yes",
    "no": "No",
    "english_language_proficiency": "English Language Proficiency",
    "native_english_speaker": "I am a native English speaker.",
    "earned_accredited_degree_in_english": "I have earned a degree from an accredited university taught in English.",
    "education_summary": "Education Summary",
    "my_highest_level_education": "My highest level of COMPLETED education is best described as…",
    "highest_level_education": "Highest level education",
    "earned_accredited_degree_in_english_degree_sub_text": "Upon acceptance, you will be required to provide acceptable English language proficiency results from an approved assessment.",
    "has_no_formal_education": "I have never enrolled in a formal degree program.",
    "sufficient_english_work_experience": "Have you worked in a predominantly English-speaking workplace for five or more years?",
    "sufficient_english_work_experience_degree_sub_text": "Upon acceptance, we will require you to provide English language proficiency scores from the TOEFL or similarly recognized test.",
    "english_work_experience_description": "What company or companies?",
    "legal_names": "Legal Name(s)",
    "legal_names_question": "If admitted, you will be asked to provide transcripts confirming your degrees. Please list any additional legal names that appear on your past transcripts:",
    "legal_names_subtext_less_than_five": "Limit {{maxNames}}",
    "legal_names_subtext_greater_than_five": "You have exceeded {{maxNames}} names. Please prioritize listing names that are on your official transcripts from your highest level of education.",
    "legal_names_subtext_five": "Maximum limit of {{maxNames}} reached."
}
}
    