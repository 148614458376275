import { type TFunction } from 'i18next';
import { type CareerProfile } from './CareerProfiles.types';

export const getFeaturedWorkExperience = (cp: CareerProfile) => {
    if (!cp) return null;

    return cp.workExperiences.find(c => !!c.featured) ?? null;
};

export const getMostRecentPositionText = (cp: CareerProfile | null, t?: TFunction) => {
    if (!cp) return '';

    const workExperience = getFeaturedWorkExperience(cp);

    if (!workExperience) return '';

    let text = workExperience.jobTitle;
    if (!text) return '';

    if (workExperience.professionalOrganization?.text) {
        text = `${text}, ${workExperience.professionalOrganization.text}`;

        if (workExperience.endDate) {
            if (t) {
                text = `${t('careers.candidate_list_card.formerly')} ${text}`;
            } else {
                text = `Formerly ${text}`;
            }
        }
    }
    return text;
};
