
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.deferral_link = window.Smartly.locales.modules.en.back_royal.careers.deferral_link || {};
window.Smartly.locales.modules.en.back_royal.careers.deferral_link = {...window.Smartly.locales.modules.en.back_royal.careers.deferral_link, ...{
    "quantic_online_deferral": "Quantic Online Deferral",
    "you_are_registered": "You are registered for the <b>{{fromCohortTitle}} Cohort</b>. To defer your place to a future class, please complete the form below, selecting a start date within one year of your original registration.",
    "would_like_to_join": "I would like to join...",
    "billing_confirmation": "I understand monthly tuition billing is now suspended and will restart on {{trialEndDate}}.",
    "submit": "Submit",
    "questions_contact_us": "Questions? Contact us at <a href=\"mailto:{{supportEmail}}\">{{supportEmail}}</a>",
    "thanks_for_registering": "Thanks for registering for the <b>{{toCohortTitle}} Cohort</b>, which begins on {{toCohortStartDate}}! You will receive an email reminder three weeks prior to the class start date.",
    "calendar_event_description": "Class begins for the {{cohortTitle}} Cohort."
}
}
    