import { type VerifyParams, type BioSigScope } from './BioSig.types';
import { clearBioSigQuery, handleGetBioSigUrlRequest } from './helpers';

// This is for angular what the useBioSig hook is for react
// The shared logic is in ./helpers.ts
export const BioSigMixin = {
    onLink: (scope: BioSigScope, $injector: ng.auto.IInjectorService) => {
        const setLoading = (loading: boolean) => {
            scope.isLoadingBioSig = loading;
        };

        setLoading(false);
        clearBioSigQuery($injector);

        scope.enrollBioSig = async () => handleGetBioSigUrlRequest('enroll.json', $injector, setLoading);
        scope.verifyBioSig = async (params: VerifyParams) =>
            handleGetBioSigUrlRequest('verify.json', $injector, setLoading, params);
    },
};
