import angularModule from 'Careers/angularModule/scripts/careers_module';

angularModule.directive('careersAvatar', [
    '$injector',
    function factory($injector) {
        const DEFAULT_PROFILE_PHOTO = $injector.get('DEFAULT_PROFILE_PHOTO');

        return {
            restrict: 'E',
            scope: {
                src: '<',
            },
            link(scope, elem) {
                scope.$watch('src', src => {
                    // We're setting two background images with the src image layered on top of
                    // the DEFAULT_PROFILE_PHOTO image. If the image can't be rendered from src,
                    // then the DEFAULT_PROFILE_PHOTO background image will be shown instead.
                    // If src is unspecified, we don't attempt set it, and only use the default.
                    const defaultSrcUrl = `url(${DEFAULT_PROFILE_PHOTO})`;

                    // put on the scope for testing purposes since JSDom doesn't support
                    // multiple background images: https://github.com/jsdom/jsdom/issues/1166
                    scope.fullSrcUrl = src ? `url(${src}), ${defaultSrcUrl}` : defaultSrcUrl;

                    elem.css('background-image', scope.fullSrcUrl);

                    // for invalid highlighting
                    if (src) {
                        elem.removeClass('empty');
                    } else {
                        elem.addClass('empty');
                    }
                });
            },
        };
    },
]);
