
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.demographics_form_section = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.demographics_form_section || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.demographics_form_section = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.demographics_form_section, ...{
    "demographics": "Demographics",
    "welcome_to_move_on": "Any questions regarding demographics and sex comply with the U.S. Department of Education's standards. They are completely optional.",
    "sex": "Sex",
    "how_identify_yourself": "Please indicate how you identify yourself (select all that apply)",
    "identify_placeholder": "Describe here",
    "gender": "Gender"
}
}
    