
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.client_config = window.Smartly.locales.modules.en.back_royal.client_config || {};
window.Smartly.locales.modules.en.back_royal.client_config.client_config_interceptor = window.Smartly.locales.modules.en.back_royal.client_config.client_config_interceptor || {};
window.Smartly.locales.modules.en.back_royal.client_config.client_config_interceptor = {...window.Smartly.locales.modules.en.back_royal.client_config.client_config_interceptor, ...{
    "a_new_version_is_available": "A new version of {{brandName}} is available!",
    "update_available_cordova": "Update Available",
    "update_is_required": "A new version of {{brandName}} is available and an update is required.",
    "update_is_required_cordova": "Update Required"
}
}
    