import angularModule from 'Careers/angularModule/scripts/careers_module';
import trashcanBeige from 'vectors/trashcan_beige.svg';

angularModule.factory('EducationExperienceDetailHelper', [
    '$injector',
    $injector => {
        const TranslationHelper = $injector.get('TranslationHelper');
        const EducationExperience = $injector.get('EducationExperience');

        let degreeOptions;
        const translationHelper = new TranslationHelper('careers.edit_career_profile.education_experience_detail');
        const yearOptions = [];
        for (let j = new Date().getUTCFullYear() + 5; j >= 1940; j--) {
            yearOptions.push(j);
        }

        return {
            trashcanBeige,
            graduateOptions: [
                {
                    value: true,
                    stringValue: 'true',
                    label: translationHelper.get('yes'),
                },
                {
                    value: false,
                    stringValue: 'false',
                    label: translationHelper.get('no'),
                },
            ],
            // NOTE: this seems incorrect, but is not. These options are used
            // for educationExperience.willNotComplete. Selecting "yes" means
            // the program is or will be complete, hence the false value.
            // The opposite is true when selecting "no".
            completeOptions: [
                {
                    value: false,
                    stringValue: 'true',
                    label: translationHelper.get('yes'),
                },
                {
                    value: true,
                    stringValue: 'false',
                    label: translationHelper.get('no'),
                },
            ],
            yearOptions,
            degreeOptions: ngModel => {
                if (!degreeOptions) {
                    degreeOptions = EducationExperience.degreeOptions
                        .map(translationKey => {
                            const fullStringKey = `${translationKey.replace(/_[0-9]$/, '')}_full`;
                            const fullString = translationHelper.get(fullStringKey);
                            const shortString = translationHelper.get(translationKey);
                            return {
                                sort: fullString,
                                value: translationKey,
                                label: `${shortString} (${fullString})`,
                                // allows searching for shortened string without needing the periods
                                search: shortString.split('.').join(''),
                            };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label, translationHelper.getCurrentLanguage()));

                    // find any user-provided options that aren't in the existing list and allow them to be visible
                    const additionalOptions = _.chain(ngModel).map('degree').difference(degreeOptions).uniq().value();

                    additionalOptions.forEach((option, i) => {
                        if (!option) {
                            return;
                        }

                        degreeOptions.push({
                            sort: degreeOptions.length + i,
                            value: option,
                            label: option,
                            search: option.split('.').join(''),
                        });
                    });
                }

                return degreeOptions;
            },
            resetGraduateState: (educationExperience, resetYears = false) => {
                // In the past we had a question that set will_not_complete for both degree
                // and non-degree experiences. However, for degree experiences we broke that
                // question into two pieces that now set has_graduated and / or will_graduate.
                if (educationExperience.has_graduated || educationExperience.will_graduate) {
                    educationExperience.will_not_complete = false;
                } else if (educationExperience.has_graduated === false && educationExperience.will_graduate === false) {
                    educationExperience.will_not_complete = true;
                }

                // Undo the will_graduate value if a user decides to answer yes to has_graduated,
                // since both being true doesn't make sense. Be sure to use null instead of
                // undefined so the server will get the value, in case the user had already saved.
                // We have a server validation that ensures both can't be true.
                if (educationExperience.has_graduated) {
                    educationExperience.will_graduate = null;
                }

                if (resetYears) {
                    // It felt like a better UX experience to reset the years when changing the context of the question
                    educationExperience.start_year = null;
                    educationExperience.graduation_year = null;
                }
            },
        };
    },
]);
