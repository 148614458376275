import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('PeriodAction', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const guid = $injector.get('guid');
        const IsPeriodItem = $injector.get('IsPeriodItem');

        return Iguana.subclass(function () {
            this.alias('PeriodAction');
            this.embeddedIn('period');
            this.include(IsPeriodItem);

            this.VALID_TYPES = ['expulsion_warning', 'graduation_status'];

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.id = this.id || guid.generate();
                this.type = this.type || 'expulsion_warning';
                this.days_offset_from_end = this.days_offset_from_end || -1;
            });

            Object.defineProperty(this.prototype, 'date', {
                get() {
                    return this._getCachedRelativeDateFromPeriodEnd('date', 'days_offset_from_end');
                },
                set(date) {
                    this.days_offset_from_end = this._getRelativeDayOffsetFromPeriodEnd(date);
                    return date;
                },
            });

            return {
                remove() {
                    this.period().actions = _.without(this.period().actions, this);
                },
            };
        });
    },
]);
