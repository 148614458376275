import angularModule from 'Careers/angularModule/scripts/careers_module';
import 'FrontRoyalUiBootstrap/popover';
import template from 'Careers/angularModule/views/edit_career_profile/education_experience_detail.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getPendingOrSuccessAoiRecord } from 'Users';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('educationExperienceDetail', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const EducationExperienceDetailHelper = $injector.get('EducationExperienceDetailHelper');
        const EducationExperienceDetailMixin = $injector.get('EducationExperienceDetailMixin');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                degreeProgram: '<',
            },
            require: '?^form',
            templateUrl,
            link(scope, elem, attrs, formController) {
                scope.helper = EducationExperienceDetailHelper;
                EducationExperienceDetailMixin.onLink(scope, elem, attrs, formController);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.$watchCollection('ngModel', () => {
                    scope.educationExperiences = _.filter(
                        scope.ngModel,
                        experience => experience.degreeProgram === scope.degreeProgram,
                    );

                    getButtonKeys();

                    if (
                        scope.degreeProgram &&
                        scope.currentUser?.career_profile?.indicatesUserShouldProvideTranscripts &&
                        getPendingOrSuccessAoiRecord(scope.currentUser)
                    ) {
                        scope.educationExperiences.forEach(experience => {
                            experience.$$disableEdit = experience.canRequireOfficial;
                        });
                    }
                });

                function getButtonKeys() {
                    // If this is within the Formal Degree context, always
                    // display 'Add Another Degree', because we pre-create
                    // an empty experience if there are none to being with.
                    if (scope.degreeProgram) {
                        scope.addButtonKey =
                            scope.educationExperiences && scope.educationExperiences.length >= 1
                                ? 'careers.edit_career_profile.education_experience_detail.add_degree'
                                : 'careers.edit_career_profile.education_experience_detail.add_degree_first';
                    } else {
                        // If we have no non-degree programs, display 'Add a Program'
                        // If we have 1 or more non-degree programs, display 'Add Another Program'
                        scope.addButtonKey =
                            scope.educationExperiences && scope.educationExperiences.length >= 1
                                ? 'careers.edit_career_profile.education_experience_detail.add_education'
                                : 'careers.edit_career_profile.education_experience_detail.add_education_first';
                    }
                }
            },
        };
    },
]);
