import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('CohortPromotion', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('cohort_promotions');
            this.alias('CohortPromotion');
            this.setIdProperty('id');
        });
    },
]);
