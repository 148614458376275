import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/profile_photo_form_section.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('profilePhotoFormSection', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                user: '<',
                careerProfile: '<',
                disableLinkedin: '<?',
                isRequired: '<?',
            },
            link(scope) {
                scope.isRequired = angular.isDefined(scope.isRequired) ? scope.isRequired : true;

                // The normal messaging for the Profile Photo field mentions the user's student profile
                // and their potential classmates; however, since CNO users technically aren't students,
                // they don't have a student profile and they don't have classmates, so we show them a
                // slightly different message.
                if (scope.user.programType === 'career_network_only') {
                    scope.avatarUploadInstructionsKey =
                        'careers.edit_career_profile.profile_photo_form_section.upload_avatar_desc_alt';
                    scope.avatarUploadSubTextKey =
                        'careers.edit_career_profile.profile_photo_form_section.upload_avatar_note_alt';
                } else {
                    scope.avatarUploadInstructionsKey =
                        'careers.edit_career_profile.profile_photo_form_section.upload_avatar_desc_classmates_only';
                    scope.avatarUploadSubTextKey =
                        'careers.edit_career_profile.profile_photo_form_section.upload_avatar_note_classmates_only';
                }
            },
        };
    },
]);
