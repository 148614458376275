// This defines the subset of Cohort attributes that are used in CohortForAdminListsAttrs,
// which is used when we need a list of all the cohorts with just the basic information about them.

import { type ProgramType } from 'Program';

export interface CohortSection {
    identifier: string;
    id: string;
    sectionType: CohortSectionType;
}

export enum CohortSectionType {
    Default = 'default',
    Regional = 'regional',
}

export interface SlackRoom {
    id: string;
    name: string;
    title: string;
}

// Anything in this list needs to be included in COHORT_FOR_ADMIN_LISTS_ATTRS
export type CohortForAdminListsAttrs = {
    id: string;
    name: string;
    programType: ProgramType;
    startDate: number;
    endDate: number;
    startOfAdmissionCycle: number;
    studentNetworkActivationDate: number;
    graduationDate: number;
    graduationDaysOffsetFromEnd: number;
    cohortSections: CohortSection[];
    slackRooms: SlackRoom[];
    availableScholarshipIds: string[];
    availableTuitionPlanIds: string[];
    isolatedNetwork: boolean;
};
