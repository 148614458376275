
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.client_config = window.Smartly.locales.modules.en.back_royal.client_config || {};
window.Smartly.locales.modules.en.back_royal.client_config.client_config = window.Smartly.locales.modules.en.back_royal.client_config.client_config || {};
window.Smartly.locales.modules.en.back_royal.client_config.client_config = {...window.Smartly.locales.modules.en.back_royal.client_config.client_config, ...{
    "native_upgrade_button_text": "Update",
    "native_upgrade_instructions": "Click the button below to get the latest version.",
    "web_upgrade_button_text": "Refresh",
    "web_upgrade_instructions": "To get the new version, please refresh your browser."
}
}
    