import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/career_profile_form_select_field.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('careerProfileFormSelectField', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                careerProfile: '<',
                hideBottomBorder: '<?',
                isRequired: '<?',
                isDisabled: '<?',
                name: '@',
                labelLocale: '@',
                placeholderLocale: '@',
                options: '<',
            },
            link(scope) {
                scope.isRequired = angular.isDefined(scope.isRequired) ? scope.isRequired : true;
                scope.hideBottomBorder = angular.isDefined(scope.hideBottomBorder) ? scope.hideBottomBorder : false;
                scope.placeholder = `careers.edit_career_profile.${scope.placeholderLocale}`;
            },
        };
    },
]);
