
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.cohort_sections = window.Smartly.locales.modules.en.back_royal.cohort_sections || {};
window.Smartly.locales.modules.en.back_royal.cohort_sections = {...window.Smartly.locales.modules.en.back_royal.cohort_sections, ...{
    "global_short": "Global",
    "global_long": "Global",
    "s_se_asia_short": "S/SE Asia",
    "s_se_asia_long": "S/SE Asia",
    "africa_short": "Africa",
    "africa_long": "Africa"
}
}
    