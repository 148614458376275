import 'Capabilities/angularModule';
import 'ClickKey/angularModule';
import 'ClientStorage/angularModule';
import 'Compile/angularModule';
import 'DialogModal/angularModule';
import 'FormatsText/angularModule';
import 'Translation/angularModule';
import 'EventLogger/angularModule';

export default angular.module('FrontRoyal.ClientConfig', [
    'Translation',
    'DialogModal',
    'FormatsText',
    'compileDir',
    'Capabilities',
    'ClickKey',
    'ClientStorage',
    'EventLogger',
]);
