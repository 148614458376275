
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.program_preferences_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.program_preferences_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.program_preferences_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.program_preferences_form, ...{
    "quantic_generic_offerings_description": "Quantic offers a traditional MBA and Executive MBA curriculum with a somewhat greater emphasis on analytics and quantitative reasoning.",
    "valar_generic_offerings_description": "Valar offers a traditional MBA and Executive MBA curriculum with a slightly greater emphasis on people management and leadership.",
    "only_valar_generic_offerings_description": "<p>Valar’s MBA degree is designed for high potential learners, typically with up to seven years of full time work experience.</p><p>Valar’s Executive MBA has a greater emphasis on people management and leadership, and caters to accomplished professionals with over seven years of experience.</p>",
    "error_please_complete_earlier_questions": "Please complete all questions on the <a ng-click=\"loadRoute('/settings/application?page=1')\">Basic Info step</a> before moving forward with your application.",
    "minimum_education_requirements": "Minimum Education Requirements",
    "minimum_education_requirements_desc": "<p>Due to US accreditation requirements, all {{brandName}} students must hold a bachelor’s degree or its international equivalent.</p><p>We are unable to consider you for admission to the MBA or Executive MBA program at this time.</p><p>Please review your <a ng-click=\"loadRoute('/settings/application?page=1')\">highest level of completed education</a> if you believe this to be in error.</p>",
    "mba": "MBA",
    "considered_for_mba": "Based on a cursory review of your reported career experience, you will be registered as an <strong>MBA applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_mba_desc": "Valar Institute, a division of Quantic, offers an MBA on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "mba_and_emba": "MBA & Executive MBA",
    "mba_or_emba": "MBA or Executive MBA",
    "registered_as_one_but_considered_for_both": "Based on a cursory review of your reported career experience, you will be registered as an <strong>{{registeredAs}} applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_mba_and_emba_desc_mba_default": "Valar Institute, a division of Quantic, offers MBA and Executive MBA degrees on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "quantic_mba_and_emba_desc_emba_default": "Based on a cursory review of your reported career experience, you will be registered as an <strong>Executive MBA applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_mba_and_emba_desc_emba_default": "Valar Institute, a division of Quantic, offers MBA and Executive MBA degrees on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "emba": "Executive MBA",
    "considered_for_emba": "Based on a cursory review of your reported career experience, you will be registered as an <strong>Executive MBA applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_emba_desc": "Valar Institute, a division of Quantic, offers an Executive MBA on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "valar_opt_in_agreement": "I would like to be in consideration for both Valar and Quantic degrees (recommended).",
    "interview_pref": "Admissions Interview",
    "interview_pref_question": "Over the coming weeks, you may be invited to a brief interview with a member of our admissions team. If so, do you have a preference on availability?",
    "interview_pref_answer_weekdays": "Weekdays",
    "interview_pref_answer_weekend": "Weekend",
    "interview_pref_answer_no_pref": "No preference",
    "minimum_education_requirements_desc_generic": "<p>Due to US accreditation requirements, all {{brandName}} students must hold a bachelor’s degree or its international equivalent.</p><p>We are unable to consider you for admission to the {{programTitle}} program at this time.</p><p>Please review your <a ng-click=\"loadRoute('/settings/application?page=1')\">highest level of completed education</a> if you believe this to be in error.</p>",
    "other": "{{programTitle}}"
}
}
    