import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/thanks_for_applying_modal.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupBrandNameProperties } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('thanksForApplyingModal', [
    '$injector',
    function factory($injector) {
        const DialogModal = $injector.get('DialogModal');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,

            link(scope) {
                setupBrandNameProperties($injector, scope);
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.action = () => {
                    DialogModal.hideAlerts();
                };
            },
        };
    },
]);
