import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';
import moment from 'moment-timezone';

angularModule.factory('IsPeriodItem', [
    '$injector',
    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const dateHelper = $injector.get('dateHelper');

        return new AModuleAbove({
            _getCachedRelativeDateFromPeriodEnd(name, offsetProp, unit = 'days') {
                const cacheKey = `$$cached${name}`;

                // if nothing has changed, return the last date we calculated
                if (
                    this[cacheKey] &&
                    this[cacheKey].endDate === this.period().endDate &&
                    this[cacheKey][offsetProp] === this[offsetProp]
                ) {
                    return this[cacheKey].date;
                }

                const date = dateHelper.addInDefaultTimeZone(this.period().endDate, this[offsetProp], unit);

                this[cacheKey] = {
                    endDate: this.period().endDate,
                    date,
                };
                this[cacheKey][offsetProp] = this[offsetProp];

                return date;
            },

            _getRelativeDayOffsetFromPeriodEnd(date) {
                if (date === null) {
                    throw new Error('null Not supported');
                }

                const endDay = moment(this.period().endDate).startOf('day');

                // The endDate of the period is in ADMIN_REF_TIMEZONE so change date value to match, otherwise
                // the diff calculation will be off.
                const day = moment(date).startOf('day');

                return -Math.floor(endDay.diff(day, 'days'));
            },

            _getRelativeHourOffsetFromPeriodEnd(date) {
                if (date === null) {
                    throw new Error('null Not supported');
                }

                return -Math.floor(moment(this.period().endDate).diff(date, 'hours'));
            },
        });
    },
]);
