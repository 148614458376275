import angularModule from 'ClientConfig/angularModule/scripts/client_config_module';
import template from 'ClientConfig/angularModule/views/upgrade_client.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('upgradeClient', [
    '$injector',

    function factory($injector) {
        const ClientConfig = $injector.get('ClientConfig');
        const EventLogger = $injector.get('EventLogger');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                message: '<',
                buttonText: '<?',
                showUpgradeInstructions: '<?',
            },
            link(scope, _, attrs) {
                // eslint-disable-next-line no-multi-assign
                const clientConfig = (scope.clientConfig = ClientConfig.current);
                scope.buttonText = scope.buttonText || clientConfig.upgradeButtonText;
                if (scope.showUpgradeInstructions !== false) {
                    scope.showUpgradeInstructions = true;
                }

                scope.onClickButton = () => {
                    if (attrs.buttonAction) {
                        scope.$parent.$eval(attrs.buttonAction);
                    } else {
                        clientConfig.upgrade(EventLogger);
                    }
                };
            },
        };
    },
]);
