import angularModule from 'Careers/angularModule/scripts/careers_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Careers/angularModule/views/preview_profile_status_message.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import {
    getProgramApplication,
    getHasOfferOrIncludedOrCompleted,
    getIsNotJoiningProgramOrHasBeenExpelled,
} from 'Users';
import { isPendingProgramApplication } from 'ProgramApplication';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('previewProfileStatusMessage', [
    '$injector',
    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const $rootScope = $injector.get('$rootScope');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            scope: {
                // Certain locales make reference to the blue dots displayed in the progress-nav,
                // but if the progress-nav isn't being shown in the UI, then we don't want to use
                // those locales, so this directive needs to know if the progress-nav is being shown
                // in the UI so that it can display the appropriate locale to the user.
                withProgressNav: '<?',
            },
            templateUrl,

            link(scope) {
                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);

                // convenience getter
                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // FIXME: Should we create a specific brandName config property to use here, to avoid having
                // to sprinkle isQuantic logic outside of the AppBrandConfig?
                scope.isQuantic = ConfigFactory.getSync().isQuantic();

                scope.withProgressNav = scope.withProgressNav || false;

                // need to be responsive to push messages (i.e.: if we're accepted, rejected, etc. by an admin)
                // and changes to the status fields
                scope.$watchGroup(
                    [
                        'currentUser.can_edit_career_profile',
                        'currentUser.career_profile.completedAt',
                        () => getIsNotJoiningProgramOrHasBeenExpelled(scope.currentUser),
                        () => getHasOfferOrIncludedOrCompleted(scope.currentUser),
                        () => isPendingProgramApplication(getProgramApplication(scope.currentUser)),
                    ],
                    () => {
                        // be defensive
                        if (!scope.currentUser?.career_profile) {
                            return;
                        }

                        // Student Network only -- Career Network is retired.
                        // Profile status message only visible in Profile editor, which is only accessible if currentUser.hasEditCareerProfileAccess OR currentUser.hasAdminAccess

                        /*
                            The `user.can_edit_career_profile` attribute is weird. It is set to true when an included user's cohort content is unlocked (see SetCanEditCareerProfileAfterAcceptanceJob),
                            but it's a bit of a misnomer because the current UI logic allows users to access and edit their profile when they are offered admission,
                            even if `can_edit_career_profile` is `false`. But, in case an admin manually sets `can_edit_career_profile` to `true` for an unaccepted user (pending/rejected/expelled),
                            thereby giving them access to edit their profile, we have cases here to provide a status message for those users.
                        */
                        const canEditCareerProfile = scope.currentUser.can_edit_career_profile;
                        const offeredOrIncludedOrComplete = getHasOfferOrIncludedOrCompleted(scope.currentUser);
                        const isPending = isPendingProgramApplication(getProgramApplication(scope.currentUser));
                        const notJoiningOrExpelled = getIsNotJoiningProgramOrHasBeenExpelled(scope.currentUser);
                        const careerProfileComplete = !!scope.currentUser.career_profile.completedAt;

                        if (offeredOrIncludedOrComplete && careerProfileComplete) {
                            scope.status = canEditCareerProfile ? null : 'reviewing_student_network';
                        } else if (offeredOrIncludedOrComplete && !careerProfileComplete) {
                            scope.status = scope.withProgressNav
                                ? 'accepted_degree_incomplete_profile' // references blue dots
                                : 'accepted_degree_incomplete_profile_alt';
                        } else if (notJoiningOrExpelled) {
                            scope.status = 'no_student_network_access';
                        } else if (isPending) {
                            scope.status = 'pending_student_network';
                        } else {
                            scope.status = null;
                        }
                    },
                );
            },
        };
    },
]);
