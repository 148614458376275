import { formatNameAndStartDate, type CohortForAdminListsAttrs } from 'Cohorts';
import { TaggedSelectOption, type TaggedSelectOptionTag } from 'FrontRoyalMaterialUiForm';

type Props = {
    cohort: CohortForAdminListsAttrs;
    tags: TaggedSelectOptionTag[];
};

export function CohortSelectOption({ cohort, tags }: Props) {
    return <TaggedSelectOption label={formatNameAndStartDate(cohort)} tags={tags} />;
}
