import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/employer_funding_section.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupBrandNameProperties } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('employerFundingSection', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            scope: {
                careerProfile: '<',
            },
            templateUrl,
            link(scope) {
                setupBrandNameProperties($injector, scope);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true,
                });

                // Note: If you change this list also see the "Employer Funding Eligibility" Airtable column
                scope.employer_funding_options = ['eligible', 'not_eligible', 'not_sure', 'not_applicable'];
            },
        };
    },
]);
