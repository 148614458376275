import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/student_network_settings_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('studentNetworkSettingsForm', [
    '$injector',

    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                scope.helper = EditCareerProfileHelper;
                scope.helper.logEventForApplication('viewed-student_network_settings');

                FormHelper.supportForm(scope, scope.student_network_settings);

                const translationHelper = new TranslationHelper(
                    'careers.edit_career_profile.student_network_settings_form',
                );

                scope.isNonEnglish = FormHelper.isNonEnglish();

                if (scope.currentUser.programType === 'career_network_only') {
                    scope.funFactDescKey = `careers.edit_career_profile.student_network_settings_form.fun_fact_desc${
                        scope.isNonEnglish ? '_non_english' : ''
                    }`;
                } else {
                    scope.funFactDescKey = `careers.edit_career_profile.student_network_settings_form.fun_fact_tell_us_classmates_only${
                        scope.isNonEnglish ? '_non_english' : ''
                    }`;
                }

                scope.placeholderFact = translationHelper.get('fun_fact_placeholder');

                Object.defineProperty(scope, 'studentNetworkEmailRequired', {
                    get() {
                        return (
                            scope.careerProfile.pref_student_network_privacy === 'full' &&
                            scope.currentUser.hasAccessToFullStudentNetworkProfiles &&
                            !scope.currentUser.isEmailValidForStudentNetwork
                        );
                    },
                });

                const initialInterests = _.clone(scope.careerProfile.student_network_interests);

                scope.$watch('careerProfile.student_network_interests.length', (newLength, oldLength) => {
                    // if we're resetting the careerProfile after the form was dirtied, we don't want to re-dirty
                    if (
                        oldLength !== newLength &&
                        !_.isEqual(initialInterests, scope.careerProfile.student_network_interests)
                    ) {
                        scope.student_network_settings.$setDirty(true);
                    }
                });

                scope.lookingForOptions = $injector.get('STUDENT_NETWORK_LOOKING_FOR_KEYS');
                scope.privacyOptions = ['full', 'anonymous', 'hidden'];

                const propsValuesMap = {
                    student_network_looking_for: scope.lookingForOptions,
                };
                FormHelper.supportCheckboxGroups(scope, scope.careerProfile, propsValuesMap);
            },
        };
    },
]);
