import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('AdmissionRound', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const SupportsRelativeDates = $injector.get('SupportsRelativeDates');

        return Iguana.subclass(function () {
            this.alias('AdmissionRound');
            this.embeddedIn('schedulableItem');
            this.include(SupportsRelativeDates);

            Object.defineProperty(this.prototype, 'applicationDeadline', {
                get() {
                    return this._getCachedRelativeDate('applicationDeadline', 'application_deadline_days_offset');
                },
                set(date) {
                    const val = this._getRelativeDayOffset(date);

                    if (
                        this.schedulableItem().ensureLessThanOrEqualTo(
                            val,
                            this.decision_date_days_offset,
                            'Application deadline must be before decision date',
                        )
                    ) {
                        this.application_deadline_days_offset = val;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'decisionDate', {
                get() {
                    return this._getCachedRelativeDate('decisionDate', 'decision_date_days_offset');
                },
                set(date) {
                    // decision date cannot be before the application deadline
                    const val = this._getRelativeDayOffset(date);
                    if (
                        !this.schedulableItem().ensureGreaterThanOrEqualTo(
                            val,
                            this.application_deadline_days_offset,
                            'Decision date must be after application deadline',
                        )
                    ) {
                        return;
                    }

                    if (
                        !this.schedulableItem().ensureLessThanOrEqualTo(
                            val,
                            0,
                            'Decision date must be before schedule start date',
                        )
                    ) {
                        return;
                    }

                    this.decision_date_days_offset = val;
                },
                configurable: true,
            });

            Object.defineProperty(this.prototype, 'index', {
                get() {
                    return this.schedulableItem().admission_rounds.indexOf(this);
                },
            });

            Object.defineProperty(this.prototype, '_startDateForRelativeDates', {
                get() {
                    return this.schedulableItem().startDate;
                },
                configurable: true,
            });

            return {
                remove() {
                    this.schedulableItem().admission_rounds = _.without(this.schedulableItem().admission_rounds, this);
                },
            };
        });
    },
]);
