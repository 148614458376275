
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.tuition_and_scholarships_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.tuition_and_scholarships_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.tuition_and_scholarships_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.tuition_and_scholarships_form, ...{
               
    "decline_scholarships": "Decline Scholarships",
    "decline_scholarships_option_desc": "With many deserving global students and limited awards, we ask candidates with sufficient resources to consider declining scholarship consideration.",
    "apply_for_merit_and_need_scholarships": "Apply for Merit and Need Scholarships",
    "decline_or_apply_for_scholarships": "Decline or Apply for Scholarships",
    "scholarship_eligibility": "Scholarship Eligibility",
    "scholarship_eligibility_sub_heading": "Select all scholarships you are interested in.",

    "merit": "Merit",
    "need": "Need",

                   
    "merit_desc": "Merit-based award for demonstrated academic performance and career achievement in key global cities, industries, and roles.",
    "alumni_desc": "As a Quantic nominee you have been pre-approved for this $4,500 USD award if admitted to the program",
    "employer_partner_desc": "You will receive this scholarship if you are an employee or member of one of our employer or association partners.",
    "impact_desc": "Merit-based award on the basis of Diversity, Equity, and Inclusion (DEI), social impact initiatives, and leadership potential.",
    "recruitment_desc": "Offered to students interested in hearing about jobs at our employer partners. There is no obligation to respond to these opportunities.",
    "need_desc": "Please select this only if in financial hardship or in a global region with economic disadvantages. {{instNameShort}} is committed to broadening access to education worldwide with these limited funds.",

           
    "need_statement_desc": "Please outline any special circumstances to support your request for financial aid.",
    "confirm": "Confirm",
    "post_decline_modal_body": "You are declining scholarship consideration. This supports the exceptional global students who rely on these limited funds to enroll.",
    "post_decline_modal_close_and_select": "Close and Select Scholarships",
    "admission_not_affected_by_scholarship_applications": "Chances of admission are not affected by scholarship applications.",
    "scholarship_eligibility_desc": "After submitting this application, you will gain access to 6 free courses in your {{brandNameShort}} dashboard. They showcase our interactive learning and become part of the full degree. <strong>You will need to complete at least one to be eligible for scholarships.</strong>"
}
}
    