
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_experience_detail = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_experience_detail || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_experience_detail = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_experience_detail, ...{
    "enter_responsibilities": "Please enter between 1 and 3 responsibilities or achievements (max 120 characters):",
    "enter_responsibilities_non_english": "Please enter between 1 and 3 responsibilities or achievements, in English (max 120 characters):",
    "add_employment": "Add Another Work Experience",
    "add_employment_first": "Add a Work Experience",
    "organization": "Organization",
    "job_title": "Job Title",
    "start": "Start",
    "end": "End",
    "responsibilities_placeholder": "Launched the social media effort for the digital marketing team.",
    "change_from_full_time": "Move to Part Time Experiences",
    "change_from_part_time": "Move to Full Time Experiences"
}
}
    