import angularModule from 'Careers/angularModule/scripts/careers_module';
import template from 'Careers/angularModule/views/edit_career_profile/tuition_and_scholarships_form.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import scholarshipConfigs from 'Scholarship/ScholarshipConfigs';
import { ScholarshipCategory } from 'Scholarship';
import { setupBrandScopeProperties } from 'AppBranding';
import { getCohort } from 'Users';
import { ProgramTypeConfigs } from 'Program';

const templateUrl = cacheAngularTemplate(angularModule, template);
const stepName = 'tuition_and_scholarships';

angularModule.directive('tuitionAndScholarshipsForm', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const EditCareerProfileHelper = $injector.get('EditCareerProfileHelper');
        const DialogModal = $injector.get('DialogModal');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('careers.edit_career_profile.tuition_and_scholarships_form');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            link(scope) {
                // Setup
                FormHelper.supportForm(scope, scope.tuition_and_scholarships);

                // Set initial scope values
                Object.defineProperty(scope, 'programTypeConfig', {
                    get() {
                        const cohort = getCohort($rootScope.currentUser);
                        return ProgramTypeConfigs[cohort?.programType];
                    },
                    configurable: true,
                });

                setupBrandScopeProperties($injector, scope, ['brandNameShort']);
                scope.showScholarshipOptions = false;

                // Set 'Save' button interceptor to show confirmation modal if no scholarships selected
                scope.setSaveClickInterceptorForStep(stepName, () => scope.showAppropriateModalIfNecessary('save'));

                // Set 'Save and Next' button interceptor to show confirmation modal if no scholarships selected
                scope.setSaveNextClickInterceptorForStep(stepName, () =>
                    scope.showAppropriateModalIfNecessary('saveAndNext'),
                );

                // Watch radio button selection
                scope.$watch('programFamilyFormDatum.declineScholarshipConsideration', val => {
                    const saveDisabled = [null, undefined].includes(val);
                    scope.setDisableSaveForStep(stepName, saveDisabled);
                    scope.showScholarshipOptions = val === false;
                });

                // Build data for form and form control
                const { alumni, need, ...rest } = scholarshipConfigs($injector, 'application');

                if (scope.currentUser?.isNominee) {
                    scope.scholarshipConfigs = { alumni, need };
                } else {
                    scope.scholarshipConfigs = { need, ...rest };
                }

                scope.helper = EditCareerProfileHelper;
                scope.helper.logEventForApplication('viewed-tuition_and_scholarships');

                scope.shortAnswerMinChars = 400;
                scope.shortAnswerMaxChars = 1000;

                // Support scholarship checkboxes
                const scholarshipValues = Object.values(ScholarshipCategory);
                const propsValuesMap = { scholarshipLabelsInterestedIn: scholarshipValues };
                const propsRequiredFieldsMap = {
                    declineScholarshipConsideration: true,
                };
                const propsDefaultAndDominantValuesMap = {
                    scholarshipLabelsInterestedIn: scope.programFamilyFormDatum.scholarshipLabelsInterestedIn || [],
                };

                FormHelper.supportCheckboxGroups(
                    scope,
                    scope.programFamilyFormDatum,
                    propsValuesMap,
                    propsRequiredFieldsMap,
                    propsDefaultAndDominantValuesMap,
                );

                // If reapplying, a user could have been previously interested in a scholarship we no longer
                // have, so we make sure to filter out values not in ScholarshipCategory
                const currentInterests = scope.programFamilyFormDatum.scholarshipLabelsInterestedIn;
                if (currentInterests?.length) {
                    scope.programFamilyFormDatum.scholarshipLabelsInterestedIn = currentInterests.filter(val =>
                        scholarshipValues.includes(val),
                    );
                }

                scope.unsetScholarshipSelections = () => {
                    DialogModal.hideAlerts();
                    scope.checkboxGroupsFauxModels.scholarshipLabelsInterestedIn = [];
                    scope.programFamilyFormDatum.scholarshipLabelsInterestedIn = [];
                    scope.programFamilyFormDatum.shortAnswerScholarship = null;
                    scope.programFamilyFormDatum.declineScholarshipConsideration = true;
                    scope.setDisableSaveForStep(stepName, false);
                };

                /** MODALS  */
                scope.showAppropriateModalIfNecessary = action => {
                    if (scope.programFamilyFormDatum.declineScholarshipConsideration) {
                        scope.unsetScholarshipSelections();
                        scope.executeSaveAction(action);
                    } else if (
                        !scope.programFamilyFormDatum.declineScholarshipConsideration &&
                        !scope.programFamilyFormDatum.scholarshipLabelsInterestedIn.length
                    ) {
                        scope.showNoSelectionModal(action);
                    } else {
                        scope.executeSaveAction(action);
                    }
                };

                scope.executeSaveAction = action => {
                    if (action === 'saveAndNext') {
                        scope.saveAndNext(scope.$nextStep);
                    } else {
                        scope.save();
                    }
                };

                /** The modal that shows if the user views the scholarships, selects none, and then saves */
                scope.showNoSelectionModal = action => {
                    DialogModal.alert({
                        classes: ['decline-scholarships-modal'],
                        content: `
                            <div class="decline-modal-body">
                                ${translationHelper.get('post_decline_modal_body')}

                                <div class="decline-modal-action-buttons">
                                    <button class="confirm-button btn flat blue" ng-click="handleClickConfirmDeclineScholarship()">
                                        ${translationHelper.get('confirm')}
                                    </button>

                                    <button class="close-and-review-button" ng-click="handleClickCloseAndReview()">
                                        ${translationHelper.get('post_decline_modal_close_and_select')}
                                    </button>
                                </div>
                            </div>
                        `,
                        title: '',
                        size: 'normal',
                        scope: {
                            handleClickCloseAndReview: scope.handleClickCloseAndReview,
                            handleClickConfirmDeclineScholarship: () => {
                                scope.unsetScholarshipSelections();
                                scope.executeSaveAction(action);
                            },
                        },
                    });
                };

                // Handler for the modal dismiss link
                scope.handleClickCloseAndReview = () => {
                    DialogModal.hideAlerts();
                    scope.programFamilyFormDatum.declineScholarshipConsideration = false;
                    scope.showScholarshipOptions = true;
                    scope.setDisableSaveForStep(stepName, false);
                };
            },
        };
    },
]);
