import 'angular-animate';
import 'angular-bind-html-compile';
import 'angular-multi-step-form';
import 'angular-ui-mask';
import 'ng-file-upload';
import 'ng-toast';

import 'ClientStorage/angularModule';
import 'EmailInput/angularModule';
import 'IsMobile/angularModule';
import 'ImageFadeInOnLoad/angularModule';
import 'EventLogger/angularModule';
import 'FixViewportOnBlur/angularModule';
import 'FrontRoyalApiErrorHandler/angularModule';
import 'FrontRoyalForm/angularModule';
import 'FrontRoyalUpload/angularModule';
import 'Navigation/angularModule';
import 'OnImageLoad/angularModule';
import 'ReverseFilter/angularModule';
import 'SafeApply/angularModule';
import 'ScrollHelper/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'Sortable/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Translation/angularModule';
import 'Uib-extensions/angularModule';
import 'UnloadedChangeDetector/angularModule';
import 'Countries/angularModule';
import 'Status/angularModule';

import buildings from 'vectors/buildings.svg';
import profilePhotoDefault from 'vectors/profile_photo_default.svg';

const careersModule = angular.module('FrontRoyal.Careers', [
    'FrontRoyal.ApiErrorHandler',
    'onImageLoad',
    'timeoutHelpers',
    'SiteMetadata',
    'FrontRoyal.EmailInput',
    'FrontRoyal.Form',
    'FrontRoyal.Navigation',
    'FrontRoyal.Upload',
    'FrontRoyal.Countries',
    'FrontRoyal.Status',
    'imageFadeInOnLoad',
    'ClientStorage',
    'multiStepForm',
    'ngFileUpload',
    'ngToast',
    'ui.mask',
    'reverseFilter',
    'ListOffsetHelper',
    'ngAnimate',
    'ui.bootstrap',
    'EventLogger',
    'scrollHelper',
    'Translation',
    'safeApply',
    'uibExtensions',
    'angular-bind-html-compile',
    'sortable',
    'isMobile',
    'UnloadedChangeDetector',
    'FixViewportOnBlur',
]);

// generated by running the following on the server (this gave me the
// expected order, but since it's not an ordered hash, that may
// not be guaranteed):
// puts Location.locations.map { |pair| "['#{pair[0]}', JSON.parse('#{pair[1].place_details.to_json}')]" }.join(",\n")
// NOTE: If you add anything to this list, see the server-side location model and the "Locations of Interest" Airtable column as well
const locations = [
    [
        'san_francisco',
        JSON.parse(
            '{"locality":{"short":"SF","long":"San Francisco"},"administrative_area_level_2":{"short":"San Francisco County","long":"San Francisco County"},"administrative_area_level_1":{"short":"CA","long":"California"},"country":{"short":"US","long":"United States"},"formatted_address":"San Francisco, CA, USA","utc_offset":-420,"lat":37.7749295,"lng":-122.4194155}',
        ),
    ],
    [
        'new_york',
        JSON.parse(
            '{"locality":{"short":"New York","long":"New York"},"administrative_area_level_1":{"short":"NY","long":"New York"},"country":{"short":"US","long":"United States"},"formatted_address":"New York, NY, USA","utc_offset":-240,"lat":40.7127753,"lng":-74.0059728}',
        ),
    ],
    [
        'seattle',
        JSON.parse(
            '{"locality":{"short":"Seattle","long":"Seattle"},"administrative_area_level_2":{"short":"King County","long":"King County"},"administrative_area_level_1":{"short":"WA","long":"Washington"},"country":{"short":"US","long":"United States"},"formatted_address":"Seattle, WA, USA","utc_offset":-420,"lat":47.6062095,"lng":-122.3320708}',
        ),
    ],
    [
        'denver',
        JSON.parse(
            '{"locality":{"short":"Denver","long":"Denver"},"administrative_area_level_2":{"short":"Denver County","long":"Denver County"},"administrative_area_level_1":{"short":"CO","long":"Colorado"},"country":{"short":"US","long":"United States"},"formatted_address":"Denver, CO, USA","utc_offset":-360,"lat":39.7392358,"lng":-104.990251}',
        ),
    ],
    [
        'boston',
        JSON.parse(
            '{"locality":{"short":"Boston","long":"Boston"},"administrative_area_level_2":{"short":"Suffolk County","long":"Suffolk County"},"administrative_area_level_1":{"short":"MA","long":"Massachusetts"},"country":{"short":"US","long":"United States"},"formatted_address":"Boston, MA, USA","utc_offset":-240,"lat":42.3600825,"lng":-71.0588801}',
        ),
    ],
    [
        'los_angeles',
        JSON.parse(
            '{"locality":{"short":"Los Angeles","long":"Los Angeles"},"administrative_area_level_2":{"short":"Los Angeles County","long":"Los Angeles County"},"administrative_area_level_1":{"short":"CA","long":"California"},"country":{"short":"US","long":"United States"},"formatted_address":"Los Angeles, CA, USA","utc_offset":-420,"lat":34.0522342,"lng":-118.2436849}',
        ),
    ],
    [
        'san_diego',
        JSON.parse(
            '{"locality":{"short":"San Diego","long":"San Diego"},"administrative_area_level_2":{"short":"San Diego County","long":"San Diego County"},"administrative_area_level_1":{"short":"CA","long":"California"},"country":{"short":"US","long":"United States"},"formatted_address":"San Diego, CA, USA","utc_offset":-420,"lat":32.715738,"lng":-117.1610838}',
        ),
    ],
    [
        'washington_dc',
        JSON.parse(
            '{"locality":{"short":"Washington","long":"Washington"},"administrative_area_level_2":{"short":"District of Columbia","long":"District of Columbia"},"administrative_area_level_1":{"short":"DC","long":"District of Columbia"},"country":{"short":"US","long":"United States"},"formatted_address":"Washington, DC, USA","utc_offset":-240,"lat":38.9071923,"lng":-77.0368707}',
        ),
    ],
    [
        'chicago',
        JSON.parse(
            '{"locality":{"short":"Chicago","long":"Chicago"},"administrative_area_level_2":{"short":"Cook County","long":"Cook County"},"administrative_area_level_1":{"short":"IL","long":"Illinois"},"country":{"short":"US","long":"United States"},"formatted_address":"Chicago, IL, USA","utc_offset":-300,"lat":41.8781136,"lng":-87.6297982}',
        ),
    ],
    [
        'austin',
        JSON.parse(
            '{"locality":{"short":"Austin","long":"Austin"},"administrative_area_level_2":{"short":"Travis County","long":"Travis County"},"administrative_area_level_1":{"short":"TX","long":"Texas"},"country":{"short":"US","long":"United States"},"formatted_address":"Austin, TX, USA","utc_offset":-300,"lat":30.267153,"lng":-97.7430608}',
        ),
    ],
    [
        'atlanta',
        JSON.parse(
            '{"locality":{"short":"Atlanta","long":"Atlanta"},"administrative_area_level_2":{"short":"Fulton County","long":"Fulton County"},"administrative_area_level_1":{"short":"GA","long":"Georgia"},"country":{"short":"US","long":"United States"},"formatted_address":"Atlanta, GA, USA","utc_offset":-240,"lat":33.7489954,"lng":-84.3879824}',
        ),
    ],
    [
        'dallas',
        JSON.parse(
            '{"locality":{"short":"Dallas","long":"Dallas"},"administrative_area_level_2":{"short":"Dallas County","long":"Dallas County"},"administrative_area_level_1":{"short":"TX","long":"Texas"},"country":{"short":"US","long":"United States"},"formatted_address":"Dallas, TX, USA","utc_offset":-300,"lat":32.7766642,"lng":-96.79698789999999}',
        ),
    ],
    [
        'london',
        JSON.parse(
            '{"locality":{"short":"London","long":"London"},"postal_town":{"short":"London","long":"London"},"administrative_area_level_2":{"short":"Greater London","long":"Greater London"},"administrative_area_level_1":{"short":"England","long":"England"},"country":{"short":"GB","long":"United Kingdom"},"formatted_address":"London, UK","utc_offset":60,"lat":51.5073509,"lng":-0.1277583}',
        ),
    ],
    [
        'raleigh_durham',
        JSON.parse(
            '{"neighborhood":{"short":"Umstead","long":"Umstead"},"locality":{"short":"Raleigh","long":"Raleigh"},"administrative_area_level_2":{"short":"Wake County","long":"Wake County"},"administrative_area_level_1":{"short":"NC","long":"North Carolina"},"country":{"short":"US","long":"United States"},"formatted_address":"Umstead, Raleigh, NC, USA","utc_offset":-240,"lat":35.88022059999999,"lng":-78.7532884}',
        ),
    ],
    [
        'miami',
        JSON.parse(
            '{"locality":{"short":"Miami","long":"Miami"},"administrative_area_level_2":{"short":"Miami-Dade County","long":"Miami-Dade County"},"administrative_area_level_1":{"short":"FL","long":"Florida"},"country":{"short":"US","long":"United States"},"formatted_address":"Miami, FL, USA","utc_offset":-240,"lat":25.7616798,"lng":-80.1917902}',
        ),
    ],
    [
        'houston',
        JSON.parse(
            '{"locality":{"short":"Houston","long":"Houston"},"administrative_area_level_2":{"short":"Harris County","long":"Harris County"},"administrative_area_level_1":{"short":"TX","long":"Texas"},"country":{"short":"US","long":"United States"},"formatted_address":"Houston, TX, USA","utc_offset":-300,"lat":29.7604267,"lng":-95.3698028}',
        ),
    ],
    [
        'seoul',
        JSON.parse(
            '{"locality":{"short":"Seoul","long":"Seoul"},"administrative_area_level_1":{"short":"Seoul","long":"Seoul"},"country":{"short":"KR","long":"South Korea"},"formatted_address":"Seoul, South Korea","utc_offset":540,"lat":37.566535,"lng":126.9779692}',
        ),
    ],
    [
        'singapore',
        JSON.parse(
            '{"locality":{"short":"Singapore","long":"Singapore"},"country":{"short":"SG","long":"Singapore"},"formatted_address":"Singapore","utc_offset":480,"lat":1.3553794,"lng":103.8677444}',
        ),
    ],
    [
        'dubai',
        JSON.parse(
            '{"locality":{"short":"Dubai","long":"Dubai"},"administrative_area_level_1":{"short":"Dubai","long":"Dubai"},"country":{"short":"AE","long":"United Arab Emirates"},"formatted_address":"Dubai - United Arab Emirates","utc_offset":240,"lat":25.2048493,"lng":55.2707828}',
        ),
    ],
    [
        'paris',
        JSON.parse(
            '{"locality":{"short":"Paris","long":"Paris"},"administrative_area_level_2":{"short":"Paris","long":"Paris"},"administrative_area_level_1":{"short":"Île-de-France","long":"Île-de-France"},"country":{"short":"FR","long":"France"},"formatted_address":"Paris, France","utc_offset":120,"lat":48.85661400000001,"lng":2.3522219}',
        ),
    ],
    [
        'berlin',
        JSON.parse(
            '{"locality":{"short":"Berlin","long":"Berlin"},"administrative_area_level_1":{"short":"Berlin","long":"Berlin"},"country":{"short":"DE","long":"Germany"},"formatted_address":"Berlin, Germany","utc_offset":120,"lat":52.52000659999999,"lng":13.404954}',
        ),
    ],
    [
        'hong_kong',
        JSON.parse(
            '{"country":{"short":"HK","long":"Hong Kong"},"formatted_address":"Hong Kong","utc_offset":480,"lat":22.396428,"lng":114.109497}',
        ),
    ],
    [
        'beijing',
        JSON.parse(
            '{"locality":{"short":"Beijing","long":"Beijing"},"administrative_area_level_1":{"short":"Beijing","long":"Beijing"},"country":{"short":"CN","long":"China"},"formatted_address":"Beijing, China","utc_offset":480,"lat":39.90419989999999,"lng":116.4073963}',
        ),
    ],
    [
        'shanghai',
        JSON.parse(
            '{"locality":{"short":"Shanghai","long":"Shanghai"},"administrative_area_level_1":{"short":"Shanghai","long":"Shanghai"},"country":{"short":"CN","long":"China"},"formatted_address":"Shanghai, China","utc_offset":480,"lat":31.2303904,"lng":121.4737021}',
        ),
    ],
    [
        'tokyo',
        JSON.parse(
            '{"colloquial_area":{"short":"Tokyo","long":"Tokyo"},"administrative_area_level_1":{"short":"Tokyo","long":"Tokyo"},"country":{"short":"JP","long":"Japan"},"formatted_address":"Tokyo, Japan","utc_offset":540,"lat":35.7090259,"lng":139.7319925}',
        ),
    ],
];

const locationPlaceDetails = {};

_.forEach(locations, pair => {
    locationPlaceDetails[pair[0]] = pair[1];
});

// Note: These are legacy keys. They are only shown to folks who have the
// legacy less_than_40000 value selected on their career profile.
// As it stands, someone with this legacy value could re-apply. In which case, we
// need to support it in the UI, our Estimated Value logic, and FB conversion events.
// If we decide that supporting this legacy value is a burden, we could get
// clever and clear out the legacy values during the (re)application process.
const salaryBaseRangeKeysLegacy = [
    'less_than_40000',
    '40000_to_49999',
    '50000_to_59999',
    '60000_to_69999',
    '70000_to_79999',
    '80000_to_89999',
    '90000_to_99999',
    '100000_to_119999',
    '120000_to_149999',
    '150000_to_199999',
    'over_200000',
];

// Note: If you change this list also see the "Salary Range" Airtable column,
// the dynamic landing pages, as well as the preSignupValues service.
const salaryBaseRangeKeys = [
    'less_than_20000',
    '20000_to_29999',
    '30000_to_39999',
    '40000_to_49999',
    '50000_to_59999',
    '60000_to_69999',
    '70000_to_79999',
    '80000_to_89999',
    '90000_to_99999',
    '100000_to_119999',
    '120000_to_149999',
    '150000_to_199999',
    'over_200000',
];

careersModule

    .constant('STUDENT_NETWORK_LOOKING_FOR_KEYS', [
        'co_founder',
        'study_partner',
        'collaborators_talent',
        'new_friends',
        'ideas_inspiration',
        'job_opportunities',
        'mentorship',
        'funding',
    ])

    .constant('STUDENT_NETWORK_INTEREST_KEYS', {
        popular_interests: [
            'travel',
            'finance',
            'entrepreneurship',
            'art',
            'education',
            'marketing',
            'health_care',
            'nonprofit',
            'sports',
            'technology',
            'data_science',
            'blockchain',
            'product_management',
            'design',
            'sales',
            'leadership',
            'mixology',
            'ai',
        ],
    })

    .constant('CAREERS_FULL_TIME_EXPERIENCE_KEYS', ['0_1', '2_6', '7_10', '11_15', '16_plus'])

    .constant('CAREERS_RECENT_ROLE_KEYS', [
        'senior_management_role',
        'management_role',
        'professional_role',
        'service_role',
        'retail_role',
    ])

    .constant('CAREERS_HIGHEST_EDUCATION_KEYS', [
        'doctorate',
        'masters',
        'undergraduate_bachelor',
        'associates',
        'high_school',
    ])

    // NOTE: If you change this list, see the "Primary Areas of Interest" Airtable column.
    .constant('CAREERS_AREA_KEYS', {
        data_analysis: undefined,
        consultant: undefined,
        data_science: undefined,
        design: ['ux_ui', 'art_creative', 'graphic_design'],
        engineering: [
            'software_dev_front_end',
            'software_dev_back_end',
            'software_dev_full_stack',
            'qa',
            'devops',
            'hardware',
            'aerospace',
            'biomedical',
            'chemical',
            'civil',
            'mechanical',
        ],
        finance: [
            'corporate_banking',
            'investment',
            'trading',
            'fpa_accounting_audit',
            'wealth_management',
            'risk_audit',
        ],
        information_technology: undefined,
        general_management: [
            'senior_management',
            'operations',
            'human_resources',
            'legal',
            'administrative',
            'logistics_supply_chain',
        ],
        marketing: undefined,
        medical_services: undefined,
        product_management: undefined,
        research: undefined,
        sales: ['account_executive', 'account_manager', 'customer_success', 'sales_engineer', 'sales_development_rep'],
        teaching_education: undefined,
        writing: undefined,
        other: undefined,
    })

    .constant('CAREERS_INDUSTRY_KEYS', [
        'consulting',
        'consumer_products',
        'education',
        'energy',
        'financial',
        'health_care',
        'legal',
        'manufacturing',
        'media',
        'military',
        'nonprofit',
        'retail',
        'real_estate',
        'technology',
        'other',
    ])

    // Note: If you change this list also see the "Company Sizes of Interest" Airtable column
    .constant('CAREERS_EMPLOYEE_COUNT_KEYS', ['1-24', '25-99', '100-499', '500-4999', '5000_plus'])

    .constant('CAREERS_SUBJECT_AREA_KEYS', [
        'statistics',
        'finance_and_accounting',
        'strategy_and_innovation',
        'entrepreneurship',
        'economics',
        'operations_management',
        'marketing',
    ])

    .constant('CAREERS_CANDIDATE_SALARY_RANGE_KEYS', ['prefer_not_to_disclose'].concat(salaryBaseRangeKeys))

    .constant(
        'CAREERS_CANDIDATE_SALARY_RANGE_KEYS_LEGACY',
        ['prefer_not_to_disclose'].concat(salaryBaseRangeKeysLegacy),
    )

    // FIXME: remove in Careers Network cleanup
    .constant('CAREERS_LOCATIONS_OF_INTEREST_KEYS', ['flexible'].concat(_.map(locations, 0)))

    .constant('LOCATION_PLACE_DETAILS', locationPlaceDetails)

    .constant('NEW_COMPANY_INTEREST_LEVELS', ['very_interested', 'interested', 'neutral', 'not_interested'])

    .constant('CAREERS_YEARS_EXPERIENCE_KEYS', ['0_1_years', '1_2_years', '2_4_years', '4_6_years', '6_plus_years'])

    // Note: If you change any values in this list, also see the "Employment Types of Interest" Airtable column
    .constant('CAREERS_POSITION_DESCRIPTOR_KEYS', ['permanent', 'part_time', 'contract', 'internship'])

    .constant('DEFAULT_PROFESSIONAL_ORGANIZATION_LOGO_URL', buildings)

    .constant('DEFAULT_PROFILE_PHOTO', profilePhotoDefault);

export default careersModule;
