
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.job_preferences_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.job_preferences_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.job_preferences_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.job_preferences_form, ...{
    "authorization_status": "Select your citizenship/immigration status",
    "contract": "Contract",
    "desired_company_size": "What size companies are you interested in interviewing with?",
    "desired_company_size_would": "What size companies do you prefer working in?",
    "desired_company_size_desc": "Choose at least one size option, based on the number of full time employees.",
    "desired_employment_type": "What type of employment are you interested in?",
    "desired_employment_type_would": "If you enter the career network, what type of employment would you be interested in?",
    "desired_location": "Where are you looking to work?",
    "where_want": "If you enter the career network, where would you want to work?",
    "desired_start_date": "When would you ideally start your new job?",
    "interested_in_joining": "How willing and interested would you be to join a new company in the next 6 months?",
    "internship": "Internship",
    "open_to_remote_work": "I am open to remote work",
    "would_be_open_to_remote_work": "I would be open to remote work",
    "part_time": "Part-Time",
    "permanent": "Permanent",
    "status_citizen": "US Citizen or Permanent Resident",
    "where_do_you_want_to_work": "What additional cities would you be open to working in?",
    "willing_to_relocate": "I am willing to relocate",
    "would_be_willing_to_relocate": "I would be willing to relocate",
    "show_all_locations": "Show All Locations",

                                        
    "not_interested": "Not at all interested",
    "neutral": "Neutral",
    "interested": "Interested",
    "very_interested": "Very Interested",

                               
    "citizen": "US Citizen or Permanent Resident",
    "h1visa": "Currently have H-1B visa",
    "f1visa": "Currently have F-1/OPT visa",
    "ead": "Currently have EAD",
    "tnvisa": "Currently have TN visa",
    "j1visa": "Currently have J-1 visa",
    "o1visa": "Currently have O-1 visa",
    "l1visa": "Currently have L-1 visa",
    "tneligible": "Eligible for TN visa",
    "e1eligible": "Currently have E-1 visa",
    "e2eligible": "Currently have E-2 visa",
    "e3eligible": "Currently have E-3 visa",
    "h1b1eligible": "Eligible for H1B1",
    "novisa": "Not a US citizen or permanent resident"
}
}
    