import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';
import moment from 'moment-timezone';

angularModule.factory('SupportsRelativeDates', [
    '$injector',

    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const dateHelper = $injector.get('dateHelper');

        return new AModuleAbove({
            included(target) {
                Object.defineProperty(target.prototype, '_startDateForRelativeDates', {
                    get() {
                        throw new Error(
                            'Classes including SupportsRelativeDates must defined _startDateForRelativeDates',
                        );
                    },
                    configurable: true,
                });
            },

            // If you're looking for the analogy to this in es6, look for Cohort#getRelativeDate
            _getCachedRelativeDate(name, offsetProp, startDate = this._startDateForRelativeDates) {
                const cacheKey = `$$cached${name}`;

                // if nothing has changed, return the last date we calculated
                if (
                    this[cacheKey] &&
                    this[cacheKey].startDate === startDate &&
                    this[cacheKey].daysOffset === this[offsetProp]
                ) {
                    return this[cacheKey].date;
                }

                let date;
                if (this[offsetProp] === null || angular.isUndefined(this[offsetProp])) {
                    date = null;
                } else {
                    date = dateHelper.addInDefaultTimeZone(startDate, this[offsetProp], 'days');
                }

                this[cacheKey] = {
                    startDate,
                    daysOffset: this[offsetProp],
                    date,
                };

                return date;
            },

            _getRelativeDayOffset(date, startDate = this._startDateForRelativeDates) {
                if (date === null) {
                    throw new Error('null Not supported');
                }
                const startDay = moment(startDate).tz(dateHelper.tzForOffsetting).startOf('day');
                const day = moment(date).tz(dateHelper.tzForOffsetting).startOf('day');
                return -Math.floor(startDay.diff(day, 'days'));
            },
        });
    },
]);
