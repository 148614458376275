import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('IdVerificationPeriod', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const SupportsRelativeDates = $injector.get('SupportsRelativeDates');

        return Iguana.subclass(function () {
            this.alias('IdVerificationPeriod');
            this.embeddedIn('schedulableItem');
            this.include(SupportsRelativeDates);

            Object.defineProperty(this.prototype, 'cohortId', {
                get() {
                    return this.schedulableItem().id;
                },
            });

            Object.defineProperty(this.prototype, 'startDate', {
                get() {
                    return this._getCachedRelativeDate('startDate', 'start_date_days_offset');
                },
                set(date) {
                    const val = this._getRelativeDayOffset(date);

                    if (
                        this.schedulableItem().ensureLessThanOrEqualTo(
                            val,
                            this.due_date_days_offset,
                            'Start date must be before period due date',
                        )
                    ) {
                        this.start_date_days_offset = val;
                    }
                },
            });

            Object.defineProperty(this.prototype, 'dueDate', {
                get() {
                    return this._getCachedRelativeDate('dueDate', 'due_date_days_offset');
                },
                set(date) {
                    // decision date cannot be before the application deadline
                    const val = this._getRelativeDayOffset(date);
                    if (
                        !this.schedulableItem().ensureGreaterThanOrEqualTo(
                            val,
                            this.start_date_days_offset,
                            'Due date must be after period start date',
                        )
                    ) {
                        return;
                    }
                    this.due_date_days_offset = val;
                },
            });

            Object.defineProperty(this.prototype, 'index', {
                get() {
                    return this.schedulableItem().id_verification_periods.indexOf(this);
                },
            });

            Object.defineProperty(this.prototype, '_startDateForRelativeDates', {
                get() {
                    return this.schedulableItem().startDate;
                },
                configurable: true,
            });

            return {
                remove() {
                    this.schedulableItem().id_verification_periods = _.without(
                        this.schedulableItem().id_verification_periods,
                        this,
                    );
                },
            };
        });
    },
]);
