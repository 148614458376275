
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.careers = window.Smartly.locales.modules.en.back_royal.careers || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_form = window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_form || {};
window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_form = {...window.Smartly.locales.modules.en.back_royal.careers.edit_career_profile.work_form, ...{
    "work_experience": "Work Experience",
    "other_experience": "Other Experience (optional)",
    "featured_work_exp": "Featured Work Experience",
    "featured_work_exp_caption": "Choose which of your current work experiences to feature in your profile.",
    "work_history_description_high_experience": "Showcase at least 6 years of prior work experience, starting with the most recent position first.",
    "work_history_description_low_experience": "Showcase your work experience, starting with the most recent position first.",
    "list_part_time_work_students_and_graduates": "List significant non-full time experiences, like board positions or volunteer activities. For students and recent graduates, list your most significant campus activities here.",
    "work_summary": "Work Summary",
    "my_most_recent_role": "My most recent role can best be described as…",
    "years_experience": "Years experience",
    "most_recent_role": "Most recent role",
    "i_have_this_many_years_experience": "I have this many years of full-time work experience..."
}
}
    