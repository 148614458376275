import angularModule from 'Cohorts/angularModule/scripts/cohorts_module';

angularModule.factory('PeriodExercise', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');
        const guid = $injector.get('guid');
        const IsPeriodItem = $injector.get('IsPeriodItem');

        return Iguana.subclass(function () {
            this.alias('PeriodExercise');
            this.embeddedIn('period');
            this.include(IsPeriodItem);

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                this.id = this.id || guid.generate();
                this.hours_offset_from_end = this.hours_offset_from_end || 0;
            });

            Object.defineProperty(this.prototype, 'date', {
                get() {
                    return this._getCachedRelativeDateFromPeriodEnd('date', 'hours_offset_from_end', 'hours');
                },
                set(date) {
                    this.hours_offset_from_end = this._getRelativeHourOffsetFromPeriodEnd(date);
                    return date;
                },
            });

            Object.defineProperty(this.prototype, 'cohort', {
                get() {
                    return this.period().cohort();
                },
            });

            return {};
        });
    },
]);
