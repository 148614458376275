export default angular.module('ClickKey', []).directive('clickKey', [
    '$document',
    '$injector',

    ($document, $injector) => {
        const $rootScope = $injector.get('$rootScope');

        return {
            //-------------------------
            // Directive Definition
            //-------------------------

            restrict: 'A',

            //-------------------------
            // Directive Linking
            //-------------------------

            link(scope, element, attrs) {
                let bound = false;
                const safeMode = attrs.clickKeySafe !== 'false';

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                    configurable: true, // for specs
                });

                //-------------------------
                // Keypress Handler
                //-------------------------

                const handler = e => {
                    // ensure that everything is kosher. ensure element isn't disabled or
                    // contained within a disabled element. ensure that the user isn't
                    // typing in an input box. ensure that the current user has keyboard shortcuts enabled.
                    if (
                        !attrs.clickKey ||
                        typeof attrs.clickKey !== 'string' ||
                        // check if there is a current user and if they have keyboard shortcuts disabled
                        (scope.currentUser && !scope.currentUser.pref_keyboard_shortcuts) ||
                        // check if the metaKey (Command) is being pressed
                        e.metaKey ||
                        // safeMode (default) prevents things like input collisions
                        (safeMode &&
                            ($('body').hasClass('modal-open') ||
                                element.is(':disabled') ||
                                $(e.target).is('input') ||
                                $(e.target).is('textarea') ||
                                element.closest('[disabled="disabled"]').length > 0))
                    ) {
                        return;
                    }

                    const clickKeyUpper = attrs.clickKey.toUpperCase();
                    const intVal = parseInt(clickKeyUpper, 10);

                    if (
                        (clickKeyUpper === 'ESC' && e.which === 27) ||
                        (clickKeyUpper === 'ENT' && e.which === 13) ||
                        (clickKeyUpper === 'SPACE' && e.which === 32) ||
                        (clickKeyUpper === 'LEFT' && e.which === 37) ||
                        (clickKeyUpper === 'UP' && e.which === 38) ||
                        (clickKeyUpper === 'RIGHT' && e.which === 39) ||
                        (clickKeyUpper === 'DOWN' && e.which === 40) ||
                        (clickKeyUpper.match(/[0-9]/) && (e.which === intVal + 48 || e.which === intVal + 96)) ||
                        String.fromCharCode(e.which) === attrs.clickKey
                    ) {
                        // handle non-'click' events if provided
                        if (attrs.clickKeyEvent) {
                            element[attrs.clickKeyEvent]();
                        } else {
                            element.click();
                        }
                    }
                };

                //-------------------------
                // Event Bind and Cleanup
                //-------------------------

                if (!bound) {
                    $document.bind('keydown', handler);
                    bound = true;
                }

                scope.$on('$destroy', () => {
                    $document.unbind('keydown', handler);
                    bound = false;
                });
            },
        };
    },
]);
